import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./CryptoPlatform.css";
const CryptoPlatform = () => {
  const { t }= useTranslation();
  const navigate = useNavigate();
  return (
    <div className="main-platform">
      <div className="platform-content">
        <div className="platform-image">
          
          <img src="/Images/onboardingscreen2.webp" alt=""/>
        </div>
        <div className="platform-heading1">
          <h2>{t('The leading platform for crypto launchpads')}</h2>
        </div>
        <div className="platform-text1">
          <p>
          {t('The top platform for launching and accelerating blockchain and cryptocurrency projects')}
          </p>
        </div>
        <div className="platform-bottom-button">
          <button className="platform-btn" onClick={() => navigate("/UniversityPlatform")}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default CryptoPlatform;
