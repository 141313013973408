import * as React from "react";
import { useState, useEffect } from "react";
import { styled } from "@mui/material/styles";
import { getRank } from "../../api/getRank";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Card.css";
import RankingBar from "../LinearProgressBar/RankingBar";
import { useTranslation } from 'react-i18next';
const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  ...(props) => ({
    transform: props.expand ? "rotate(180deg)" : "rotate(0deg)",
  }),
}));

export default function RankCard({ rankName, rankLevels }) {
  const [showBar, setShowBar] = useState();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  console.log("thies is rankng ", rankLevels);
  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  function calculateProgress(level) {
    if (level?.status === "completed") {
      return 100; // Full bar for completed status
    } else if (level?.status === "upcoming") {
      return 0; // Empty bar for upcoming status
    } else {
      const maxReferrals = showBar?.currentRank?.currentReferrals;
      const targetReferrals = level?.referrals;
      const maxTasksCompleted = showBar?.currentRank?.currentTasksCompleted;
      const targetTasksCompleted = level?.tasksCompleted;
      const maxXPEarned = showBar?.currentRank?.currentXpEarned;
      const targetXPEarned = level?.xpEarned;

      // Calculate each progress value, capping it at 100% if the current value exceeds the target
      const referralProgress = Math.min(
        (maxReferrals / targetReferrals) * 100,
        100
      );
      const taskProgress = Math.min(
        (maxTasksCompleted / targetTasksCompleted) * 100,
        100
      );
      const xpProgress = Math.min((maxXPEarned / targetXPEarned) * 100, 100);
      console.log(referralProgress, "this is refferals of current ran");

      // Average of the progress percentages
      const averageProgress =
        (referralProgress + taskProgress + xpProgress) / 3;
      console.log(averageProgress, "this is refferals of current ran");

      return averageProgress;
    }
  }

  useEffect(() => {
    async function getRankings() {
      const token = sessionStorage.getItem("token");
      // setIsloading(true);
      const data = await getRank(token);
      console.log(data, "this is seperate data of Rank");

      setShowBar(data);
    }
    getRankings();
  }, []);

  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid #BCBFCC80",
        padding: "0px !important",
      }}
      onClick={handleExpandClick}
    >
      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat-SemiBold",
            fontSize: "14px",
            fontWeight: "600",
            color: "#1E1E24",
            display: "flex",
            alignItems: "center",
            gap: "7px",
          }}
        >
          <img
            src={`/Images/CustomAccordion/${rankName?.toLowerCase()}level.svg`}
            style={{ width: "30px", height: "28px" }}
            alt=""
          />
          {t(rankName)}
        </Typography>
        <ExpandMore
          expand={expanded}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon
            color="#1E1E24"
            sx={{
              transform: expanded ? "rotate(180deg)" : "rotate(0deg)",
              transition: "transform 0.3s ease-in-out",
            }}
          />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent
          sx={{
            padding: "0px 16px 16px !important",
            display: "flex",
            flexDirection: "column",
            justifyContent: "start",
            alignItems: "center",
            gap: "16px",
          }}
        >
          {rankLevels?.map((level, levelIndex) => (
            <div
              key={levelIndex}
              className="Rank_Card_Body"
              style={{
                border:
                  level?.status === "upcoming" || level?.status === "completed"
                    ? "1px solid #BCBFCC80"
                    : "1px solid #1E1E2480",
              }}
            >
              <div className="Ranking_Comiision_first_Sec">
                <div className="RC_Body_Top">
                  <div>
                    <img
                      src={`/Images/RankingProgression/${rankName.toLowerCase()}${
                        level?.level
                      }.png`}
                      style={{ width: "14.5px", height: "16px" }}
                      alt=""
                    />
                    {t(level?.rank)} {t('Level')} {level?.level}
                    {/* Display each level */}
                  </div>
                  <div>
                    {level?.status === "upcoming" ||
                    level?.status === "completed" ? (
                      ""
                    ) : (
                      <img
                        src="/Images/CustomAccordion/currentran.svg"
                        alt=""
                      />
                    )}
                  </div>
                </div>
                {/* {console.log("value of level", level  )} */}
                <RankingBar value={calculateProgress(level)} />
              </div>

              <div className="RC_Body_Bottom">
                <div className="RC_Body-Bottom_Content">
                  <p>{t('Referral')}</p>
                  <p>
                    {Intl.NumberFormat().format(
                      // level?.status === "upcoming" ?
                      level?.referrals
                      // : showBar?.currentRank?.currentReferrals
                    )}
                  </p>
                </div>
                <div className="verticle_Line"></div>
                <div className="RC_Body-Bottom_Content">
                  <p>{t('Tasks Completed')}</p>
                  <p>
                    {Intl.NumberFormat().format(
                      // level?.status === "upcoming"?
                      level?.tasksCompleted
                      // : showBar?.currentRank?.currentTasksCompleted
                    )}
                  </p>
                </div>
                <div className="verticle_Line"></div>
                <div className="RC_Body-Bottom_Content">
                  <p>{t('XP Earned')}</p>
                  <p>
                    {Intl.NumberFormat().format(
                      // level?.status === "upcoming"?
                      level?.xpEarned
                      // : showBar?.currentRank?.currentXpEarned
                    )}
                  </p>
                </div>
              </div>
            </div>
          ))}
        </CardContent>
      </Collapse>
    </Card>
  );
}
