import React from 'react';
import { LinearProgress } from '@mui/material';
import { styled } from '@mui/system';

const CustomLinearProgressBar = styled(LinearProgress)(({ theme }) => ({
  height: 7,  
  borderRadius: 40,  
  background: "rgba(188,191,204,0.3)",
  '& .MuiLinearProgress-bar': {
    borderRadius: 40, 
  },
}));

export default CustomLinearProgressBar;
