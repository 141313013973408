import React, { useState, useEffect } from "react";
import "./SocialOtherView.css";
import Navbar from "../../Components/Navbar/Navbar";
import VoyagerModal from "../../Components/Modal/VoyagerModal";
import VoyagerSecModal from "../../Components/Modal/VoyagerSecModal";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from "react-bootstrap";
import { follow_User } from "../../api/follow_User";
import { unfollow_User } from "../../api/unfolllow_User";
import { useTranslation } from "react-i18next";
import { get_Profiledata } from "../../api/get_Profiledata";
const SocialOtherView = () => {
  const { t } = useTranslation();
  const [buttonText, setButtonText] = useState("Follow");
  const [isLoading, setIsloading] = useState(false);
  const [created_Date, setDate] = useState();
  const [profileData, setprofileData] = useState();

  useEffect(() => {
    async function getProfileData() {
      setIsloading(true);
      const userData = await get_Profiledata();
      console.log("userData________________");
      console.log(userData);
      setprofileData(userData);
      const createdDate = formatMonthYear(userData?.profile?.createdAt);
      setDate(createdDate);
      setIsloading(false);
    }

    getProfileData();
  }, []);

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short", year: "numeric" });
  };

  const handleFollow = () => {
    if (buttonText === "Follow") {
      followUser();
    } else if (buttonText === "Following") {
      unfollowUser();
    }
  };
  async function followUser() {
    const id = sessionStorage.getItem("id");
    console.log(id);
    const response = await follow_User(id);
    if (response.Ok) {
      setButtonText("Following");
    } else {
      alert("you can't follow at this time");
    }

    console.log(response);
  }

  async function unfollowUser() {
    const id = sessionStorage.getItem("id");
    console.log(id);
    const response = await unfollow_User(id);
    console.log(response);
    if (response.ok) {
      setButtonText("Follow");
    } else {
      alert("you can't unfollow at this time");
    }
  }
  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };
  const navigate = useNavigate();

  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setSecondShowModal] = useState(false);
  const openVoyagerModal = () => {
    setShowModal(true);
  };

  const closeVoyagerModal = () => {
    setShowModal(false);
  };
  const openVoyagerSecModal = () => {
    setSecondShowModal(true);
  };

  const closeVoyagerSecModal = () => {
    setSecondShowModal(false);
  };

  const minnigBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge2.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge3.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge5.svg",
      progress: 10,
      text: "0/1",
    },
  ];

  const taskBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge6.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge5.svg",
      progress: 10,
      text: "0/1",
    },
  ];

  const streakBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge3.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
  ];
  const mysteryBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge6.svg",
      progress: 10,
      text: "0/1",
    },
  ];

  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="AllMain-New">
          <div className="topImage-New">
            <img
              src={
                profileData?.profile?.coverPicture
                  ? profileData.profile.coverPicture
                  : "/Images/ProfileBgimg.png"
              }
              alt="ProfileActor"
              className="Profilebg-New"
            />
            <img
              src="/Images/Social Profile/arrowprofile.svg"
              alt=""
              onClick={() => navigate(-1)}
              className="Profilebg-New-arrow"
            />
          </div>

          <div className="Main-New">
            <div className="maintop-New">
              <div className="ProfilePicMainDiv-New">
                <div className="ImagePostionDiv-New">
                  <div>
                    <img
                      src={
                        profileData?.profile?.profilePicture
                          ? profileData.profile.profilePicture
                          : "/Images/ProfileActor.png"
                      }
                      alt="ProfileActor"
                      className="Profile-Image-New"
                    />
                  </div>
                  <div className="InnerStarPosition-New">
                    <img src="/Images/Staricon.svg" alt="ProfileActor" />
                  </div>
                </div>

                <div className="ProfilePic-Div2-New">
                  <div className="imagealignbottom-New">
                    <div className="NameTotal-New">
                      <div className="NameDiv-New">
                        <p className="AccountName-New">
                          {profileData?.profile?.displayName
                            ? profileData?.profile?.displayName
                            : profileData?.profile?.name}
                        </p>
                        <div>
                          {profileData?.profile?.emailVerified ? (
                            <img src="/Images/Verified.svg" alt="Insta" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="NameProfile-New">
                          @{profileData?.profile?.telegramUsername}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="MainInner-New">
              <div className="MainInner1-New">
                <div className="Innerdivtext-New">
                  <p className="CryptoText-New">{profileData?.profile?.bio}</p>
                  <p className="CryptoText1-New">
                    {t("Member Since:")} {created_Date}
                  </p>
                  <p className="TotalText-New">{t("Total team value:")} $0</p>
                </div>
                {/* <div className='btnDiv'>
             <button className='FollowBtn'>Follow</button>
                <button className='Messagebtn'>Message</button> 
              <div className='iconDiv'>
                <img src="/Images/Social Profile/Insta.svg" alt="" />
                <img src="/Images/Social Profile/X.svg" alt="" />
                <img src="/Images/Social Profile/Reddit.svg" alt="" />
                <img src="/Images/Social Profile/Telegram.svg" alt="" />
                <img src="/Images/Social Profile/Facebook.svg" alt="" />
              </div>
            </div> */}

                {/* <div className='btnDiv-other'>

              <button className='FollowBtn-other'>Follow</button>
              <button className='Messagebtn-other'>Message</button>

              <div className='iconDiv-other'>
                <img src="/Images/Social Profile/Insta.svg" alt="Insta" />
                <img src="/Images/Social Profile/X.svg" alt="Twitter" />
                <img src="/Images/Social Profile/Reddit.svg" alt="Git" />
                <img src="/Images/Social Profile/Telegram.svg" alt="Telegram " />
                <img src="/Images/Social Profile/Facebook.svg" alt="Facebook" />
              </div>
            </div> */}

                <div className="button-and-icons-div-social-new-New">
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      gap: "5px",
                    }}
                  >
                    <button
                      className="Follow-new-button-social-New"
                      style={{
                        background:
                          buttonText === "Following"
                            ? "#BCBFCC4D"
                            : " linear-gradient(180deg, #000000 0%, #323232 100%)",
                        color:
                          buttonText === "Following" ? "#1E1E24" : "#ffffff",
                      }}
                      onClick={handleFollow}
                    >
                      {t(buttonText)}
                    </button>
                    <button className="Message-new-button-social-New">
                      {t("Message")}
                    </button>
                  </div>
                  <div className="div-fir-social-icons-New">
                    <img
                      src="/Images/Social Profile/Insta.svg"
                      onClick={() =>
                        profileData?.profile?.instagramLink &&
                        handleClick(`${profileData?.profile?.instagramLink}`)
                      }
                      style={{
                        cursor: "pointer",
                        opacity: profileData?.profile?.instagramLink ? 1 : 0.5,
                      }}
                      alt="Insta"
                    />
                    <img
                      src="/Images/Social Profile/X.svg"
                      onClick={() =>
                        profileData?.profile?.XLink &&
                        handleClick(`${profileData?.profile?.XLink}`)
                      }
                      style={{
                        cursor: profileData?.profile?.XLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.XLink ? 1 : 0.5,
                      }}
                      alt="Twitter"
                    />
                    <img
                      src="/Images/Social Profile/Reddit.svg"
                      onClick={() =>
                        profileData?.profile?.discordLink &&
                        handleClick(`${profileData?.profile?.discordLink}`)
                      }
                      style={{
                        cursor: profileData?.profile?.discordLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.discordLink ? 1 : 0.5,
                      }}
                      alt="Git"
                    />
                    <img
                      src="/Images/Social Profile/Telegram.svg"
                      onClick={() =>
                        profileData?.profile?.telegramUsername &&
                        handleClick(`${profileData?.profile?.telegramUsername}`)
                      }
                      style={{
                        cursor: profileData?.profile?.telegramUsername
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.telegramUsername
                          ? 1
                          : 0.5,
                      }}
                      alt="Telegram "
                    />

                    <img
                      src="/Images/Social Profile/Youtube.svg"
                      alt="YouTube"
                      onClick={() =>
                        profileData?.profile?.youtubeLink &&
                        handleClick(`${profileData?.profile?.youtubeLink}`)
                      }
                      style={{
                        cursor: profileData?.profile?.youtubeLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.youtubeLink ? 1 : 0.5,
                      }}
                    />
                    <img
                      src="/Images/Social Profile/Linkedin.svg"
                      alt="LinkedIn"
                      onClick={() =>
                        profileData?.profile?.linkedinLink &&
                        handleClick(`${profileData?.profile?.linkedinLink}`)
                      }
                      style={{
                        cursor: profileData?.profile?.linkedinLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.linkedinLink ? 1 : 0.5,
                      }}
                    />
                    <img
                      src="/Images/Social Profile/Facebook.svg"
                      onClick={() =>
                        profileData?.profile?.facebookLink &&
                        handleClick(`${profileData?.profile?.facebookLink}`)
                      }
                      style={{
                        cursor: profileData?.profile?.facebookLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.facebookLink ? 1 : 0.5,
                      }}
                      alt="Facebook"
                    />
                  </div>
                </div>

                <div className="AccountDiv-New">
                  <div className="AccountData-New">
                    <p className="AccountDatatxt1-New">xMAG</p>
                    <p className="AccountDatatxt2-New">{profileData?.xMag}</p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData-New">
                    <p className="AccountDatatxt1-New">{t("Referrals")}</p>
                    <p className="AccountDatatxt2-New">
                      {profileData?.referrals}
                    </p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData-New">
                    <p className="AccountDatatxt1-New">{t("Team")}</p>
                    <p className="AccountDatatxt2-New">{profileData?.Team}</p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData-New">
                    <p className="AccountDatatxt1-New">{t("Followers")}</p>
                    <p className="AccountDatatxt2-New">
                      {profileData?.followers}
                    </p>
                  </div>
                </div>
              </div>

              <div className="Main-Recent-Earned-Div-Bottom-New">
                <p className="recent-earned-text-new-New">
                  {t("Recent Earned Badges")}
                </p>

                <div className="recent-badges-bottom-inner-div-New">
                  <p className="minning-text-recent-badge-New">{t("Mining")}</p>
                  <div className="minning-text-recent-badge-div-New">
                    {minnigBadgeData.map((minningData, index) => (
                      <div className="recent-badge-img-and-progressbar-New">
                        <div>
                          <img
                            src={minningData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent-New">
                          <ProgressBar
                            now={minningData.progress}
                            className="custom-progress-bar-recent-New"
                          />
                          <p className="progress-bar-and-text-recent-1-New">
                            {minningData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div-New">
                  <p className="minning-text-recent-badge-New">{t("Tasks")}</p>
                  <div className="minning-text-recent-badge-div-New">
                    {taskBadgeData.map((taskData, index) => (
                      <div className="recent-badge-img-and-progressbar-New">
                        <div>
                          <img
                            src={taskData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent-New">
                          <ProgressBar
                            now={taskData.progress}
                            className="custom-progress-bar-recent-New"
                          />
                          <p className="progress-bar-and-text-recent-1-New">
                            {taskData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div-New">
                  <p className="minning-text-recent-badge-New">
                    {t("Streaks")}
                  </p>
                  <div className="minning-text-recent-badge-div-New">
                    {streakBadgeData.map((streakData, index) => (
                      <div className="recent-badge-img-and-progressbar-New">
                        <div>
                          <img
                            src={streakData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent-New">
                          <ProgressBar
                            now={streakData.progress}
                            className="custom-progress-bar-recent-New"
                          />
                          <p className="progress-bar-and-text-recent-1-New">
                            {streakData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div-New">
                  <p className="minning-text-recent-badge-New">
                    {t("Mystery Packs")}
                  </p>
                  <div className="minning-text-recent-badge-div-New">
                    {mysteryBadgeData.map((mysteryData, index) => (
                      <div className="recent-badge-img-and-progressbar-New">
                        <div>
                          <img
                            src={mysteryData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent-New">
                          <ProgressBar
                            now={mysteryData.progress}
                            className="custom-progress-bar-recent-New"
                          />
                          <p className="progress-bar-and-text-recent-1-New">
                            {mysteryData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* <div className='mainbottom-container' >
            <div className='recentandiconsdiv'>
              <div className='recenttextdiv'>
                <p className='recenttext'>
                  Recent Earned Badges
                </p>
                <p className='Seetext'>SEE ALL</p>
              </div>
              <div className='allicons'>
                <img src="/Images/RecentImg1.svg" alt="RecentImg1" onClick={openVoyagerModal} />
                <img src="/Images/RecentImg2.svg" alt="RecentImg2" onClick={openVoyagerSecModal} />
                <img src="/Images/RecentImg3.svg" alt="RecentImg3" />
                <img src="/Images/RecentImg4.svg" alt="RecentImg4" />
                <img src="/Images/RecentImg5.svg" alt="RecentImg5" />
                <img src="/Images/RecentImg6.svg" alt="RecentImg6" />
              </div>
            </div>

            <div className='botooomcontainer'>



              <div className='recenttextdiv'>
                  <p className='recenttext'>
                    Launchpad Participation
                  </p>
                  <p className='Seetext'>SEE ALL</p>
                </div> 


              <div className='card-container'>
                <div className='nft-viewall-div'>
                  <p className='Nft-text-style'>NFTS</p>
                  <p className='viewall-text-style'>View all</p>
                </div>

                <div className='ThreeCard-Row'>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card4Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>Doodles</p>
                    </div>
                  </div>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card5Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>Captain & Co...</p>
                    </div>
                  </div>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card6Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>ChronoForge</p>
                    </div>
                  </div>
                </div>


                <div className='launchpad-partication-div'>
                  <p className='launchpad-text-style'>Launchpad Participation</p>
                  <p className='seeall-1-text-style'>SEE ALL</p>
                </div>

                <div className='ThreeCard-Row'>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card1Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>EOS</p>
                    </div>
                  </div>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card2Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>Illuvium ILV</p>
                    </div>
                  </div>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card3Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>NEAR Protocol</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div> */}
            </div>
          </div>

          <Navbar />

          <VoyagerModal show={showModal} handleClose={closeVoyagerModal} />
          <VoyagerSecModal
            show={showSecondModal}
            handleClose={closeVoyagerSecModal}
          />
        </div>
      )}
    </>
  );
};

export default SocialOtherView;
