import React, { useEffect } from "react";
import Verify from "./Pages/Verify/Verify";
import Platform from "./Pages/Platform/Platform";
import ProfileMain from "./Pages/Profile Main/ProfileMain";
import Veification from "./Pages/Verification/Veification";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import Referrals from "./Pages/Referrals/Referrals";
import ReferralOne from "./Pages/Referrals/ReferralOne";
import Profile from "./Pages/Profile/Profile";
import Certificate from "./Pages/Certificate/Certificate";
import Quiz from "./Pages/Quiz/Quiz";
import WealthAcademy from "./Pages/WealthAcademy/WealthAcademy";
import Cryptocurrency from "./Pages/Cryptocurrency/Cryptocurrency";
import Wealth from "./Pages/Wealth Academy/Wealth";
import CourseVideo from "./Pages/Course Video/CourseVideo";
import CourseCompleted from "./Pages/Course Completed/CourseCompleted";
import VideosDetail from "./Pages/Videos Detail/VideosDetail";
import Home from "./Pages/Home/Home";
import Splash from "./Pages/Splash Screen/Splash";
import VoyagerModal from "./Components/Modal/VoyagerModal";
import VoyagerSecModal from "./Components/Modal/VoyagerSecModal";
import SocialOtherView from "./Pages/Social Profile Other View/SocialOtherView";
import EditProfile from "./Pages/Edit Profile/EditProfile";
import CryptoPlatform from "./Pages/Platform 2/CryptoPlatform";
import UniversityPlatform from "./Pages/Platform 3/UniversityPlatform";
import TradingPlatform from "./Pages/Platform 4/TradingPlatform";
import AccountSetting from "./Pages/Account Setting/AccountSetting";
import ConnectWallet from "./Pages/Connect Wallet/ConnectWallet";
import ComptationDetail from "./Pages/ComptationDetail/ComptationDetail";
import Competitions from "./Pages/Competitions/Competitions";
import Notification from "./Pages/Notification/Notification";
import Convert$SMAG from "./Pages/ConvertSMAG/ConvertSMAG";
import RankProgression from "./Pages/Rank Progression/RankProgression";
import Rewards from "./Pages/Rewards/Rewards";
import Updates from "./Pages/update/Updates";
import TaskDetail from "./Pages/TaskDetail/TaskDetail";
import CustomAccordion from "./Pages/CustomAccordion/CustomAccordion";
import Leaderboard from "./Pages/Leaderboard/Leaderboard";
import CommissionHistory from "./Pages/Commission History/CommissionHistory";
import TermsNConditions from "./Pages/Terms And Condition/TermsNConditions"
import SelectLanguage from "./Pages/MagnusSlectLanguage/SlectLanguage";
import RoadMap____Updates from "./Pages/RoadMap____Updates/RoadMap____Updates";
const App = () => {
  useEffect(() => {
    // console.log("This is moon coin");

    // window.Telegram.WebApp.isVerticalSwipesEnabled = false;

    // window.Telegram.WebApp.expand();

    // window.Telegram.WebApp.disableVerticalSwipes();

    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.disableVerticalSwipes
    ) {
      window.Telegram.WebApp.isVerticalSwipesEnabled = false;

      window.Telegram.WebApp.expand();

      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.disableVerticalSwipes();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  }, []);
  useEffect(() => {
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.disableVerticalSwipes
    ) {
      window.Telegram.WebApp.disableVerticalSwipes();
      window.Telegram.WebApp.expand();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  }, []);

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Splash />} />
        <Route path="/Home" element={<Home />} />
        <Route path="/Profile" element={<Profile />} />
        <Route path="/Platform" element={<Platform />} />
        <Route path="/Verification" element={<Veification />} />
        <Route path="/Verification/:email" element={<Veification />} />
        <Route path="/Verify" element={<Verify />} />
        <Route path="/ComptationDetail" element={<ComptationDetail />} />
        <Route path="/Referral" element={<Referrals />} />
        <Route path="/RankProgression" element={<RankProgression />} />
        <Route path="/Commision-History" element={<CommissionHistory />} />
        <Route path="/Referralone" element={<ReferralOne />} />
        <Route path="/ProfileMain" element={<ProfileMain />} />
        <Route path="/VoyagerModal" element={<VoyagerModal />} />
        <Route path="/Quiz" element={<Quiz />} />
        <Route path="/SocialOtherView" element={<SocialOtherView />} />
        <Route path="/WealthAcademy" element={<WealthAcademy />} />
        <Route path="/Cryptocurrency" element={<Cryptocurrency />} />
        <Route path="/VoyagerSecModal" element={<VoyagerSecModal />} />
        <Route path="/Wealth" element={<Wealth />} />
        <Route path="/CourseVideo" element={<CourseVideo />} />
        <Route path="/CourseCompleted" element={<CourseCompleted />} />
        <Route path="/VideosDetail" element={<VideosDetail />} />
        <Route path="/VideosDetail" element={<VideosDetail />} />
        <Route path="/CourseCompleted" element={<CourseCompleted />} />
        <Route path="/Certificate" element={<Certificate />} />
        <Route path="/EditProfile" element={<EditProfile />} />
        <Route path="/CryptoPlatform" element={<CryptoPlatform />} />
        <Route path="/UniversityPlatform" element={<UniversityPlatform />} />
        <Route path="/TradingPlatform" element={<TradingPlatform />} />
        <Route path="/AccountSetting" element={<AccountSetting />} />
        <Route path="/ConnectWallet" element={<ConnectWallet />} />
        <Route path="/competitions" element={<Competitions />} />
        <Route path="/notifications" element={<Notification />} />
        <Route path="/Rewards" element={<Rewards />} />
        <Route path="/Leaderboard" element={<Leaderboard />} />
        <Route path="/Updates" element={<Updates />} />
        <Route path="/TaskDetail" element={<TaskDetail />} />
        <Route path="/TermsNConditions" element={<TermsNConditions />} />
        <Route path="/SelectLanguage" element={<SelectLanguage />} />
        <Route path="/Roadmap" element={<RoadMap____Updates />} />
   
      </Routes>
    </Router>
  );
};

export default App;
