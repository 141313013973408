import React, { useState, useEffect } from "react";
import "./Rewards.css";
import Points from "../../Components/Rewards/Points";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import {
  LinearProgress,
  Box,
  Drawer,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import { styled } from "@mui/system";
import CustomLinearProgressBar from "../../Components/Rewards/ProgressBar";
import { get_rewards } from "../../api/get_rewards";
import { reward_streak } from "../../api/reward_streak";
import { get_streakcount } from "../../api/get_streakcount";
import { useNavigate } from "react-router-dom";
import { get_home_userdetails } from "../../api/get_home_userdetails";
import Navbar from "../../Components/Navbar/Navbar";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
function Rewards() {
  const [isLoading, setIsloading] = useState(false);
  const [activeButton, setActiveButton] = useState("Quests");
  // const[url, seturl]=useState();
  const [streak, setStreak] = useState([0]);
  const [claimable, Setclaimable] = useState();
  const [quest, setquestdata] = useState([]);
  const [featured, sefeaturedata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [userdata, setUserdata] = useState();
  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  useEffect(() => {
    getuserdata();
  }, []);
  const getuserdata = async () => {
    setIsloading(true);
    const user_Data = await get_home_userdetails();
    console.log("-----");
    console.log(user_Data);
    setUserdata(user_Data);
    setIsloading(false);
  };

  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handlequestClick = (quest) => {
    console.log("quest id_", quest?._id);
    navigate("/TaskDetail", { state: { id: quest?._id } });
  };
  const handlefeaturedClick = (featured) => {
    console.log("quest id_", featured?._id);
    navigate("/TaskDetail", { state: { id: featured?._id } });
  };

  const fetchStreakStatus = async () => {
    const streakStatus = await get_streakcount();
    setIsloading(true);
    console.log("streakstatues");
    console.log(streakStatus?.claimable);
    Setclaimable(streakStatus?.claimable);
    if (streakStatus?.Streak?.currentStreak) {
      setStreak(streakStatus?.Streak?.currentStreak);
    }
    setIsloading(false);
  };
  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const claimStreak = async () => {
    setIsloading(true);
    const streak_count = await reward_streak();
    console.log("---------");
    console.log(streak_count);
    if (streak_count?.Streak?.currentStreak) {
      console.log(streak_count?.Streak?.currentStreak);
      setStreak(streak_count?.Streak?.currentStreak);
      Setclaimable(false);
    }
    setIsloading(false);
  };
  useEffect(() => {
    async function getrewarddata() {
      setIsloading(true);
      const rewarData = await get_rewards("quest", currentPage);
      console.log("123");
      console.log(rewarData);
      setquestdata(rewarData?.quests);
      const rewarData1 = await get_rewards("featured", currentPage);
      console.log(rewarData1, "fetured");
      sefeaturedata(rewarData1?.quests);
      setIsloading(false);
    }
    getrewarddata();
  }, [activeButton]);

  useEffect(() => {
    setIsloading(true);
    fetchStreakStatus();
    setIsloading(false);
  }, []);
  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <>
          <div className="rewards__main__container">
            <div className="rewards__header__container">
              <img
                src="/Images/Backicon.svg"
                alt="BackIcon"
                onClick={handleBack}
              />
              <p className="reward__heading_text">Reward</p>
              <div className="rewards__header__container-1">
                <img src="/Images/Rewards/headericon.svg" alt="HeaderIcon" />
                <p className="reward__heading_text-1">{userdata?.user?.xMag}</p>
              </div>
            </div>

            <div className="Home-top-div1">
              <div className="Home-top1-1">
                <div className="Home-top1-1-inner">
                  <p className="Home-top1-1-inner-text">xMAG</p>
                  <h1 className="Home-top1-1-inner-text1">
                    {userdata?.user?.xMag}
                  </h1>
                </div>
                <button className="Home-top1-1-button">Redeem</button>
              </div>
              <div className="Home-top1-2">
                <div className="Home-top1-2-inner">
                  <div className="Home-top1-2-inner1">
                    <img src="/Images/Home/rank.svg" alt="" />
                    <p className="Home-top1-2-inner1-text">
                      {userdata?.user?.CurrentRank} level{" "}
                      {userdata?.user?.CurrentLevel}
                    </p>
                  </div>
                  <p className="Home-top1-2-inner-text">
                    <span style={{ color: "#ffffff" }}>
                      {userdata?.user?.xMag}
                    </span>
                    /{userdata?.user?.NextRank?.xpEarned} xMAG
                  </p>
                </div>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "50px",
                    overflow: "hidden",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={
                      (userdata?.user?.xMag /
                        userdata?.user?.NextRank?.xpEarned) *
                      100
                    }
                    sx={{
                      height: "10px",
                      borderRadius: "50px",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#BCBFCC",
                        borderRadius: "100px",
                      },
                      backgroundColor: "#FFFFFF1A",
                    }}
                  />
                </Box>
                {/* <p className="Home-top1-2__text">
            XPs are earned for completing quests
          </p> */}
              </div>
            </div>

            <div className="rewards__buttons__container">
              <div className="reward__button__container_1">
                <button
                  className={`quests_btn ${
                    activeButton === "Quests" ? "active" : "transparent"
                  }`}
                  onClick={() => handleButtonClick("Quests")}
                >
                  Quests
                </button>

                <button
                  className={`featured_btn ${
                    activeButton === "Featured" ? "active" : "transparent"
                  }`}
                  onClick={() => handleButtonClick("Featured")}
                >
                  Featured
                </button>

                <button
                  className={`streaks_btn ${
                    activeButton === "Streaks" ? "active" : "transparent"
                  }`}
                  onClick={() => handleButtonClick("Streaks")}
                >
                  Streaks
                </button>
              </div>
            </div>

            <div className="quests__detail__container">
              {activeButton === "Quests" && (
                <>
                  {quest?.map((quest) => (
                    <div
                      key={quest?.id}
                      className="quests__detail__container-1"
                      style={{ opacity: quest?.questPerformed ? 0.6 : 1 }}
                      onClick={() =>
                        !quest?.questPerformed && handlequestClick(quest)
                      }
                    >
                      <div className="quests__detail__container-2">
                        <img
                          className="image_rewards_1"
                          src={quest?.image}
                          alt="Platform Icon"
                        />
                        <p className="quests__main__text_1">{quest?.title}</p>
                      </div>
                      <div className="quests__detail__container-3">
                        <div className="quests__detail__container-4">
                          <img
                            className="image_rewards_2"
                            src="/Images/Rewards/icon-m.svg"
                            alt="Icon"
                          />
                          <p className="quests__main__amount_1">
                            +{quest?.points}
                          </p>
                        </div>
                        <img
                          className="image_rewards_3"
                          src={
                            quest?.questPerformed
                              ? "/Images/Rewards/tic.svg"
                              : "/Images/Rewards/right-arrow.svg"
                          }
                          alt="Arrow Icon"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="Ranking__pagenation">
                    <Stack spacing={2}>
                      <Pagination
                        count={quest?.totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </>
              )}

              {activeButton === "Featured" && (
                <>
                  {featured?.map((featured) => (
                    <div
                      key={featured?.id}
                      className="quests__detail__container-1"
                      style={{ opacity: featured?.questPerformed ? 0.6 : 1 }}
                      onClick={() =>
                        !quest?.questPerformed && handlefeaturedClick(featured)
                      }
                    >
                      <div className="quests__detail__container-2">
                        <img
                          className="image_rewards_1"
                          src={featured?.image}
                          alt="Platform Icon"
                        />
                        <p className="quests__main__text_1">
                          {featured?.title}
                        </p>
                      </div>
                      <div className="quests__detail__container-3">
                        <div className="quests__detail__container-4">
                          <img
                            className="image_rewards_2"
                            src="/Images/Rewards/icon-m.svg"
                            alt="Icon"
                          />
                          <p className="quests__main__amount_1">
                            +{featured?.points}
                          </p>
                        </div>
                        <img
                          className="image_rewards_3"
                          src={
                            featured?.questPerformed
                              ? "/Images/Rewards/tic.svg"
                              : "/Images/Rewards/right-arrow.svg"
                          }
                          alt="Arrow Icon"
                        />
                      </div>
                    </div>
                  ))}
                  <div className="Ranking__pagenation">
                    <Stack spacing={2}>
                      <Pagination
                        count={featured?.totalPages}
                        page={currentPage}
                        onChange={handlePageChange}
                      />
                    </Stack>
                  </div>
                </>
              )}

              {activeButton === "Streaks" && (
                <div className="streaks__main__container">
                  <div className="streaks__main__container-1">
                    <div className="streaks__main__container-2">
                      <img
                        className="image_fire_1"
                        src="/Images/Rewards/fire.svg"
                        alt="mage"
                      />
                      <p className="fire__text__1">{streak ? streak : ""}</p>
                      <p className="fire__text__2">Day Streak!</p>
                    </div>

                    {claimable ? (
                      <div className="button__streak__container">
                        <button
                          className="button__streak_1"
                          onClick={claimStreak}
                        >
                          Claim Streak
                        </button>
                      </div>
                    ) : (
                      <div className="button__streak__container">
                        <button className="button__streak_2">Claimed </button>
                      </div>
                    )}
                  </div>
                  <p className="come__back__text">
                    Come back daily to claim your streak and unlock $MAG,
                    Bonuses and NFTs as you hit milestones!
                  </p>
                  <div className="next_milestone_container">
                    <p className="next_milestone__text">
                      {" "}
                      Next Milestone:{" "}
                      <span className="next_milestone__text1"> In 5 days </span>
                    </p>
                  </div>
                  <div className="cards__reward__container">
                    <div className="card__reward__container__2">
                      <div className="cards__reward__container-1">
                        <img
                          className="card_image_1"
                          src="/Images/Competitions/card_2.png"
                          alt="Image_"
                        />
                        <p className="metawarse__text-1">Metaverse Avatar </p>
                        <Box sx={{ width: "100%", mb: 2 }}>
                          <CustomLinearProgressBar
                            variant="determinate"
                            value={60}
                            sx={{
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#BCBFCC",
                                width: "200%",
                              },
                            }}
                          />
                        </Box>
                        <div className="value__Reward__container">
                          <p className="value__text__1">
                            {" "}
                            Value: <span className="value__text__2">
                              $5{" "}
                            </span>{" "}
                          </p>
                          <div className="value__Reward__container-2">
                            <img
                              className="firw_image_2"
                              src="/Images/Rewards/fire2.svg"
                              alt="Image_"
                            />
                            <p className="day__reward_text"> Day 30/30 </p>
                          </div>
                        </div>
                      </div>
                      <div className="cards__reward__container-1">
                        <img
                          className="card_image_1"
                          src="/Images/Competitions/card_3.png"
                          alt="Image_"
                        />
                        <p className="metawarse__text-1">ThorBeast - Rare </p>
                        <Box sx={{ width: "100%", mb: 2 }}>
                          <CustomLinearProgressBar
                            variant="determinate"
                            value={60}
                            sx={{
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#BCBFCC",
                                width: "50%",
                              },
                            }}
                          />
                        </Box>
                        <div className="value__Reward__container">
                          <p className="value__text__1">
                            {" "}
                            Value: <span className="value__text__2">
                              $5{" "}
                            </span>{" "}
                          </p>
                          <div className="value__Reward__container-2">
                            <img
                              className="firw_image_2"
                              src="/Images/Rewards/fire2.svg"
                              alt="Image_"
                            />
                            <p className="day__reward_text"> Day 25/90 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="card__reward__container__2">
                      <div className="cards__reward__container-1">
                        <img
                          className="card_image_1"
                          src="/Images/Competitions/card_4.png"
                          alt="Image_"
                        />
                        <p className="metawarse__text-1">Cyber Truck - Epic</p>
                        <Box sx={{ width: "100%", mb: 2 }}>
                          <CustomLinearProgressBar
                            variant="determinate"
                            value={60}
                            sx={{
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#BCBFCC",
                                width: "15%",
                              },
                            }}
                          />
                        </Box>
                        <div className="value__Reward__container">
                          <p className="value__text__1">
                            {" "}
                            Value: <span className="value__text__2">
                              $5{" "}
                            </span>{" "}
                          </p>
                          <div className="value__Reward__container-2">
                            <img
                              className="firw_image_2"
                              src="/Images/Rewards/fire2.svg"
                              alt="Image."
                            />
                            <p className="day__reward_text"> Day 25/180 </p>
                          </div>
                        </div>
                      </div>
                      <div className="cards__reward__container-1">
                        <img
                          className="card_image_1"
                          src="/Images/Competitions/card_5.png"
                          alt="Imag.e"
                        />
                        <p className="metawarse__text-1">MetaMansion - Epic</p>
                        <Box sx={{ width: "100%", mb: 2 }}>
                          <CustomLinearProgressBar
                            variant="determinate"
                            value={60}
                            sx={{
                              "& .MuiLinearProgress-bar": {
                                backgroundColor: "#BCBFCC",
                                width: "8%",
                              },
                            }}
                          />
                        </Box>
                        <div className="value__Reward__container">
                          <p className="value__text__1">
                            {" "}
                            Value: <span className="value__text__2">
                              $5{" "}
                            </span>{" "}
                          </p>
                          <div className="value__Reward__container-2">
                            <img
                              className="firw_image_2"
                              src="/Images/Rewards/fire2.svg"
                              alt="Image."
                            />
                            <p className="day__reward_text"> Day 25/300 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
          <Navbar />
        </>
      )}
    </>
  );
}

export default Rewards;
