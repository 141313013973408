import React from 'react'
import './ReferralOne.css'
import Navbar from '../../Components/Navbar/Navbar'
import { useNavigate } from 'react-router-dom'
const ReferralOne = () => {
  const navigate=useNavigate();
  return (
    <div className='ref-one-main'>
      <div className="referral-arrow-one">
        <img src="/Images/arrowref.svg" alt='' onClick={() => navigate(-1)}/>
        <h2>Rank Progression</h2>
        <img src="/Images/launchpad/backArrowwhite.svg" alt="BackIcon" />
      </div>
      <div className='referral__container__main-1'>
      <div className='referral__container__main-2'>

</div>
      </div>
      <Navbar/>
    </div>
  )
}

export default ReferralOne
