import React from "react";
import "./WealthAcademy.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
const WealthAcademy = () => {
  const navigate = useNavigate();
  return (
    <div className="wealth-main">
      <div className="wealth-arrow">
        <img src="/Images/arrowref.svg" alt='' onClick={() => navigate(-1)} />
        <p className="wealth-arrowtext">Wealth Academy</p>
        <img src="/Images/arrowref2.svg" alt=""/>
      </div>
      <div className="wealth-top">
        <button className="course-btn" onClick={() => navigate('/Wealth')}>Course</button>
        <button className="market-btn">Market Report</button>
      </div>
      <div className="wealth-profile-main">
        <div className="see-all">
          <h2>Featured</h2>
          <p>SEE ALL</p>
        </div>
        <div className="for-slider-main">
          <div className="wealth-image-content" onClick={() => navigate('/Cryptocurrency')}>
            <div className="wealth-wala-top-image">
              <img src="/Images/wealth.svg" alt="" />
            </div>
            <div className="bottom-content-wealth">
              <h2 className="forex-mastery">Forex Mastery</h2>
              <div className="staric-part">
                <div className="ek-image-ek-txt">
                  <img src="/Images/alarm.svg" alt=""/>
                  <p>2h 32min</p>
                </div>
                <div className="ek-image-ek-txt">
                  <img src="/Images/book.svg" alt="" />
                  <p>12 lessons</p>
                </div>
                <div className="ek-image-ek-txt">
                  <img src="/Images/star.svg" alt="" />
                  <p>4.3</p>
                </div>
              </div>
            </div>
          </div>
          <div className="wealth-image-content">
            <div className="wealth-wala-top-image">
              <img src="/Images/wealth.svg" alt=""/>
            </div>
            <div className="bottom-content-wealth">
              <h2 className="forex-mastery">Forex Mastery</h2>
              <div className="staric-part">
                <div className="ek-image-ek-txt">
                  <img src="/Images/alarm.svg" alt="" />
                  <p>2h 32min</p>
                </div>
                <div className="ek-image-ek-txt">
                  <img src="/Images/book.svg" alt=""/>
                  <p>12 lessons</p>
                </div>
                <div className="ek-image-ek-txt">
                  <img src="/Images/star.svg" alt="" />
                  <p>4.3</p>
                </div>
              </div>
            </div>
          </div>

        </div>
        <div className="bottom-margin-see-all">
          <div className="see-all-2">
            <h2>Trending</h2>
            <p>SEE ALL</p>
          </div>
          <div className="trending-main-bottom">
            <div className="trending-main">
              <img src="/Images/stocks.svg" alt=""/>
              <div className="stocks-content">
                <h1>Stocks Mastery</h1>
                <p>Morem ipsum dolor sit amet consectetur.</p>
                <span>#blockchain Oct 1, 2023</span>
              </div>
            </div>
            <div className="trending-main">
              <img src="/Images/mastry.svg" alt="" />
              <div className="stocks-content">
                <h1>Forex Mastery</h1>
                <p>Morem ipsum dolor sit amet consectetur.</p>
                <span>#blockchain Oct 1, 2023</span>
              </div>
            </div>
            <div className="trending-main">
              <img src="/Images/crypto.svg" alt="" />
              <div className="stocks-content">
                <h1>Crypto Mastery</h1>
                <p>Morem ipsum dolor sit amet consectetur.</p>
                <span>#blockchain Oct 1, 2023</span>
              </div>
            </div>
            <div className="trending-main">
              <img src="/Images/stocks.svg" alt="" />
              <div className="stocks-content">
                <h1>Stocks Mastery</h1>
                <p>Morem ipsum dolor sit amet consectetur.</p>
                <span>#blockchain Oct 1, 2023</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar />
    </div>
  );
};

export default WealthAcademy;
