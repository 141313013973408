import React from "react";
import "./Cryptocurrency.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const Cryptocurrency = () => {
const navigate=useNavigate();

  return (
    <div className="crytocurrency-main">
      <div className="wealth-arrow2-">
        <img src="/Images/arrowref.svg" alt=""  onClick={() => navigate(-1)}/>
        <p></p>
        <p></p>
      </div>
      <div className="crypto-news-main">
        <div className="cryto-news-first">
          <div>
            <img src="/Images/light.svg" alt=""/>
          </div>
          <div className="Building">
            <h2>
              Cryptocurrency News: Building in Resiliency and International
              Co-Operation
            </h2>
            <span>#blockchain . Oct 1, 2023 . 3 min read</span>
          </div>
        </div>
        <div className="cryptograph">
          <img src="/Images/cryptograph.svg" />
        </div>
        <div className="full-text">
          <p>
            Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
            turpis molestie, dictum est a, mattis tellus. Sed dignissim, metus
            nec fringilla accumsan, risus sem sollicitudin lacus, ut interdum
            tellus elit sed risus. Maecenas eget condimentum velit, sit amet
            feugiat lectus. Class aptent taciti sociosqu ad litora torquent per
            conubia nostra, per inceptos himenaeos. Praesent auctor purus luctus
            enim egestas, ac scelerisque ante pulvinar. Donec ut rhoncus ex.
            Suspendisse ac rhoncus nisl, eu tempor urna. Curabitur vel bibendum
            lorem. Morbi convallis convallis diam sit amet lacinia. Aliquam in
            elementum tellus. <span>Read More...</span>
          </p>
        </div>
        <div className="social-icons">
          <img src="/Images/fb.svg" />
          <img src="/Images/twi.svg" />
          <img src="/Images/in.svg" />
          <img src="/Images/tele.svg" />
          <img src="/Images/redit.svg" />
          <img src="/Images/share.svg" />
        </div>
      </div>
      <div className="research-main">
        <h2 className="Related-Research">Related Research</h2>
        <div className="related-search-first">
          <p>1</p>
          <div className="questions-part">
            <h2>How Is It Changing the Real Estate Industry?</h2>
            <span>#crypto . Sep 23, 2022</span>
          </div>
        </div>
        <div className="related-search-first">
          <p>1</p>
          <div className="questions-part">
            <h2>Why You Should Trade Cryptocurrencies in Coinbank</h2>
            <span>#crypto . Sep 23, 2022</span>
          </div>
        </div>
        <div className="related-search-first">
          <p>1</p>
          <div className="questions-part">
            <h2>Setting the Record Straight: BUSD Auto-Conversion</h2>
            <span>#crypto . Sep 23, 2022</span>
          </div>
        </div>
        <div className="related-search-first">
          <p>1</p>
          <div className="questions-part">
            <h2>BUSD Hits Its $20B Market Cap Milestone</h2>
            <span>#crypto . Sep 23, 2022</span>
          </div>
        </div>
        <div className="related-search-first">
          <p>1</p>
          <div className="questions-part">
            <h2>How to Use Binance Pay as a Payment Method on Binance P2P</h2>
            <span>#crypto . Sep 23, 2022</span>
          </div>
        </div>
      </div>
      <div className="Reports-main">
        <h2 className="unlock">Unlock Premium Market Reports</h2>
        <div className="monthly-btn">
          <button className="monthly">Monthly</button>
          <div className="anually-btn-for">

          <button className="Annually"><p className="andr-annualy-trans">Annually</p></button>
          </div>
          <button className="Biennially">Biennially</button>
        </div>
        <div className="pro-starter">
          <div className="pro">
            <div className="top-pro">
              <p>Pro</p>
              <h2>
                $129 <span className="year-span">/ year</span>
              </h2>
            </div>
            <div className="bottom-pro">
              <p className="Maximize">
                Maximize your gains with cutting - edge crypto insights
              </p>
              <div className="tick-and-text">
                <img src="/Images/tick.svg" />
                <h2>Research</h2>
              </div>
              <div className="tick-and-text">
                <img src="/Images/tick.svg" />
                <h2>Analysis</h2>
              </div>
              <div className="tick-and-text">
                <img src="/Images/tick.svg" />
                <h2>Community</h2>
              </div>
              <div className="tick-and-text">
                <img src="/Images/tick.svg" />
                <h2>Tools</h2>
              </div>
            </div>
            <div className="pro-subscribe">
              <button>Subscribe</button>
            </div>
          </div>
          <div>
            <div className="pro-starter">
              <div className="starter">
                <div className="top-pro">
                  <p>Starter</p>
                  <h2>
                    $0 <span className="year-span">/ month</span>
                  </h2>
                </div>
                <div className="bottom-pro">
                  <p className="Maximize">
                  Your Crypto success story starts here
                  </p>
                  <div className="tick-and-text">
                    <img src="/Images/tick.svg" />
                    <h2>Research</h2>
                  </div>
                  <div className="tick-and-text">
                    <img src="/Images/cross.svg" />
                    <h2>Analysis</h2>
                  </div>
                  <div className="tick-and-text">
                    <img src="/Images/cross.svg" />
                    <h2>Community</h2>
                  </div>
                  <div className="tick-and-text">
                    <img src="/Images/cross.svg" />
                    <h2>Tools</h2>
                  </div>
                </div>
                <div className="starter-subscribe">
                  <button><p className="ander-wala-text-subscribe">Subscribe</p></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div >
        <div className="see-all-3">
          <h2>Recommended</h2>
        
        </div>
        <div className="trending-main-bottom">
        <div className="trending-main">
            <img src="/Images/stocks.svg"/>
           <div className="stocks-content">
            <h1>Stocks Mastery</h1>
            <p>Morem ipsum dolor sit amet consectetur.</p>
            <span>#blockchain Oct 1, 2023</span>
           </div>
           </div>
           <div className="trending-main">
            <img src="/Images/mastry.svg"/>
           <div className="stocks-content">
            <h1>Forex Mastery</h1>
            <p>Morem ipsum dolor sit amet consectetur.</p>
            <span>#blockchain Oct 1, 2023</span>
           </div>
           </div>
           <div className="trending-main">
            <img src="/Images/crypto.svg"/>
           <div className="stocks-content">
            <h1>Crypto Mastery</h1>
            <p>Morem ipsum dolor sit amet consectetur.</p>
            <span>#blockchain Oct 1, 2023</span>
           </div>
           </div>
           {/* <div className="trending-main">
            <img src="/Images/stocks.svg"/>
           <div className="stocks-content">
            <h1>Stocks Mastery</h1>
            <p>Morem ipsum dolor sit amet consectetur.</p>
            <span>#blockchain Oct 1, 2023</span>
           </div>
           </div> */}
        </div>
        </div>
      </div>
      <Navbar/>
    </div>
  );
};

export default Cryptocurrency;
