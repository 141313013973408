import React from 'react'
import { Modal } from 'react-bootstrap';
import './QuizFail.css'
const QuizFail = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="QuizFail-first-modal" centered>
                <Modal.Body >
                    <div className='Cross-Icon-Quiz2'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />
                    </div>
                    <div className="text-center-quiz2">
                        <img src="/Images/Quiz/FailImage2.png" alt="" className='quiz-pic2' style={{height:"auto",width:"120px"}}/>
                        <p className='pass-text2'>Oh! You failed this test!</p>
                        <p className='earned-text2'>You can retake the test</p>
                        <button className='back-to-course-btn2' onClick={handleClose}>Retake the Test</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default QuizFail
