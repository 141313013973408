import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationEN from './locales/en/translation.json';
import translationRU from './locales/ru/translation.json';
import translationUK from './locales/uk/translation.json';
import translationZH from './locales/zh/translation.json';
import translationTR from './locales/tr/translation.json';

// the translations
const resources = {
  en: {
    translation: translationEN
  },
  ru: {
    translation: translationRU
  },
  uk: {
    translation: translationUK
  },
  zh: {
    translation: translationZH
  },
  tr: {
    translation: translationTR
  }
};

i18n
  .use(LanguageDetector) // Detect user language
  .use(initReactI18next) // Passes i18n down to react-i18next
  .init({
    resources,
    fallbackLng: 'en', // Use English if language is not detected
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;
