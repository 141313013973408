import React, { useState } from "react";
import { Drawer } from "@mui/material";
import "./SelectProfileImageSec.css";
import { useEffect } from "react";
import { getProfilePicture } from "../../../api/getProfilePicture";
import { useTranslation } from 'react-i18next';
const SelectProfileImageSec = ({ open, onClose, setSelectedProfileImage }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [loading, setLoading] = useState(false);
  const [profilePictures, setProfilePictures] = useState([]);
  const { t } = useTranslation();  
  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleConfirm = () => {
    if (selectedImageIndex !== null) {
      setSelectedProfileImage(profilePictures[selectedImageIndex]);
      onClose();
    }
  };

  useEffect(() => {
    async function getProfile() {
      setLoading(true);
      const data = await getProfilePicture();
      console.log(data, "cover Picture");
      setProfilePictures(data?.profilePictures);
      setLoading(false);
    }
    getProfile();
  }, []);
  return (
    <>
      <Drawer
        anchor="bottom"
        ModalProps={{
          sx: {
            backdropFilter: "blur(6px)",
          },
        }}
        PaperProps={{
          sx: {
            maxHeight: "70vh",
          },
        }}
        open={open}
        onClose={onClose}
      >
        {loading ? (
          <div className="loading-container-profile">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="SelectProfileImage-Main-Div2">
            <p className="Select-Profile-Image-text2">{t('Select Profile Image')}</p>

            <div className="Select-Profile-images-div-main">
              <div className="first-row-of-images-Select">
                {profilePictures?.map((image, index) => (
                  <img
                    key={index}
                    src={image}
                    alt=""
                    className={` ${
                      selectedImageIndex === index ? "activepic" : ""
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                ))}
              </div>
            </div>
            {/* <p className='Visit-Mag-text2'>
                    Visit the <span className='Mag-text-Bold2'>$MAG Store</span> to unlock premium profile images and stand out!
                </p> */}
            <button
              className="Confirm-Image-button-select-image2"
              onClick={handleConfirm}
            >
              {t('Confirm Image')}
            </button>
          </div>
        )}
      </Drawer>
    </>
  );
};

export default SelectProfileImageSec;
