import * as React from "react";
import { styled } from "@mui/material/styles";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardActions from "@mui/material/CardActions";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./Card.css";

const ExpandMore = styled((props) => {
  const { expand, ...other } = props;
  return <IconButton {...other} />;
})(({ theme }) => ({
  marginLeft: "auto",
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest,
  }),
  variants: [
    {
      props: ({ expand }) => !expand,
      style: {
        transform: "rotate(0deg)",
      },
    },
    {
      props: ({ expand }) => !!expand,
      style: {
        transform: "rotate(180deg)",
      },
    },
  ],
}));

export default function ExpandableCard() {
  const [expanded, setExpanded] = React.useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  return (
    <Card
      sx={{
        width: "100%",
        boxShadow: "none",
        borderRadius: "12px",
        border: "1px solid #BCBFCC80",
        padding: "0px !important",
        "& .css-pr9tn1-MuiButtonBase-root-MuiIconButton-root": {
          padding: "0px !important",
        },

        "& .css-wpanfz-MuiCardActions-root": {
          padding: "16px 17px 16px 16px !important",
        },

        "& .css-6ts3b8-MuiButtonBase-root-MuiIconButton-root": {
          padding: "0px !important",
        },
        "@media screen and (max-width: 335px)": {
          "& .css-wpanfz-MuiCardActions-root": {
            padding: "14px 15px 14px 14px !important",
          },
        },
      }}
    >
      <CardActions
        disableSpacing
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography
          sx={{
            fontFamily: "Montserrat, sans-sarif",
            fontSize: "14px",
            fontWeight: "600",
            lineHeight: "21px",
            letterSpacing: "0.30000001192092896px",
            textAlign: "left",
            "@media screen and (max-width: 335px)": {
              fontSize: "12px",
              lineHeight: "19px",
            },
          }}
        >
          How It Works?
        </Typography>
        <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore>
      </CardActions>

      <Collapse in={expanded} timeout="auto" unmountOnExit sx={{}}>
        <CardContent
          sx={{
            position:
              "relative" /* To make sure the background stays in place */,
            paddingTop: "20px" /* Ensure padding for content */,
            "&:before": {
              content: '""',
              position: "absolute",
              top: 0,
              left: 0,
              right: 0,
              height: "1px" /* Height of the gradient border */,
              background:
                "linear-gradient(90deg, rgba(188, 191, 204, 0) 0%, #BCBFCC 50%, rgba(188, 191, 204, 0) 100%)",
            },
            padding: "16px 16px 16px 16px !important",
          }}
        >
          <ol className="Card_Data">
            <li>
              <span className="bold_list_text">Earn $MAG:</span> Engage with the
              Magnus App by sharing it, completing tasks, and maintaining
              streaks to earn $MAG credits.
            </li>
            <li>
              <span className="bold_list_text">Convert to Tickets:</span>{" "}
              Exchange your $MAG credits for Mag Tickets, which are required for
              entry into the competitions. Mag Tickets are non-refundable but
              can be used for any MAG Competition, regardless of the prize.
            </li>
            <li>
              <span className="bold_list_text">Choose a Competition:</span>{" "}
              Select a competition to enter. Each competition remains open until
              the maximum number of tickets has been submitted.
            </li>
            <li>
              <span className="bold_list_text">Ticket Limits:</span> Each
              competition has a varying ticket cap, based on demand and the
              value of the prizes.
            </li>
            <li>
              <span className="bold_list_text">Winning:</span> Winners are
              randomly selected by the system from the submitted tickets. The
              more tickets you submit, the greater your chance of winning.
            </li>
          </ol>
          <p className="Card_Last_Description">
            Remember, entry into MAG Competitions is exclusive to users who have
            earned <span className="bold_list_text">$MAG;</span> direct payment
            is not accepted. This ensures a fair and engaging way to reward
            community participation.
          </p>
        </CardContent>
      </Collapse>
    </Card>
  );
}
