import React, { useState, useEffect, act } from "react";
import "./Ranking.css";
import { get_alltime_invites } from "../../api/get_alltime_invites";
import { get_invites_bytime } from "../../api/get_invites_bytime";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useTranslation } from 'react-i18next';
import PaginationToday from "../../Components/pagination_leaderboard/PaginationToday";
import PaginationWeekly from "../../Components/pagination_leaderboard/PaginationWeakly";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const Ranking = () => {
  const { t } = useTranslation();
  const [Act, SetAct] = useState("A");
  const [isLoading, setIsloading] = useState(true);
  const [invites_data, Setinvites_data] = useState();
  const [ranked_userdata, Setranked_userdata] = useState([]);
  const [Todaydata, setTodaydata] = useState();
  const [myrank, setmyrank] = useState(false);
  const [Weeklydata, setweeklydata] = useState([]);

  const [currentPage, setCurrentPage] = useState(1);
  const [current1Page, setCurrent1Page] = useState(1);

  const [time, setTime] = useState();

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const handlePage1Change = (event, value) => {
    setCurrent1Page(value);
  };
  useEffect(() => {
    const handle_todaydata = async () => {
      // setIsloading(true);
      if (Act === "D") {
        const invitesData = await get_alltime_invites();
        setTodaydata(invitesData);
        // Setranked_userdata(invitesData?.rankedUsers);
      } else {
        const timeframe =
          Act === "A"
            ? "daily"
            : Act === "B"
            ? "weekly"
            : Act === "C"
            ? "monthly"
            : "";
        const invitesData = await get_invites_bytime(
          `${currentPage}`,
          `${timeframe}`
        );
        setTodaydata(invitesData);
        console.log("-----------");
        console.log(invitesData);
      }
      if (Todaydata?.myPosition) {
        setmyrank(true);
      }
      setIsloading(false);
    };
    handle_todaydata();
  }, [currentPage, Act]);

  const handleClick = (active) => {
    SetAct(active);
  };

  const ranksPerPage = 20;
  const startRank = (current1Page - 1) * ranksPerPage + 1;
  const endRank = current1Page * ranksPerPage;
  const isOnCurrentPage =
    Todaydata?.currentUser?.rank >= startRank &&
    Todaydata?.currentUser?.rank <= endRank;
  const [isSticky, setIsSticky] = useState(!isOnCurrentPage);
  const handleScroll = () => {
    const rankElement = document.getElementById(
      `rank-${Todaydata?.currentUser?.rank}`
    );
    if (rankElement) {
      const rankPosition = rankElement.getBoundingClientRect().top;
      if (rankPosition <= window.innerHeight && rankPosition > 0) {
        setIsSticky(false); // Stop being sticky if the rank is visible
      } else {
        setIsSticky(true); // Sticky if rank is out of view
      }
    }
  };

  useEffect(() => {
    if (isOnCurrentPage) {
      window.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (isOnCurrentPage) {
        window.removeEventListener("scroll", handleScroll);
      }
    };
  }, [isOnCurrentPage]);

  return (
    <>
      {isLoading ? (
        // <div className="loading-container-ranking">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="images-uper-div">
          {Todaydata?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div2">
                <img
                  src={
                    Todaydata?.rankedUsers[1]?.profilePic
                      ? Todaydata?.rankedUsers[1]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "65px",
                    width: "65px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank2star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {Todaydata?.rankedUsers[1]?.user?.name
                    ? Todaydata?.rankedUsers[1]?.user?.name?.length > 5
                      ? `${Todaydata?.rankedUsers[1]?.user?.name.slice(
                          0,
                          5
                        )}...`
                      : Todaydata?.rankedUsers[1]?.user?.name
                    : Todaydata?.rankedUsers[1]?.user?.userName?.length > 5
                    ? `${Todaydata?.rankedUsers[1]?.user?.userName.slice(
                        0,
                        5
                      )}...`
                    : Todaydata?.rankedUsers[1]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {Todaydata?.myPosition === Todaydata?.rankedUsers[1].rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{Todaydata?.rankedUsers[1]?.referrals}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div2">
              <img
                src={
                  Todaydata?.rankedUsers[1]?.profilePic
                    ? Todaydata?.rankedUsers[1]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank2star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}

          {Todaydata?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div ">
                <img
                  src={
                    Todaydata?.rankedUsers[0]?.profilePic
                      ? Todaydata?.rankedUsers[0]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "88px",
                    width: "88px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank1star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {Todaydata?.rankedUsers[0]?.user?.name
                    ? Todaydata?.rankedUsers[0]?.user?.name?.length > 5
                      ? `${Todaydata?.rankedUsers[0]?.user?.name.slice(
                          0,
                          5
                        )}...`
                      : Todaydata?.rankedUsers[0]?.user?.name
                    : Todaydata?.rankedUsers[0]?.user?.userName?.length > 5
                    ? `${Todaydata?.rankedUsers[0]?.user?.userName.slice(
                        0,
                        5
                      )}...`
                    : Todaydata?.rankedUsers[0]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {Todaydata?.myPosition === Todaydata?.rankedUsers[0].rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{Todaydata?.rankedUsers[0]?.referrals}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div ">
              <img
                src={
                  Todaydata?.rankedUsers[0]?.profilePic
                    ? Todaydata?.rankedUsers[0]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "88px",
                  width: "88px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank1star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}

          {Todaydata?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div3">
                <img
                  src={
                    Todaydata?.rankedUsers[2]?.profilePic
                      ? Todaydata?.rankedUsers[2]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "65px",
                    width: "65px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank3star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {Todaydata?.rankedUsers[2]?.user?.name
                    ? Todaydata?.rankedUsers[2]?.user?.name.length > 5
                      ? `${Todaydata?.rankedUsers[2]?.user?.name.slice(
                          0,
                          5
                        )}...`
                      : Todaydata?.rankedUsers[2]?.user?.name
                    : Todaydata?.rankedUsers[2]?.user?.userName.length > 5
                    ? `${Todaydata?.rankedUsers[2]?.user?.userName.slice(
                        0,
                        5
                      )}...`
                    : Todaydata?.rankedUsers[2]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {" "}
                    {Todaydata?.myPosition === Todaydata?.rankedUsers[2].rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{Todaydata?.rankedUsers[2]?.referrals}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div3">
              <img
                src={
                  Todaydata?.rankedUsers[2]?.profilePic
                    ? Todaydata?.rankedUsers[2]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank3star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}
        </div>
      )}
      <div className="Ranking__Main">
        <div className="Ranking__Main_FirstDiv">
          <button
            className="Ranking__Main_FirstDiv_Button1"
            type="submit"
            style={{
              background:
                Act === "A"
                  ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                  : "transparent",
              color: Act === "A" ? "var(--White, #FFFFFF)" : "#1E1E24",
            }}
            onClick={() => handleClick("A")}
          >
            {t('Today')}
          </button>

          <button
            className="Ranking__Main_FirstDiv_Button2"
            type="submit"
            style={{
              background:
                Act === "B"
                  ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                  : "transparent",
              color: Act === "B" ? "var(--White, #FFFFFF)" : "#1E1E24",
            }}
            onClick={() => handleClick("B")}
          >
            {t('Week')}
          </button>

          <button
            className="Ranking__Main_FirstDiv_Button1"
            type="submit"
            style={{
              background:
                Act === "C"
                  ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                  : "transparent",
              color: Act === "C" ? "var(--White, #FFFFFF)" : "#1E1E24",
            }}
            onClick={() => handleClick("C")}
          >
            {t('Month')}
          </button>

          <button
            className="Ranking__Main_FirstDiv_Button1"
            type="submit"
            style={{
              background:
                Act === "D"
                  ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                  : "transparent",
              color: Act === "D" ? "var(--White, #FFFFFF)" : "#1E1E24",
            }}
            onClick={() => handleClick("D")}
          >
            {t('All Time')}
          </button>
        </div>
        <>
          {/* {isLoading ? (
            <div className="loading-container-ranking">
              <div className="spinner"></div>
            </div>
          ) : ( */}
          <>
            <div className="invite-list">
              {Todaydata?.rankedUsers?.slice(3)?.map((invitee, index) => (
                <div
                  key={index + 3}
                  className="invite-card"
                  style={{
                    border:
                      Todaydata?.myPosition === invitee?.rank
                        ? "1px solid #323232"
                        : "1px solid #bcbfcc80",
                  }}
                >
                  <div className="invite-id">
                    {invitee?.rank < 10 ? `0${invitee?.rank}` : invitee?.rank}
                  </div>
                  <div className="invite-details">
                    <div className="invite-icon">
                      <img
                        src={
                          invitee?.profilePic
                            ? invitee?.profilePic
                            : "/Images/ProfileActor.png"
                        }
                        alt="g"
                      />
                    </div>
                    <div className="invite-name">
                      {invitee?.user?.name
                        ? invitee?.user?.name
                        : invitee?.user?.userName}
                      <span className="invites">
                        {Todaydata?.myPosition === invitee?.rank && " (You)"}
                      </span>
                    </div>
                  </div>
                  <div className="invite-count">
                    <span className="invites">Invites</span>
                    <strong className="invites2">{invitee?.referrals}</strong>
                  </div>
                </div>
              ))}

              {!isOnCurrentPage && myrank && (
                <div
                  className={`invite-card ${isSticky ? "sticky-rank" : ""}`}
                  style={{
                    border: "1px solid #323232",
                    position: isSticky ? "sticky" : "relative",
                    bottom: isSticky ? "0" : "unset",
                    zIndex: isSticky ? 1 : "auto",
                  }}
                >
                  <div className="invite-id">
                    {Todaydata?.currentUser?.rank < 10
                      ? `0${Todaydata?.currentUser?.rank}`
                      : Todaydata?.currentUser?.rank}
                  </div>
                  <div className="invite-details">
                    <div className="invite-icon">
                      <img
                        src={
                          Todaydata?.currentUser?.profilePic
                            ? Todaydata?.currentUser?.profilePic
                            : "/Images/ProfileActor.png"
                        }
                        alt="g"
                      />
                    </div>
                    <div className="invite-name">
                      {Todaydata?.currentUser?.user?.name
                        ? Todaydata?.currentUser?.user?.name
                        : Todaydata?.currentUser?.user?.userName}
                      <span className="invites"> (You)</span>
                    </div>
                  </div>
                  <div className="invite-count">
                    <span className="invites">Invites</span>
                    <strong className="invites2">
                      {Todaydata?.currentUser?.referrals}
                    </strong>
                  </div>
                </div>
              )}
            </div>
            <div className="Ranking__pagenation">
              <Stack spacing={2}>
                <Pagination
                  count={Todaydata?.totalPages}
                  page={current1Page}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </>
          {/* )} */}
        </>
      </div>
    </>
  );
};

export default Ranking;
