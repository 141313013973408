import React, { useState, useEffect } from "react";
import "./AccountSetting.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { get_Profiledata } from "../../api/get_Profiledata";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useTranslation } from "react-i18next";

const AccountSetting = () => {
  const { t } = useTranslation();
  const [profileData, setprofileData] = useState();
  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    async function getProfileData() {
      setIsloading(true);
      const userData = await get_Profiledata();
      console.log("userData________________");
      console.log(userData);
      setprofileData(userData);
      setIsloading(false);
    }

    getProfileData();
  }, []);
  const navigate = useNavigate();
  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="AccountSetting-Main">
          <div className="AccountSetting-arrow">
            <img
              src="/Images/arrowref.svg"
              alt=""
              onClick={() => navigate(-1)}
            />
            <p className="AccountSetting-arrowtext">{t("Account Settings")}</p>
            <img src="/Images/arrowref2.svg" alt="" />
          </div>

          <div className="top-account-Detail-Main-Div">
            <div className="Top-account-detail-div-first">
              <div className="Profile-Picture-AccountSetting">
                <img
                  src={
                    profileData?.profile?.profilePicture
                      ? profileData.profile.profilePicture
                      : "/Images/ProfileActor.png"
                  }
                  alt="ProfileActor"
                  className="pic-height-width-setting"
                />
              </div>

              <div className="div-for-acount-names-setting">
                <p className="Account-Name-Div-for-setting">
                  {profileData?.profile?.displayName?.split(" ")[0]}
                </p>
                <p className="Account-Name-Div-for-setting2">
                  @{profileData?.profile?.telegramUsername}
                </p>
              </div>
            </div>
            <button
              className="edit-btn-profile"
              onClick={() => {
                navigate("/EditProfile");
              }}
            >
              <img src="/Images/AccountSetting/EditButtonSetting.svg" alt="" />
              <p className="edit-button-text-for-setting">{t("Edit")}</p>
            </button>
          </div>

          <img src="/Images/AccountSetting/horizontalLine.svg" alt="" />

          <div className="Main-Content-Div-for-AccountSetting">
            <div
              className="Main-Full-Tab-Div"
              onClick={() => {
                navigate("/SocialOtherView");
              }}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/PreviewProfile.svg" alt="" />
                <p className="Tab-Content-text">{t("Preview Profile")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>

            <div
              className="Main-Full-Tab-Div"
              onClick={() => navigate("/ConnectWallet")}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/WalletManagement.svg" alt="" />
                <p className="Tab-Content-text">{t("Wallet Management")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>

            <div
              className="Main-Full-Tab-Div"
              onClick={() => navigate("/Commision-History")}
            >
              <div className="Main-Tab-first-Div">
                <img
                  src="/Images/AccountSetting/CommissionHistory.svg"
                  alt=""
                />
                <p className="Tab-Content-text">{t("Commission History")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>

            {/* <div className='Main-Full-Tab-Div'>
                    <div className='Main-Tab-first-Div'>
                        <img src="/Images/AccountSetting/EarningHistory.svg" alt="" />
                        <p className='Tab-Content-text'>Earning History</p>
                    </div>
                    <img src="/Images/AccountSetting/Arrow.svg" alt="" />
                </div>

                <div className='Main-Full-Tab-Div'>
                    <div className='Main-Tab-first-Div'>
                        <img src="/Images/AccountSetting/OrderHistory.svg" alt="" />
                        <p className='Tab-Content-text'>Order History</p>
                    </div>
                    <img src="/Images/AccountSetting/Arrow.svg" alt="" />
                </div>
                <div className='Main-Full-Tab-Div'>
                    <div className='Main-Tab-first-Div'>
                        <img src="/Images/AccountSetting/MysteryPacks.svg" alt="" />
                        <p className='Tab-Content-text'>Mystery Packs</p>
                    </div>
                    <img src="/Images/AccountSetting/Arrow.svg" alt="" />
                </div>
                <div className='Main-Full-Tab-Div'>
                    <div className='Main-Tab-first-Div'>
                        <img src="/Images/AccountSetting/Security.svg" alt="" />
                        <p className='Tab-Content-text'>Security</p>
                    </div>
                    <img src="/Images/AccountSetting/Arrow.svg" alt="" />
                </div> */}
            <div
              className="Main-Full-Tab-Div"
              onClick={() => navigate("/SelectLanguage")}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/Language.svg" alt="" />
                <p className="Tab-Content-text">{t("Language")}</p>
              </div>
              <div className="language-arrow-div">
                <p className="Language-detail-text">{t("English (US)")}</p>
                <img src="/Images/AccountSetting/Arrow.svg" alt="" />
              </div>
            </div>
            <div
              className="Main-Full-Tab-Div"
              onClick={() => navigate("/TermsNConditions")}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/TermsofServices.svg" alt="" />
                <p className="Tab-Content-text">{t("Terms of Services")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>
            <div
              className="Main-Full-Tab-Div"
              style={{
                opacity: 0.5, // Lower the opacity to make it appear disabled
                pointerEvents: "none", // Disable any interaction with the div
              }}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/CommunitySocials.svg" alt="" />
                <p className="Tab-Content-text">{t("Community Socials")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>

            <div
              className="Main-Full-Tab-Div"
              style={{
                opacity: 0.5, // Lower the opacity to make it appear disabled
                pointerEvents: "none", // Disable any interaction with the div
              }}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/Support.svg" alt="" />
                <p className="Tab-Content-text">{t("Support")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>
            <div
              className="Main-Full-Tab-Div"
              style={{
                opacity: 0.5, // Lower the opacity to make it appear disabled
                pointerEvents: "none", // Disable any interaction with the div
              }}
            >
              <div className="Main-Tab-first-Div">
                <img src="/Images/AccountSetting/LearnCentre.svg" alt="" />
                <p className="Tab-Content-text">{t("Learn Centre")}</p>
              </div>
              <img src="/Images/AccountSetting/Arrow.svg" alt="" />
            </div>

            {/* <div className="Main-Full-Tab-Div">
          <div className="Main-Tab-first-Div">
            <img src="/Images/AccountSetting/Logout.svg" alt="" />
            <p className="Logout-Content-text">Logout</p>
          </div>
        </div> */}
          </div>
          {/* <Navbar /> */}
        </div>
      )}
    </>
  );
};

export default AccountSetting;
