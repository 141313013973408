import React from 'react';
import { Modal } from 'react-bootstrap';
import './ComingSoon.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const ComingSoon = ({ show, handleClose }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered dialogClassName="verify-modal-dialog">
                <Modal.Body className='ComingSoon-modal-body'>
                    <div className='Top-Img-Cross'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />

                    </div>
                    <div className="ComingSoon-modal-content text-centers">
                        <img src="/Images/Home/timer.png"  style={{width:"106.5px",height:"100px"}} alt="Verify Ok" />
                        <p className='ComingSoon-modal-text'>{t('Coming Soon')}</p>
                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default ComingSoon;
