import React from "react";
import "./TermsNConditions.css";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
const TermsNConditions = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="Terms_And_Condition_Main">
      {/* Header of Terms and Condition */}
      <div className="TermsN_Condition-arrow-div">
        <img
          src="/Images/Backicon.svg"
          alt="BackIcon"
          onClick={() => navigate(-1)}
        />
        <p className="TermsN_Condition-heading">{t('Terms Of Service')}</p>
        <img src="/Images/arrowref2.svg" alt=""/>
      </div>

      {/* Body of Terms and Condition Page */}
      <div className="TermsN_Condition_Body">
        <ol className="TermsN_Condition_Data">
          <li>
            <span>{t('Eligibility')}:</span> {t('Open to all users of the Bluemoon App. The competitions are void where prohibited by law.')}
          </li>
          <li>
            <span>{t('Entry Period:')}</span> {t("Competitions end once all tickets are claimed or after 60 days, whichever comes first. Unallocated tickets are returned to users.")}
          </li>
          <li>
            <span>{t('How to Enter:')}</span> {t('Engage with the Bluemoon App by sharing it, completing tasks, and maintaining streaks to earn xBLUE credits. No purchase necessary; purchasing does not enhance your chance of winning.')}
          </li>
          <li>
            <span>{t('Prizes')}:</span> {t('Prizes include in-app items, NFTs, Metaverse Assets, and Tokens, determined solely by Bluemoon Tech.')}
          </li>
          <li>
            <span>{t('Winner Selection & Notification:')}</span> {t('Winners are chosen randomly and notified within the app or by the contact details provided. Winners must claim their prize within the designated timeframe; otherwise, Bluemoon Tech may award the prize to another participant.')}
          </li>
          <li>
            <span>{t('Permissions')}:</span> {t('By entering, participants agree to let Bluemoon Tech use their name, and likeness for promotional purposes without compensation, where permitted by law.')}
          </li>
          <li>
            <span>{t('Competition Management:')}</span> {t('Bluemoon Tech may cancel or modify competitions if issues arise that corrupt the administration, security, fairness, or proper conduct of the competition.')}
          </li>
          <li>
            <span>{t('Liability')}:</span> {t('By entering, participants agree to release Bluemoon Tech from any liability for injury, loss, or damage related to the competitions.')}
          </li>
          <li>
            <span>{t('Governing Law:')}</span> {t('Competitions are governed by the laws of Dubai, UAE.')}
          </li>
          <li>
            <span>{t('Non-Affiliation with Apple and Google:')}</span> {t('Apple and Google are not sponsors or involved in any way with these competitions. All inquiries should be directed to Bluemoon Tech.')}
          </li>
        </ol>
      </div>
    </div>
  );
};

export default TermsNConditions;
