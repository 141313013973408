import { API_URL } from "../utils/client";

export const get_streakcount = async () => {
  try {
    const token = sessionStorage.getItem("token");
    console.log("token");
    console.log(token);
    const response = await fetch(`${API_URL}/streak/get-streak`, {
      method: "GET",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${token}`, 
      }
    });

    console.log(response, "this is console log");
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch  streak");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
