import React from "react";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

function RankingBar({ value }) {
  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      {/* Progress Bar */}
      <LinearProgress
        variant="determinate"
        value={value}
        sx={{
          height: "10px",
          borderRadius: 5,
          // Color for the moving progress (filled part)
          "& .MuiLinearProgress-bar": {
            backgroundColor: "#BCBFCC", // Gradient for filled portion
            borderRadius: 5,
          },
          // Color for the unfilled part of the bar
          backgroundColor: "#BCBFCC4D",
        }}
      />
    </Box>
  );
}

export default RankingBar;
