import React from 'react'
import './Verify.css'
import { useNavigate } from 'react-router-dom';

const Verify = () => {

  const navigate = useNavigate();

  return (
    <div className='MainVerify'>


      <div className='Top-ArrowDiv'>
        <img src="/Images/Backicon.svg" alt="BackIcon" onClick={() => navigate(-1)}/>
      </div>


      <div className='verify-Identity'>
        <div>
          <img src="/Images/verifyok.svg" alt="Verify Ok" />
        </div>
        <p className='verify-heading'>Verify your identity</p>
        <p className='verify-secheading'>Your identity helps you discover new people and opportunities</p>


      </div>

      <div className='input-div'>
        <div>
          <img src="/Images/Email.svg" alt="Email" />
        </div>
        <div className='Email-align'>
          <p className='Email-text'>Email</p>
          <p className='Email-input'>Verify with your emails</p> 

        </div>
      </div>


      <div className='button-div'>
        <button className='verify-button' onClick={() => navigate("/Verification")}>
          Verify
        </button>
      </div>
    </div>
  )
}

export default Verify
