import React, { useEffect, useState } from "react";
import "./Referrals.css";
import CustomTable from "../../Components/Referrals/Table/Table";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { getreffel } from "../../api/getreffel";
import { getreffelTeam } from "../../api/getreffelTeam";
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { handleInvite } from "../../utils/handleInvite";
import { useTranslation } from 'react-i18next';
const Referrals = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [raffel, setRaffel] = useState();
  const [raffelTeam, setRaffelTeam] = useState();
  const [raffelCode, setRaffelCode] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [Commission,setCommission]=useState();

  useEffect(() => {
    async function getRaffelInfo() {
      setIsloading(true);
      const id = sessionStorage.getItem("id");
      const token = sessionStorage.getItem("token");
      const dataUser = await getreffel(token);
      console.log(" _----------------raffel detail");
      console.log(dataUser);
      setRaffel(dataUser?.total);
      console.log(dataUser?.total);
      console.log(id, "id get successfully");
      setIsloading(false);
    }
    getRaffelInfo();
  }, []);
  useEffect(() => {
    async function getRaffelTeamInfo() {
      setIsloading(true);
      // const id = sessionStorage.getItem("id");
      const token = sessionStorage.getItem("token");
      const refferalCode = sessionStorage.getItem("referrer");
      console.log(refferalCode, "raffel code get successfully");
      setRaffelCode(refferalCode);
      const dataTeam = await getreffelTeam(token);
      console.log(dataTeam, "team -------------------raffel");
      setRaffelTeam(dataTeam?.total);
      setCommission(dataTeam?.commissions)
      // console.log(id, "id get successfully");
      setIsloading(false);
    }
    getRaffelTeamInfo();
  }, []);

  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  const handleCopy = () => {
    navigator.clipboard.writeText(
      `https://t.me/magnus_dev123bot?start=r_${raffelCode}`
    );
    setSaveChangesMessage("Copy to Clipboard");
    setShowSaveChanges(true);
  };

  const handleInviteClick = async () => {
    const chatId = sessionStorage.getItem("chatId");
    const userName = sessionStorage.getItem("userName");
    const refferalCode = sessionStorage.getItem("referrer");
    await handleInvite(chatId, userName,refferalCode);
    if (
      window.Telegram &&
      window.Telegram.WebApp &&
      window.Telegram.WebApp.close
    ) {
      window.Telegram.WebApp.close();
    } else {
      console.error("Telegram WebApp API is not available.");
    }
  };

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="referral-main">
          <div className="referral-arrow">
            <img
              src="/Images/arrowref.svg"
              alt=""
              onClick={() => navigate(-1)}
            />
            <p className="referral-arrowtext">{t('Referrals')}</p>
            <p></p>
          </div>

          <div className="referral-top1">
            {/* Level Referrel */}
            <div className="referral-top1-inner">
              <img
                src="/Images/referral/signal.svg"
                style={{ height: "30px", width: "30px" }}
                alt=".."
              />

              <div className="referral-top1-inner1">
                <p className="referral-top1-inner1-text">{t('Lv1 referrals')}</p>
                <p className="referral-top1-inner1-text1">{raffel ? raffel:"0"}</p>
              </div>

              <img src="/Images/referral/I_Icon.svg" alt="" />
            </div>

            {/* Commissions */}
            <div
              className="referral-top1-inner"
              onClick={() => navigate("/Commision-History")}
            >
              <img
                src="/Images/referral/signal.svg"
                style={{ height: "30px", width: "30px" }}
                alt=".."
              />
              <div className="referral-top1-inner1">
                <p className="referral-top1-inner1-text">{t('Commissions')}</p>
                <p className="referral-top1-inner1-text1">{Commission}</p>
              </div>

              <img src="/Images/referral/I_Icon.svg" alt="" />
            </div>

            {/* Total Earnings */}
            <div className="referral-top1-inner">
              <img
                src="/Images/referral/signal.svg"
                style={{ height: "30px", width: "30px" }}
                alt=".."
              />
              <div className="referral-top1-inner1">
                <p className="referral-top1-inner1-text">{t('Total Team')}</p>
                <p className="referral-top1-inner1-text1">{raffelTeam ? raffelTeam:"0"}</p>
              </div>

              <img src="/Images/referral/I_Icon.svg" alt="" />
            </div>

            {/* Total Earned */}
            <div className="referral-top1-inner">
              <img
                src="/Images/referral/signal.svg"
                style={{ height: "30px", width: "30px" }}
                alt=".."
              />
              <div className="referral-top1-inner1">
                <p className="referral-top1-inner1-text">{t('Team Volume')}</p>
                <p className="referral-top1-inner1-text1">0</p>
              </div>

              <img src="/Images/referral/I_Icon.svg" alt="" />
            </div>
          </div>

          <div className="referral-top2">
            <p className="referral-top2-text">{t('Referral Link')}</p>
            <div className="referral-top2-inner">
              <input
                type="text"
                className="referral-top2-inner-input"
                // placeholder="Enter link"
                value={`https://t.me/magnus_dev123bot?start=r_${raffelCode}`}
                readOnly
              />
              <img
                src="/Images/referral/copy.svg"
                className="Copy_Image"
                style={{ cursor: "pointer" }}
                alt=""
                onClick={handleCopy}
              />
              <img onClick={()=>{handleInviteClick()}} src="/Images/referral/share.svg" alt="share" />
            </div>
          </div>
          <div className="referrals-tableContainer">
            <CustomTable />
          </div>
          <Navbar />
          <AlertOk
            show={showSaveChanges}
            handleClose={() => setShowSaveChanges(false)}
            message={saveChangesMessage}
          />
        </div>
      )}
    </>
  );
};

export default Referrals;
