import React, { useEffect, useState } from "react";
import "./CommissionHistory.css";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import UsdtModel from "../../Components/Modal/Commission History/UsdtModel";
import XmadModel from "../../Components/Modal/Commission History/XmadModel";
import CommingSoonModel from "../../Components/Modal/Commission History/commingSoonModel";
import { get_commissionhistory } from "../../api/get_commissionhistory";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useTranslation } from 'react-i18next';
const CommissionHistory = () => {
  // Model states
  const [showusdtModel, setShowusdtModel] = useState(false);
  const [showxmadModel, setShowxmadModel] = useState(false);
  const [showCommingModel, setShowCommingModel] = useState(false);
  const [groupedData, setGroupedData] = useState({});
  const [isLoading, setIsloading] = useState(true);
  const [isMainNav, setMainNav] = useState("xMAG");

  const [isUSDTNAV, setUSDTNAV] = useState("earnusdt");
  const [isxMADNAV, setXMADNAV] = useState("earn");
  const [Comhistory, setcomhistory] = useState();
  const [Currentpage, setCurrentpage] = useState(1);
  
  const navigate = useNavigate();
  const { t } = useTranslation();
  useEffect(() => {
    async function getRewardData() {
      // setIsloading(true);
      const commissionData = await get_commissionhistory(`${Currentpage}`);
      console.log("commissionData--------");
      console.log(commissionData);
      setcomhistory(commissionData);
      console.log("______________commissionHistory________________");
      console.log(Comhistory?.commissionHistory);
      if (Comhistory?.commissionHistory) {
        const grouped = commissionData?.commissionHistory?.reduce(
          (acc, transaction) => {
            const { year, month } = transaction;
            if (!acc[year]) acc[year] = {};
            if (!acc[year][month]) acc[year][month] = [];
            acc[year][month]?.push(transaction);
            return acc;
          },
          {}
        );
        console.log("-----------grouped----------");
        console.log(grouped);

        setGroupedData(grouped);
        setIsloading(false);
      }
    }
    getRewardData();
  }, [Comhistory]);

  const handleMainNav = (currentBtn) => {
    setMainNav(currentBtn);
  };

  const handleUSDTNAV = (currentBtn) => {
    setUSDTNAV(currentBtn);
  };

  const handleXMADNAV = (currentBtn) => {
    setXMADNAV(currentBtn);
  };

  const openusdtVoyagerModal = () => {
    groupedData.length !== 0
      ? setShowusdtModel(true)
      : setShowCommingModel(true);
  };

  const closeusdtVoyagerModal = () => {
    setShowusdtModel(false);
  };

  //   handle xmad Model Open and close
  const openxmadVoyagerModal = () => {
    groupedData.length !== 0
      ? setShowxmadModel(true)
      : setShowCommingModel(true);
  };

  const closexmadVoyagerModal = () => {
    setShowxmadModel(false);
  };

  const closeCommingModel = () => {
    setShowCommingModel(false);
  };

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        <ScreenLoader />
      ) : (
        // </div>
        <div className="Commision_History_Main">
          {/* Header of Generate Ticket */}
          <div className="Commision_History-arrow-div">
            <img
              src="/Images/Backicon.svg"
              alt="BackIcon"
              onClick={() => navigate(-1)}
            />
            <p className="Commision_History-heading">{t('Commission History')}</p>
            <p></p>
          </div>

          {/* Body of Generate Ticket */}
          <div className="Commision_History_Body">
            {/* Body head main Card */}
            <div
              className="referral-top1-inner"
              onClick={() => navigate("/Commision-History")}
            >
              <div className="commission-top1-inner1">
                <div style={{ width: "50%" }}>
                  <p className="referral-top1-inner1-text">USDT</p>
                  <p className="referral-top1-inner1-text1">$0</p>
                </div>
                <div style={{ width: "50%" }}>
                  <p className="referral-top1-inner1-text">xMAG</p>
                  <p className="referral-top1-inner1-text1">
                    {Comhistory ? Comhistory?.totalMags : "0"}
                  </p>
                </div>
              </div>

              <img src="/Images/referral/I_Icon.svg" alt="" />
            </div>

            <div className="TH_Button_Transactions_Section">
              <div
                className={`TH_TransactionButton_Button ${
                  isMainNav === "xMAG"
                    ? "TH_TransactionButton_Button_Selected"
                    : ""
                }`}
                onClick={() => handleMainNav("xMAG")}
              >
                xMAG
              </div>
              <div
                className={`TH_TransactionButton_Button ${
                  isMainNav === "usdt"
                    ? "TH_TransactionButton_Button_Selected"
                    : ""
                }`}
                onClick={() => handleMainNav("usdt")}
              >
                USDT
              </div>
            </div>

            {isMainNav === "xMAG" && (
              // <div className="Commision_History_Withdraw_Nav">
              //   <div className="CH_Withdraw_Nav_Btns">
              //     <div
              //       className={isUSDTNAV ? "selected_Transac" : ""}
              //       onClick={() => handleUSDTNAV("earnusdt")}
              //     >
              //       Earned
              //     </div>
              //     <div
              //       className={!isUSDTNAV ? "selected_Transac" : ""}
              //       onClick={() => handleUSDTNAV("withdraw")}
              //     >
              //       Withdrawn
              //     </div>
              //   </div>

              //   <div
              //     className="CH_withdraw_Nav_Filter"
              //     onClick={openusdtVoyagerModal}
              //   >
              //     <img src="/Images/CommisionHistory/filter.svg" alt="" />
              //   </div>
              // </div>

              <>
                <div className="Commision_History_Withdraw_Nav">
                  <div className="CH_Withdraw_Nav_Btns">
                    <div
                      className={isxMADNAV === "earn" ? "selected_Transac" : ""}
                      onClick={() => handleXMADNAV("earn")}
                    >
                      {t('Earned')}
                    </div>
                    <div
                      className={
                        isxMADNAV === "spent" ? "selected_Transac" : ""
                      }
                      onClick={() => handleXMADNAV("spent")}
                    >
                      {t('Spent')}
                    </div>
                  </div>

                  <div
                    className="CH_withdraw_Nav_Filter"
                    onClick={openxmadVoyagerModal}
                  >
                    <img src="/Images/CommisionHistory/filter.svg" alt="" />
                  </div>
                </div>

                <div
                  className="Commission_History_Body_Transactions"
                  style={{ width: "100%" }}
                >
                  {isxMADNAV === "earn" &&
                    Object?.keys(groupedData)
                      ?.sort((a, b) => b - a)
                      ?.map((year) => (
                        <div
                          key={year}
                          className="CH_Body_Div"
                          style={{ width: "100%" }}
                        >
                          {Object?.keys(groupedData[year])
                            ?.sort(
                              (a, b) =>
                                new Date(`${b} 1 ${year}`) -
                                new Date(`${a} 1 ${year}`)
                            )
                            ?.map((month) => (
                              <div key={month} style={{ width: "100%" }}>
                                <div className="CH_Body_Div_Date">
                                  {month} {year}
                                </div>

                                <div className="CH_Body-Div_Transactions_Section">
                                  <div className="Ch_Body_Transaction_Div">
                                    {groupedData[year][month]?.map((tr) => (
                                      <div
                                        key={tr?._id}
                                        className="Ch_Body_Transaction_Div_Body"
                                      >
                                        <div className="CH_TransBosy_S1">
                                          <p className="CH_TransBosy_S1_Date">
                                            {tr?.source}
                                          </p>
                                          <div className="CH_TransBosy_S1_Date_details">
                                            <p>
                                              {new Date(
                                                tr?.completedAt
                                              ).toLocaleDateString()}
                                            </p>
                                            <span></span>
                                            <p>
                                              {new Date(
                                                tr?.completedAt
                                              ).toLocaleTimeString()}
                                            </p>
                                          </div>
                                        </div>

                                        <div className="CH_TransBosy_S2">
                                          <div className="CH_Transation_Number">
                                            <img
                                              src="/Images/CommisionHistory/mag.svg"
                                              alt="/"
                                            />
                                            <p>{tr?.points}</p>
                                          </div>
                                          <div
                                            className="Ch_Transaction_Status"
                                            style={{
                                              color:
                                                tr?.status === "Processing"
                                                  ? "#FF9900"
                                                  : "#04C182",
                                            }}
                                          >
                                            {tr?.status}
                                          </div>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                </div>
                              </div>
                            ))}
                        </div>
                      ))}
                  {isxMADNAV === "spent" && (
                    <div className="CH_Comming-Soon_Section">
                      <img
                        src="/Images/CommisionHistory/Cooming_Soon_Image.png"
                        alt="Coming Soon"
                      />
                      <p>{t('Coming Soon')}</p>
                    </div>
                  )}
                </div>
              </>
            )}
            {isMainNav === "usdt" && (
              <>
                <div className="Commision_History_Withdraw_Nav">
                  <div className="CH_Withdraw_Nav_Btns">
                    <div
                      className={
                        isUSDTNAV === "earnusdt" ? "selected_Transac" : ""
                      }
                      onClick={() => handleUSDTNAV("earnusdt")}
                    >
                      {t('Earned')}
                    </div>
                    <div
                      className={
                        isUSDTNAV === "withdraw" ? "selected_Transac" : ""
                      }
                      onClick={() => handleUSDTNAV("withdraw")}
                    >
                      {t('Withdrawn')}
                    </div>
                  </div>

                  <div
                    className="CH_withdraw_Nav_Filter"
                    onClick={openusdtVoyagerModal}
                  >
                    <img src="/Images/CommisionHistory/filter.svg" alt="" />
                  </div>
                </div>

                {/* <div className="Commision_History_Withdraw_Nav">
           <div className="CH_Withdraw_Nav_Btns">
             <div
               className={isxMADNAV ? "selected_Transac" : ""}
               onClick={() => handleXMADNAV("earn")}
             >
               Earned
             </div>
             <div
               className={!isxMADNAV ? "selected_Transac" : ""}
               onClick={() => handleXMADNAV("spent")}
             >
               Spent
             </div>
           </div>

           <div
             className="CH_withdraw_Nav_Filter"
             onClick={openxmadVoyagerModal}
           >
             <img src="/Images/CommisionHistory/filter.svg" alt="" />
           </div>
         </div> */}

                <div
                  className="Commission_History_Body_Transactions"
                  style={{ width: "100%" }}
                >
                  {isxMADNAV === "earn" && (
                    //  Object.keys(groupedData)
                    //    .sort((a, b) => b - a)
                    //    .map((year) => (
                    //      <div key={year} className="CH_Body_Div" style={{ width: "100%" }}>
                    //        {Object.keys(groupedData[year])
                    //          .sort((a, b) => new Date(`${b} 1 ${year}`) - new Date(`${a} 1 ${year}`))
                    //          .map((month) => (
                    //            <div key={month} style={{ width: "100%" }}>

                    //              <div className="CH_Body_Div_Date">
                    //                {month} {year}
                    //              </div>

                    //              <div className="CH_Body-Div_Transactions_Section">
                    //                <div className="Ch_Body_Transaction_Div">
                    //                  {groupedData[year][month]?.map((tr) => (
                    //                    <div key={tr._id} className="Ch_Body_Transaction_Div_Body">
                    //                      <div className="CH_TransBosy_S1">
                    //                        <p className="CH_TransBosy_S1_Date">{tr.source}</p>
                    //                        <div className="CH_TransBosy_S1_Date_details">
                    //                          <p>{new Date(tr.completedAt).toLocaleDateString()}</p>
                    //                          <span></span>
                    //                          <p>{new Date(tr.completedAt).toLocaleTimeString()}</p>
                    //                        </div>
                    //                      </div>

                    //                      <div className="CH_TransBosy_S2">
                    //                        <div className="CH_Transation_Number">
                    //                          <img
                    //                            src="/Images/CommisionHistory/mag.svg"
                    //                            alt="/"
                    //                          />
                    //                          <p>{tr.points}</p>
                    //                        </div>
                    //                        <div
                    //                          className="Ch_Transaction_Status"
                    //                          style={{
                    //                            color:
                    //                              tr.status === "Processing" ? "#FF9900" : "#04C182",
                    //                          }}
                    //                        >
                    //                          {tr.status}
                    //                        </div>
                    //                      </div>
                    //                    </div>
                    //                  ))}
                    //                </div>
                    //              </div>
                    //            </div>
                    //          ))}
                    //      </div>
                    //    ))
                    <div className="CH_Comming-Soon_Section">
                      <img
                        src="/Images/CommisionHistory/Cooming_Soon_Image.png"
                        alt=""
                      />
                      <p>{t('Coming Soon')}</p>
                    </div>
                  )}
                  {isxMADNAV === "spent" && (
                    <div className="CH_Comming-Soon_Section">
                      <img
                        src="/Images/CommisionHistory/Cooming_Soon_Image.png"
                        alt=""
                      />
                      <p>{t('Coming Soon')}</p>
                    </div>
                  )}
                </div>
              </>
            )}
          </div>

          <Navbar />
          <UsdtModel onClose={closeusdtVoyagerModal} open={showusdtModel} />
          <XmadModel onClose={closexmadVoyagerModal} open={showxmadModel} />
          <CommingSoonModel
            onClose={closeCommingModel}
            open={showCommingModel}
          />
        </div>
      )}
    </>
  );
};

export default CommissionHistory;
