import { API_URL } from "../utils/client";

export const editprofile = async (
  displayName,
  bio,
  coverPicture,
  profilePicture,
  XLink,
  email,
  discordLink,
  instagramLink,
  linkedinLink,
  facebookLink,
  youtubeLink,
  token
) => {
  try {
    const response = await fetch(`${API_URL}/profile/telegram/update-profile`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        displayName,
        bio,
        coverPicture,
        profilePicture,
        XLink,
        email,
        discordLink,
        instagramLink,
        linkedinLink,
        facebookLink,
        youtubeLink,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
