import { API_URL } from "../utils/client";

export const emailVerification = async (token , email) => {
  try {
    const response = await fetch(`${API_URL}/profile/verify-email-telegram`, {
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email,
      }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
