import React from 'react';
import { Modal } from 'react-bootstrap';
import './VerifyModal.css';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const VerifyModal = ({ show, handleClose }) => {
    const navigate = useNavigate();
    const { t } = useTranslation(); 
    return (
        <div>
            <Modal show={show} onHide={handleClose} centered dialogClassName="verify-modal-dialog">
                <Modal.Body className='verify-modal-body'>
                    <div className='Top-Img-Cross'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />

                    </div>
                    <div className="verify-modal-content text-centers">
                        <img src="/Images/ModalImg.svg" alt="Verify Ok" />
                        <p className='verify-modal-text'>{t('Email Linked Successfully')}</p>
                        <button className='verify-modal-button' onClick={() => navigate("/EditProfile")}>{t('Continue')}</button>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VerifyModal;
