import React, { useEffect, useState } from "react";
import "./Profile.css";
import { useNavigate } from "react-router-dom";
import { register } from "../../api/register";
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useTranslation } from 'react-i18next';
const Profile = () => {
  const { t }=useTranslation();
  const navigate = useNavigate();
  const [telegramUsername, setTelegramUsername] = useState("");
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [referrer, setReferrer] = useState("");
  const [chatId, setChatId] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  const handleSubmit = async () => {
    try {
      setIsloading(true);
      setSaveChangesMessage("");
      const data = await register(
        chatId,
        telegramUsername,
        name,
        email,
        referrer,
        true

      );
      console.log(data, "this is data ");

      if (data) {
        setIsloading(false);
        console.log("Registration successful", data);
        sessionStorage.setItem("referrer", data?.currUser?.referralCode);
        sessionStorage.setItem("token", data?.accessToken);
        sessionStorage.setItem("id", data?.currUser?._id);
        navigate("/Home");
      } else {
        setSaveChangesMessage("Invalid referral code");
        setShowSaveChanges(true);
        setIsloading(false);
        console.log("No data returned from registration");
      }
    } catch (error) {
      setIsloading(false);
      console.error("Error during registration:", error);
    }
  };
  useEffect(() => {
    const data = sessionStorage.getItem("userName");
    const chatIds = sessionStorage.getItem("chatId");

    setTelegramUsername(data);
    setChatId(chatIds);
  }, []);
  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="main-profile">
          <div className="setup-profile">
            <div className="image-and-text">
              <img
                className="for-circle-image"
                src="/Images/topcircle.svg"
                alt="111"
              />
              <img src="/Images/magnusicon.svg" alt="111" />
            </div>
            <div className="text-setup-profile">
              <h2>{t('Register Your Account')}</h2>
            </div>
          </div>
          <div className="profile-main-top">
            <div className="profile-input-main">
              <div className="heading-profile-input">
                <p className="telegram-username">{t('Telegram Username')}</p>
                <input
                  type="text"
                  className="profile-input"
                  value={telegramUsername ? `@${telegramUsername.replace(/^@/, '')}` : ''}
                  readOnly
                />
              </div>
              <div className="heading-profile-input">
                <p className="telegram-username">
                  {t('Name')} <span className="optional-text-style"> {t('(Optional)')}</span>
                </p>
                <input
                  type="text"
                  placeholder={t('Enter Name')}
                  className="profile-input"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  maxLength={13}
                />
              </div>
              <div className="heading-profile-input">
                <p className="telegram-username">
                  {t('Email')} <span className="optional-text-style"> {t('(Optional)')}</span>
                </p>
                <input
                  placeholder={t('Enter Email')}
                  type="email"
                  className="profile-input"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </div>
              <div className="heading-profile-input">
                <p className="telegram-username">
                  {t('Referral ID')}{" "}
                  <span className="optional-text-style"> {t('(Optional)')}</span>
                </p>
                <input
                  type="text"
                  placeholder={t('Enter Referral ID')}
                  className="profile-input"
                  value={referrer}
                  onChange={(e) => setReferrer(e.target.value)}
                />
              </div>
            </div>
            <div className="profile-bottom-button">
              <button className="profile-btn" onClick={handleSubmit}>
                {t('Register')}
              </button>
            </div>
          </div>
        </div>
      )}
      <AlertOk
        show={showSaveChanges}
        handleClose={() => setShowSaveChanges(false)}
        message={saveChangesMessage}
      />
    </>
  );
};

export default Profile;
