import * as React from "react";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import { useState } from "react";
import { useEffect } from "react";
import { getRankingTasks } from "../../api/getRankingTasks";
import { getRankingTime } from "../../api/getRaningTime";
import { useTranslation } from 'react-i18next';
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import "./Ranking.css";

const Ranking2 = () => {

  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(true);
  const [Act, SetAct] = useState("daily");
  const [data, setData] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [myPosition, setMyPosition] = useState();

  const handleClick = (active) => {
    SetAct(active);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  useEffect(() => {
    const gettimedata = async () => {
      // setIsloading(true);
      const token = sessionStorage.getItem("token");
      try {
        if (Act === "D") {
          const allTimeData = await getRankingTasks(token, currentPage);
          console.log(allTimeData, "all time data");
          setData(allTimeData);
          setTotalPages(allTimeData?.totalPages);
          setMyPosition(allTimeData?.myPosition);
        } else {
          const timeframeData = await getRankingTime(token, Act, currentPage);
          console.log(timeframeData, "daily weekly monthly  data");
          setData(timeframeData);
          setTotalPages(timeframeData?.totalPages);
          setMyPosition(timeframeData?.myPosition);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
      setIsloading(false);
    };
    gettimedata();
  }, [Act, currentPage]);
  return (
    <>
      {isLoading ? (
        // <div className="loading-container-ranking">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="images-uper-div">
          {data?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div2">
                <img
                  src={
                    data?.rankedUsers[1]?.profilePic
                      ? data?.rankedUsers[1]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "65px",
                    width: "65px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank2star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {data?.rankedUsers[1]?.user?.name
                    ? data?.rankedUsers[1]?.user?.name?.length > 5
                      ? `${data?.rankedUsers[1]?.user?.name?.slice(0, 5)}...`
                      : data?.rankedUsers[1]?.user?.name
                    : data?.rankedUsers[1]?.user?.userName?.length > 5
                    ? `${data?.rankedUsers[1]?.user?.userName?.slice(0, 5)}...`
                    : data?.rankedUsers[1]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {" "}
                    {data?.myPosition === data?.rankedUsers[1]?.rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{data?.rankedUsers[1]?.tasks}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div2">
              <img
                src={
                  data?.rankedUsers[1]?.profilePic
                    ? data?.rankedUsers[1]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank2star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}
          {data?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div ">
                <img
                  src={
                    data?.rankedUsers[0]?.profilePic
                      ? data?.rankedUsers[0]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "80px",
                    width: "80px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank1star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {data?.rankedUsers[0]?.user?.name
                    ? data?.rankedUsers[0]?.user?.name?.length > 5
                      ? `${data?.rankedUsers[0]?.user?.name?.slice(0, 5)}...`
                      : data?.rankedUsers[0]?.user?.name
                    : data?.rankedUsers[0]?.user?.userName?.length > 5
                    ? `${data?.rankedUsers[0]?.user?.userName?.slice(0, 5)}...`
                    : data?.rankedUsers[0]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {data?.myPosition === data?.rankedUsers[0]?.rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{data?.rankedUsers[0]?.tasks}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div ">
              <img
                src={
                  data?.rankedUsers[0]?.profilePic
                    ? data?.rankedUsers[0]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "88px",
                  width: "88px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank1star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}
          {data?.rankedUsers[3] ? (
            <div className="image-text-wraper">
              <div className="leaderborad__middle___image___div3">
                <img
                  src={
                    data?.rankedUsers[2]?.profilePic
                      ? data?.rankedUsers[2]?.profilePic
                      : "/Images/ProfileActor.png"
                  }
                  alt="..."
                  style={{
                    height: "65px",
                    width: "65px",
                    borderRadius: "200px",
                  }}
                />
                <img
                  src="/Images/Leaderboardimages/rank3star.svg"
                  alt=""
                  className="inner___1___img"
                />
              </div>
              <div className="below-image">
                <p className="name-id">
                  {data?.rankedUsers[2]?.user?.name
                    ? data?.rankedUsers[2]?.user?.name?.length > 5
                      ? `${data?.rankedUsers[2]?.user?.name?.slice(0, 5)}...`
                      : data?.rankedUsers[2]?.user?.name
                    : data?.rankedUsers[2]?.user?.userName?.length > 5
                    ? `${data?.rankedUsers[2]?.user?.userName?.slice(0, 5)}...`
                    : data?.rankedUsers[2]?.user?.userName}
                  <span style={{ fontWeight: "400", fontSize: "10px" }}>
                    {" "}
                    {data?.myPosition === data?.rankedUsers[2]?.rank
                      ? "(YOU)"
                      : ""}
                  </span>
                </p>
                <p className="number">{data?.rankedUsers[2]?.tasks}</p>
              </div>
            </div>
          ) : (
            <div className="leaderborad__middle___image___div2">
              <img
                src={
                  data?.rankedUsers[2]?.profilePic
                    ? data?.rankedUsers[2]?.profilePic
                    : "/Images/ProfileActor.png"
                }
                alt="..."
                style={{
                  height: "65px",
                  width: "65px",
                  borderRadius: "200px",
                }}
              />
              <img
                src="/Images/Leaderboardimages/rank3star.svg"
                alt=""
                className="inner___1___img"
              />
            </div>
          )}
        </div>
      )}
      <div className="Ranking__Main">
        <div className="Ranking__Main_FirstDiv">
          {["daily", "weekly", "monthly", "D"]?.map((value) => (
            <button
              key={value}
              className="Ranking__Main_FirstDiv_Button1"
              type="button"
              style={{
                background:
                  Act === value
                    ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                    : "transparent",
                color: Act === value ? "var(--White, #FFFFFF)" : "#1E1E24",
              }}
              onClick={() => handleClick(value)}
            >
             {value === "daily"
  ? t('Today') 
  : value === "weekly"
  ? t('Week') 
  : value === "monthly"
  ? t('Month') 
  : t('All Time')}
            </button>
          ))}
        </div>
        {/* {isLoading ? (
          <div className="loading-container-ranking">
            <div className="spinner"></div>
          </div>
        ) : ( */}
          <>
            <div className="invite-list">
              {data?.rankedUsers?.slice(3)?.map((item, index) => (
                <div
                  key={index + 3}
                  className="invite-card"
                  style={{
                    border:
                      data?.myPosition === item?.rank
                        ? "1px solid #323232"
                        : "1 px solid #BCBFCC80 ",
                  }}
                >
                  <div className="invite-id">{item?.rank}</div>
                  <div className="invite-details">
                    <div className="invite-icon">
                      <img
                        src={
                          item?.profilePic
                            ? item?.profilePic
                            : "/Images/ProfileActor.png"
                        }
                        alt=""
                      />
                    </div>
                    <div className="invite-name">
                      {item?.user?.userName}
                      <span className="invites">
                        {data?.myPosition === item?.rank && " (You)"}
                      </span>
                    </div>
                  </div>
                  <div className="invite-count">
                    <span className="invites">Completed</span>
                    <strong className="invites2">{item?.tasks}</strong>
                  </div>
                </div>
              ))}
            </div>

            <div className="Ranking__pagenation">
              <Stack spacing={2}>
                <Pagination
                  count={totalPages}
                  page={currentPage}
                  onChange={handlePageChange}
                />
              </Stack>
            </div>
          </>
        {/* )} */}
      </div>
    </>
  );
};

export default Ranking2;
