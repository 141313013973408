import React, { useState, useRef, useEffect, act } from "react";
import ComingSoon from "../../Components/Modal/comingSoon/ComingSoon";
import Navbar from "../../Components/Navbar/Navbar";
import { get_notification } from "../../api/get_notification";
import { useTranslation } from "react-i18next";

import "./Home.css";
import {
  LinearProgress,
  Box,
  Drawer,
  Typography,
  Card,
  CardContent,
} from "@mui/material";
import Header from "../../Components/Header/Header";
import { get_home_userdetails } from "../../api/get_home_userdetails";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import { useNavigate } from "react-router-dom";
import { mining } from "../../api/mining";
const Home = () => {
  const { t } = useTranslation();
  const [isLoading, setIsloading] = useState(false);
  const [selectedImage, setSelectedImage] = useState("/Images/Home/blck.png");
  const [forpay, setForpay] = useState();
  const [isRotating, setIsRotating] = useState(false);
  const [isClaimable, setIsClaimable] = useState(false);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [userdata, setUserdata] = useState();
  const [isDragging, setIsDragging] = useState(false);
  const [startY, setStartY] = useState(0);
  const [counters, setCounters] = useState([1, 1, 1, 1, 1, 1]);
  const [dragDistance, setDragDistance] = useState(0);
  const drawerRef = useRef(null);
  const [showModal, setShowModal] = useState(false);
  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [counter, setCounter] = useState(0); // SKAI earned counter
  const [isMining, setIsMining] = useState(false); // Mining active state
  const currentUserId = sessionStorage.getItem("id");
  const drawerdata = [
    {
      image: "/Images/Home/Bluefan.png",
      background:
        "radial-gradient(74.77% 74.77% at 50% 33.15%, #59ACFF 0%, #006CDA 100%)",
      text1: "10",
      text2: "GH/S",
      text3: "bOOST 1",
      value: "Boost 1",
      count: "5,000",
    },
    {
      image: "/Images/Home/greenfan.png",
      background:
        "radial-gradient(74.77% 74.77% at 50% 33.15%, #88FF94 0%, #47BB52 100%)",
      text1: "100",
      text2: "GH/S",
      text3: "bOOST 2",
      value: "Boost 2",
      count: "10,000",
    },
    {
      image: "/Images/Home/Pink.png",
      background:
        "radial-gradient(74.77% 74.77% at 50% 33.15%, #FC6DAB 0%, #FF157B 100%)",
      text1: "500",
      text2: "GH/S",
      text3: "bOOST 3",
      value: "Boost 3",
      count: "25,000",
    },
    {
      image: "/Images/Home/Purple.png",
      background:
        "radial-gradient(74.77% 74.77% at 50% 33.15%, #D27CFF 0%, #C04CFD 100%) ",
      text1: "1000",
      text2: "GH/S",
      text3: "bOOST 4",
      value: "Boost 4",
      count: "50,000",
    },
    {
      image: "/Images/Home/Orange.png",
      background:
        "radial-gradient(66.44% 66.44% at 47.75% 41.49%, #FFD639 0%, #FB6900 100%)",
      text1: "5000",
      text2: "GH/S",
      text3: "bOOST 5",
      value: "Boost 5",
      count: "100,000",
    },
    {
      image: "/Images/Home/blck.png",
      background:
        "radial-gradient(63.71% 63.71% at 50% 36.29%, #373E4B 0%, #000000 100%)",
      text1: "10000",
      text2: "GH/S",
      text3: "bOOST 5",
      value: "Boost 6",
      count: "200,000",
    },
  ];

  const handleSubmit = async () => {
    try {
      setIsloading(true);
      setSaveChangesMessage("");
      const token = sessionStorage.getItem("token");
      const magEarned = sessionStorage.getItem("counter");
      const startTime = sessionStorage.getItem("miningStartTime");
      const data = await mining(token, magEarned, startTime);
      console.log(data, "this is data ");
      if (data) {
      } else {
        setSaveChangesMessage("Invalid referral code");
        setShowSaveChanges(true);
        console.log("No data returned from registration");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
    setIsloading(false);
  };
  const handleClaim = async () => {
    try {
      setIsloading(true);
      setSaveChangesMessage("");
      const token = sessionStorage.getItem("token");
      const magEarned = sessionStorage.getItem("counter");
      const data = await mining(token, magEarned);
      console.log(data, "this is data ");
      if (data) {
      } else {
        setSaveChangesMessage("Invalid referral code");
        setShowSaveChanges(true);
        console.log("No data returned from registration");
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
    setIsloading(false);
  };


  const startRotation = () => {
    if (!isRotating) {
      const now = new Date();
      localStorage.setItem("miningStartTime", now);
      localStorage.setItem("miningUserId", currentUserId);
      setIsMining(true);
      setIsRotating(true);
      console.log("Mining started at:", now);

      setTimeout(() => {
        setIsRotating(false);
        setIsMining(false);
        setIsClaimable(true);
        localStorage.removeItem("miningStartTime");
        localStorage.removeItem("miningUserId");
      }, 24 * 60 * 60 * 1000);
    }
  };
  // useEffect(() => {
  //   if (isMining) {
  //     const storedTime = localStorage.getItem("miningStartTime");
  //     const startTime = new Date(storedTime);

  //     const intervalId = setInterval(() => {
  //       const now = new Date();
  //       const elapsedMilliseconds = now - startTime;
  //       const elapsedSeconds = elapsedMilliseconds / 1000;

  //       const skaiPerSecond = 0.001;
  //       const newCounterValue = (elapsedSeconds * skaiPerSecond).toFixed(4);

  //       setCounter(parseFloat(newCounterValue));
  //       localStorage.setItem("counter", newCounterValue);
  //     }, 100);

  //     return () => clearInterval(intervalId);
  //   }
  // }, [isMining]);

  useEffect(() => {
    const storedTime = localStorage.getItem("miningStartTime");
    const storedUserId = localStorage.getItem("miningUserId");

    if (!storedTime || storedUserId !== currentUserId) {
      setIsRotating(false);
      setIsMining(false);
      localStorage.removeItem("miningStartTime");
      localStorage.removeItem("miningUserId");
      return;
    }

    const startTime = new Date(storedTime);
    const now = new Date();
    const elapsedMilliseconds = now - startTime;
    const elapsedSeconds = elapsedMilliseconds / 1000;

    if (elapsedSeconds < 24 * 60 * 60) {
      setIsRotating(true);
      setIsMining(true);
      setIsClaimable(false);
      setTimeout(() => {
        setIsRotating(false);
        setIsMining(false);
        setIsClaimable(true);
        localStorage.removeItem("miningStartTime");
        localStorage.removeItem("miningUserId");
      }, 24 * 60 * 60 * 1000 - elapsedMilliseconds);

      const intervalId = setInterval(() => {
        const now = new Date();
        const elapsedMilliseconds = now - startTime;
        const elapsedSeconds = elapsedMilliseconds / 1000;

        const skaiPerSecond = 0.001;
        const newCounterValue = (elapsedSeconds * skaiPerSecond).toFixed(4);

        setCounter(parseFloat(newCounterValue));
        localStorage.setItem("counter", newCounterValue);
      }, 100);

      return () => clearInterval(intervalId);
    }
  }, [currentUserId, isMining]);

  const handleVerifyClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTouchStart = (e) => {
    setIsDragging(true);
    setStartY(e.touches[0].clientY);
    setDragDistance(0);
  };

  const handleTouchMove = (e) => {
    if (!isDragging) return;

    const currentY = e.touches[0].clientY;
    const distanceDragged = currentY - startY;

    setDragDistance(distanceDragged);

    e.preventDefault();
  };

  const handleTouchEnd = () => {
    if (isDragging) {
      if (dragDistance > 50) {
        toggleDrawer(false)();
      }
      setIsDragging(false);
      setDragDistance(0);
    }
  };
  const toggleDrawer = (open) => (event) => {
    if (
      event &&
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setIsDrawerOpen(open);
  };
  const handleSelect = (active) => {
    if (active) {
      setSelectedImage(active);
    }
  };
  const handlepay = () => {
    toggleDrawer(false)();
    setForpay(selectedImage);
  };

  const [isRead, setIsread] = useState(false);
  useEffect(() => {
    async function getRewardData() {
      // setIsloading(true);
      const notificationData = await get_notification();
      console.log(notificationData?.notifications);

      const Unread = notificationData?.notifications?.some(
        (notification) => notification.isRead === false
      );
      console.log("Unread");
      console.log(Unread);
      if (Unread) {
        setIsread(true);
      }
      setIsloading(false);
    }
    getRewardData();
  }, []);

  useEffect(() => {
    const getuserdata = async () => {
      // setIsloading(true);
      const user_Data = await get_home_userdetails();
      console.log("-----");
      console.log(user_Data);
      setUserdata(user_Data);
      setIsloading(false);
    };
    getuserdata();
  }, []);

  const handleCounterChange = (index, action) => {
    const newCounters = [...counters];
    if (action === "increment") {
      newCounters[index] += 1;
    } else if (action === "decrement" && newCounters[index] > 0) {
      newCounters[index] -= 1;
    }
    setCounters(newCounters);
  };

  const navigate = useNavigate();
  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="Home-main1">
          <div className="Home-main">
            <div className="Home-top">
              <div
                className="Home-top1"
                onClick={() => navigate("/ProfileMain")}
              >
                <img
                  src={
                    userdata?.user?.profilePicture
                      ? userdata.user.profilePicture
                      : "/Images/Home/top-prof.svg"
                  }
                  alt="prof"
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "500px",
                    border: "1px solid #323232",
                  }}
                />
              </div>
              <div className="Home-top2">
                <div className="Competitions_top_inner">
                  <div className="Competitions_top_inner_1">
                    <img src="/Images/Competitions/fire.svg" alt="🔥.." />
                    <p className="Competitions_top_inner_1_text">
                      {userdata?.user?.streak}
                    </p>
                  </div>

                  <div className="Competitions_top_inner_1">
                    <img src="/Images/Competitions/m.svg" alt=".." />
                    <p className="Competitions_top_inner_1_text">
                      {userdata?.user?.xMag}
                    </p>
                  </div>
                  <img
                    src={
                      isRead
                        ? "/Images/Competitions/notify.svg"
                        : "/Images/Home/unreadnotify.svg"
                    }
                    alt=".."
                    onClick={() => navigate("/notifications")}
                  />
                </div>
              </div>
            </div>

            <div className="Home-top-div1">
              <div className="Home-top1-1">
                <div className="Home-top1-1-inner">
                  <p className="Home-top1-1-inner-text">xMAG</p>
                  <h1 className="Home-top1-1-inner-text1">
                    {userdata?.user?.xMag}
                  </h1>
                </div>
                <button className="Home-top1-1-button">{t("Redeem")}</button>
              </div>
              <div className="Home-top1-2">
                <div className="Home-top1-2-inner">
                  <div
                    className="Home-top1-2-inner1"
                    onClick={() => {
                      navigate("/RankProgression");
                    }}
                  >
                    <img src="/Images/Home/rank.svg" alt="" />
                    <p className="Home-top1-2-inner1-text">
                      {t(userdata?.user?.CurrentRank)} level {" "}
                      {userdata?.user?.CurrentLevel}
                    </p>
                  </div>
                  <p className="Home-top1-2-inner-text">
                    <span style={{ color: "#ffffff" }}>
                      {userdata?.user?.xMag}
                    </span>
                    /{userdata?.user?.NextRank?.xpEarned} xMAG
                  </p>
                </div>
                <Box
                  sx={{
                    width: "100%",
                    borderRadius: "50px",
                    overflow: "hidden",
                  }}
                >
                  <LinearProgress
                    variant="determinate"
                    value={
                      (userdata?.user?.xMag /
                        userdata?.user?.NextRank?.xpEarned) *
                      100
                    }
                    sx={{
                      height: "10px",
                      borderRadius: "50px",
                      "& .MuiLinearProgress-bar": {
                        backgroundColor: "#BCBFCC",
                        borderRadius: "100px",
                      },
                      backgroundColor: "#FFFFFF1A",
                    }}
                  />
                </Box>
                {/* <p className="Home-top1-2__text">
                  XPs are earned for completing quests
                </p> */}
              </div>
            </div>

            <div className="Home-top-div2">
              <div className="Home-top-div2-inner">
                <div
                  className="Home-top-div2-inner-1"
                  // onClick={() => {
                  //   navigate("/Wealth");
                  // }}

                  onClick={() => navigate("/Rewards")}
                >
                  <img
                    src="/Images/Home/home__1.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Quests")}</p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__2.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">
                    {t("Trading Bot")}
                  </p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__1.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Launchpad")}</p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__3.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Airdrops")}</p>
                </div>
              </div>
              <div className="Home-top-div2-inner">
                <div
                  className="Home-top-div2-inner-1"
                  onClick={() => navigate("/Referral")}
                  // onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__4.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Raffles")}</p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__3.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">
                    {t("Mini Games")}
                  </p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__1.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Academy")}</p>
                </div>
                <div
                  className="Home-top-div2-inner-1"
                  onClick={handleVerifyClick}
                >
                  <img
                    src="/Images/Home/home__3.png"
                    style={{
                      width: "54px",
                      height: "50px",
                    }}
                    alt=""
                  />
                  <p className="Home-top-div2-inner-1-text">{t("Store")}</p>
                </div>
              </div>
            </div>

            <div className="Home___middle_d">
              <div className="Home___middle_d_1">
                <img
                  src={forpay ? forpay : "/Images/Home/blck.png"}
                  alt=""
                  style={{ width: "120px", height: "120px" }}
                  // transform: `rotate(${rotation}deg)`,
                  // transition: 'transform 0.1s linear',
                  className={isRotating ? "rotating-fan" : ""}
                />
              </div>
              <div className="Home___middle_d_2">
                <div className="Home___middle_d_2_top">
                  <div>
                    <div className="Home___middle_d_2_inner">
                      <img src="/Images/Home/info.svg" alt="" />
                      <p className="Home___middle_d_2_inner_text">520 Gh/s</p>
                    </div>
                    <p className="Home___middle_d_2_text1"> {counter} xMAG</p>
                  </div>
                  <div>
                    <p className="Home___middle_d_2_top_text">
                      {t("Active Boosters:")}{" "}
                      <span style={{ fontWeight: "600" }}>22</span>
                    </p>
                    <div className="Home___middle_d_2_top_inner2">
                      <p className="Home___middle_d_2_top_inner2_text1">
                        {t("Estimated xMAG")}
                      </p>
                      <img src="/Images/Home/Line1.svg" alt="" />
                      <p className="Home___middle_d_2_top_inner2_text1">
                        100,000,221
                      </p>
                    </div>
                  </div>
                  <ul style={{ paddingLeft: "11px" }}>
                    <li className="Home___middle_d_2_top_text_3">
                      {t("Activate daily mining")}
                    </li>
                    <li className="Home___middle_d_2_top_text_3">
                      {t("Boost to increase hashrate")}
                    </li>
                  </ul>
                </div>
                <div className="Home_middle_buttonDiv">
                  <button
                    className="Home_middle_buttonDiv_btn1"
                    onClick={isClaimable ? handleClaim : startRotation} // Conditional click handler
                    style={{ opacity: isRotating ? 0.6 : 1 }}
                    disabled={isRotating} // Disable the button only when rotating
                  >
                    <img src="/Images/Home/petrol.svg" alt="Petrol Icon" />
                    <p className="Home_middle_buttonDiv_btn1_text">
                      {isClaimable ? t("Claimable") : t("Mine")}
                    </p>
                  </button>
                  <button
                    className="Home_middle_buttonDiv_btn2"
                    onClick={toggleDrawer(true)}
                  >
                    <img src="/Images/Home/energy.svg" alt="" />
                    <p className="Home_middle_buttonDiv_btn2_text">
                      {" "}
                      {t("Boost")}
                    </p>
                  </button>
                </div>
                <Drawer
                  anchor="bottom"
                  open={isDrawerOpen}
                  onClose={toggleDrawer(false)}
                  ModalProps={{
                    sx: {
                      backdropFilter: "blur(6px)",
                    },
                  }}
                  PaperProps={{
                    sx: {
                      maxHeight: "70vh",
                    },
                  }}
                  sx={{
                    "& .MuiDrawer-paper": {
                      backgroundColor: "#ffffff",
                      maxHeight: "60vh",
                    },
                  }}
                  onTouchStart={handleTouchStart}
                  onTouchMove={handleTouchMove}
                  onTouchEnd={handleTouchEnd}
                  ref={drawerRef}
                >
                  <div className="drawer_top">
                    <img
                      src="/Images/Home/hr.svg"
                      alt="__"
                      onTouchStart={handleTouchStart}
                      style={{ touchAction: "none" }}
                    />
                  </div>
                  <p className="drawer_texttop">{t("Mining Boosters")}</p>
                  <Box className="drawer_card_____top">
                    {drawerdata?.map((card, index) => (
                      <Card key={index} className="drawer_cards______card">
                        <Box
                          className="cart____topmain"
                          sx={{
                            background: `${card?.background}`,
                            border:
                              selectedImage === card.image
                                ? "1.5px solid  black"
                                : "",
                          }}
                          onClick={() => handleSelect(card?.image)}
                        >
                          <img
                            key={index}
                            src={card?.image}
                            alt=""
                            style={{ width: "59.36px", height: "57.64px" }}
                            // onClick={() => handleSelect(card.image)}
                          />
                          <img src="/Images/Home/shad.png" alt="" />
                          <div
                            style={{
                              display: "flex",
                              gap: "1px",
                              alignItems: "center",
                            }}
                          >
                            <p className="cart____topmain_text1">
                              {card?.text1}
                            </p>
                            <p className="cart____topmain_text2">
                              {card?.text2}
                              <br />
                              {card?.text3}
                            </p>
                          </div>
                        </Box>

                        <Box className="drawer_content____inner">
                          <Typography className="drawer_content____inner_text1">
                            {card.value}
                          </Typography>
                          <div className="drawer_content____inner_1">
                            <div className="drawer_content____inner_1___inner">
                              <img src="/Images/Home/logooo.svg" alt="" />
                              <p className="drawer_content____inner_text2">
                                {card?.count}
                              </p>
                            </div>
                            <div className="drawer____inner">
                              <img
                                src="/Images/Home/minus.svg"
                                alt=""
                                onClick={() =>
                                  handleCounterChange(index, "decrement")
                                }
                                style={{ cursor: "pointer" }}
                              />
                              <p className="inner________text">
                                {counters[index]}
                              </p>
                              <img
                                src="/Images/Home/plus.svg"
                                alt=""
                                onClick={() =>
                                  handleCounterChange(index, "increment")
                                }
                                style={{ cursor: "pointer" }}
                              />
                            </div>
                          </div>
                        </Box>
                      </Card>
                    ))}
                  </Box>
                  <div className="bottom_button_div">
                    <button className="drawerbottom__btn1" onClick={handlepay}>
                      Pay with ⭐
                    </button>
                    <button className="drawerbottom__btn2" onClick={handlepay}>
                      Pay with xMAG
                    </button>
                  </div>
                </Drawer>
              </div>
            </div>
            <ComingSoon show={showModal} handleClose={handleCloseModal} />
          </div>
          <div className="home_bottom_main">
            <div className="home_bottom_top">
              <div className="home_bottom_top1">
                <button className="home_bottom_top1_btn">Lorem Ispum</button>
                <img src="/Images/Home/info1.svg" alt="" />
              </div>
              <p className="home_bottom_top_text1">Lorem Ispum</p>
              <p className="home_bottom_top_text2">
                Compete against other players and forge your name on the
                leaderboard.
              </p>
            </div>
            <div className="home_bottom_top___d2">
              <div className="home_bottom_top1">
                <button className="home_bottom_top1_btn">Lorem Ispum</button>
                <img src="/Images/Home/info1.svg" alt="" />
              </div>
              <p className="home_bottom_top_text1">Lorem Ispum</p>
              <p className="home_bottom_top_text2">
                Compete against other players and forge your name on the
                leaderboard.
              </p>
            </div>
          </div>
          <Navbar />
          <AlertOk
            show={showSaveChanges}
            handleClose={() => setShowSaveChanges(false)}
            message={saveChangesMessage}
          />
        </div>
      )}
    </>
  );
};

export default Home;
