import React, { useState } from "react";
import "./Updates.css";
import Navbar from "../../Components/Navbar/Navbar";
const Updates = () => {
  const [act, setact] = useState("A");

  const handleclick = (active) => 
    {
      setact(active);
  }


  
  return (
    <div className="updates">
      <div className="updates_Main_Div1">
        <img src="/Images/update/backicon.svg" alt="..." />
        <p className="updates_Main_Div1_ptag">Updates</p>
        <img src="/Images/BackIcon2.svg" alt="..." />
      </div>

      <div className="updates_Main_Div2">
        <button className="updates_Main_Div2_button1" style={{background: act=== "A" ? "linear-gradient(180deg, #000000 0%, #323232 100%)":"", color:act==="A" ?"#ffffff":"#1E1E24"}} type="submit" onClick={() => handleclick("A")}>
          Roadmap
        </button>

        <button className="updates_Main_Div2_button2" type="submit" style={{background: act=== "B" ? "linear-gradient(180deg, #000000 0%, #323232 100%)":"",color:act==="B" ?"#ffffff":"#1E1E24"}} onClick={() => handleclick("B")}>
          Updates
        </button>
      </div>

      <div className="updates_Main_Div3">
        <p className="updates_Main_Div3_ptag"> Updates</p>


        <div>
          <div className="updates_Main_Div3_div1">
            <div className="updates_Main_Div3_div1_1">
              <p className="updates_Main_Div3_div1_1_p">Lorem Ispum</p>
              <img src="/Images/update/icon.svg" alt="...." />
            </div>

            <img src="/Images/update/line.svg" alt="..." />

            <p className="updates_Main_Div3_div1_p">
              Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus.{" "}
            </p>

            <div className="updates_Main_Div3_div1_2">
              <p className="updates_Main_Div3_div1_2_p">Oct 23, 2024</p>
              <img src="/Images/update/dot.svg" alt="" />
              <p className="updates_Main_Div3_div1_2_p">12:09:35</p>

            </div>


          </div>
        </div>


        <div>
          <div className="updates_Main_Div3_div1">
            <div className="updates_Main_Div3_div1_1">
              <p className="updates_Main_Div3_div1_1_p">Lorem Ispum</p>
              <img src="/Images/update/icon.svg" alt="...." />
            </div>

            <img src="/Images/update/line.svg" alt="..." />

            <p className="updates_Main_Div3_div1_p">
              Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus.{" "}
            </p>

            <div className="updates_Main_Div3_div1_2">
              <p className="updates_Main_Div3_div1_2_p">Oct 23, 2024</p>
              <img src="/Images/update/dot.svg" alt="" />
              <p className="updates_Main_Div3_div1_2_p">12:09:35</p>

            </div>


          </div>
        </div>


        <div>
          <div className="updates_Main_Div3_div1">
            <div className="updates_Main_Div3_div1_1">
              <p className="updates_Main_Div3_div1_1_p">Lorem Ispum</p>
              <img src="/Images/update/icon.svg" alt="...." />
            </div>

            <img src="/Images/update/line.svg" alt="..." />

            <p className="updates_Main_Div3_div1_p">
              Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
              turpis molestie, dictum est a, mattis tellus.{" "}
            </p>

            <div className="updates_Main_Div3_div1_2">
              <p className="updates_Main_Div3_div1_2_p">Oct 23, 2024</p>
              <img src="/Images/update/dot.svg" alt="" />
              <p className="updates_Main_Div3_div1_2_p">12:09:35</p>

            </div>


          </div>
        </div>



      </div>
      <Navbar/>
    </div>
  );
};

export default Updates;
