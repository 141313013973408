import React from "react";
import "./Certificate.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
const Certificate = () => {
  const navigate = useNavigate();
  return (
    <div className="cirtificate-main">
      <div className="certificate-arrow">
        <img src="/Images/arrowref.svg" alt="" onClick={() => navigate(-1)}/>
        <p className="certificate-arrowtext">Certificate</p>
        <img src="/Images/arrowref2.svg" alt=""/>
      </div>
      <div className="certificate-bottom-part">
        <div className="magnus-logo-img">
          <img src="/Images/magnuslogo.svg" alt=""/>
        </div>
        <div className="certificate-bottom-part-text">
          <p className="COMPLETION">CERTIFICATE OF COMPLETION</p>
          <h2>Crypto Mastery</h2>
          <p className="instructors">
            Instructors <span>Sarah Adams</span>
          </p>
        </div>
        <div className="divide-line">
          <img src="/Images/devideline.svg" alt=""/>
        </div>
        <div className="voyager-main">
          <div className="voyager">
            <img src="/Images/voyager.svg" alt=""/>
            <h2 className="voyager-tet">VOYAGER</h2>
            <p>Morem ipsum dolor sit amet, consectetur adipiscing elit. </p>
          </div>
        </div>
      </div>
      <div className="button-main">
        <button className="button-first">Share</button>
        <button className="button-second">
          <p className="downlaod">Download</p>
        </button>
      </div>
      <Navbar/>
    </div>
  );
};

export default Certificate;
