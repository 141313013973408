import { sendTelegramMessage } from "../api/sendTelegramMessage";

export const handleInvite = async (chatId, userName,refferalCode) => {
    const referralLink = `https://t.me/magnus_dev123bot?start=r_${refferalCode}`;
    const message = `Invite your friends and earn rewards for each one who joins!🎁\n\nShare your referral link:: \`${referralLink}\`\n\nLet’s build a stronger community together! `;
    const forwardMessage = `🏆+1.5k  tokens as a first-time bonus\n💎+30k  Coins if you have Telegram Premium`;
    const telegramShareUrl = `https://t.me/share/url?url=${encodeURIComponent(referralLink)}&text=${encodeURIComponent(forwardMessage)}`;
    const appUrl = `https://magnus-miniapp.vercel.app/?userId=${chatId}&userName=${encodeURIComponent(userName)}`;
    const inlineKeyboard = [
        [
            { text: "Invite a friend", url: telegramShareUrl }],
        [
            { text: "Back to Magnus", web_app: { url: appUrl } }
        ],
    ];
    await sendTelegramMessage(chatId, message, inlineKeyboard);
}