import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./Notification.css";
import { get_notification } from "../../api/get_notification";
import { read_notification } from "../../api/read_notification";
import { useTranslation } from 'react-i18next';
const Notification = () => {
  const navigate = useNavigate();
  const [notifications, setNotifications] = useState([]);
  const { t } = useTranslation();

  const readNotification = async (id, index) => {
    try {
      const Read = await read_notification(id);
      if (Read?.Notification?.isRead) {
        
        const updatedNotifications = [...notifications];
        updatedNotifications[index] = {
          ...updatedNotifications[index],
          isRead: true,
        };
        setNotifications(updatedNotifications);
      }
    } catch (error) {
      console.error("Error reading notification:", error);
    }
  };

  useEffect(() => {
    async function getRewardData() {
      const notificationData = await get_notification();
      setNotifications(notificationData?.notifications);
    }
    getRewardData();
  }, []);

  const formatTime = (dateString) => {
    const date = new Date(dateString);
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12;
    return `${hours}:${minutes} ${ampm}`;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toISOString().split('T')[0];
  };

  const isToday = (dateString) => {
    const today = new Date();
    const date = new Date(dateString);
    return (
      today.getDate() === date.getDate() &&
      today.getMonth() === date.getMonth() &&
      today.getFullYear() === date.getFullYear()
    );
  };

  const notificationsToday = notifications?.filter((notification) =>
    isToday(notification?.createdAt)
  );

  const notificationsThisWeek = notifications?.filter(
    (notification) => !isToday(notification?.createdAt)
  );

  const renderNotifications = (notifications, isToday = true) => {
    return notifications?.map((notification, index) => (
      <div
        className="Notify_top1_inner_1"
        key={index}
        onClick={() => readNotification(notification._id, index)}
       
      >
        <img src= {notification?.imgSrc ?  notification?.imgSrc : "/Images/ProfileActor.png"} alt="img" className="notification__img" />
        <div className="Notify_top1_inner_1_inner">
          <div className="Notify_top1_inner_1_inner_inner">
            <p className="Notify_top1_inner_1_inner_inner_text">
              {notification?.title}
            </p>
            <p className="Notify_top1_inner_1_inner_inner_text2">
              {isToday
                ? formatTime(notification?.createdAt)
                : formatDate(notification?.createdAt)}

            </p>
          </div>
          <p className="Notify_top1_inner_1_inner_inner_text2">
            {notification?.content}
          </p>
          {notification?.isRead ?"":
          <p className="markasread">{t('Mark as read')}</p>
            }
        </div>
      </div>
    ));
  };

  return (
    <div className="Notify_main">
      <div className="Notify-arrow">
        <img src="/Images/arrowref.svg" alt="" onClick={() => navigate(-1)} />
        <p className="Notify-arrowtext">{t('Notifications')}</p>
        <p></p>
      </div>

      <div className="notify___top">
        <div className="Notify_top1">
          <p className="Notify_top1_text">{t('Today')}</p>
          <div className="Notify_top1_inner">
            {notificationsToday?.length > 0
              ? renderNotifications(notificationsToday, true)
              : <p style={{paddingLeft:"12px"}} >{t('No notifications today')}</p>}
          </div>
        </div>

        <div className="Notify_top1">
          <p className="Notify_top1_text">{t('This Week')}</p>
          <div className="Notify_top1_inner">
            {notificationsThisWeek?.length > 0
              ? renderNotifications(notificationsThisWeek, false)
              : <p style={{paddingLeft:"12px"}}>{t('No notifications this week')}</p>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Notification;
