import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Leaderboard.css";
import { useTranslation } from 'react-i18next';
import Ranking from "./Ranking";
import Ranking2 from "./Ranking2";
import Ranking3 from "./Ranking3";
import Ranking4 from "./Ranking4";
import { get_alltime_invites } from "../../api/get_alltime_invites";
import { getRankingTasks } from "../../api/getRankingTasks";
import { get_home_userdetails } from "../../api/get_home_userdetails";
import Navbar from "../../Components/Navbar/Navbar";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { get_notification } from "../../api/get_notification";
const Leaderboard = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Invites");

  const [ranked1_userdata, Set1stranked_userdata] = useState();
  const [ranked2_userdata, Set2ndranked_userdata] = useState();
  const [ranked3_userdata, Set3rdranked_userdata] = useState();
  const [loader, setLoader] = useState(true);
  const [userdata, setUserdata] = useState();
  const [isRead, setIsread] = useState(false);

  useEffect(() => {
    const handleAllInvites = async () => {
      // setLoader(true);
      const invitesData = await get_alltime_invites();
      console.log("invitesData_______");
      console.log(invitesData);
      Set1stranked_userdata(invitesData?.rankedUsers[0]);
      Set2ndranked_userdata(invitesData?.rankedUsers[1]);
      Set3rdranked_userdata(invitesData?.rankedUsers[2]);
      setLoader(false);
    };
    handleAllInvites();
  }, []);
  useEffect(() => {
    const getuserdata = async () => {
      // setLoader(true);
      const user_Data = await get_home_userdetails();
      console.log("-----");
      console.log(user_Data);
      setUserdata(user_Data);
      setLoader(false);
    };
    getuserdata();
  }, []);
  useEffect(() => {
    async function getRewardData() {
      // setLoader(true);
      const notificationData = await get_notification();
      console.log(notificationData?.notifications);

      const Unread = notificationData?.notifications?.some(
        (notification) => notification.isRead === false
      );
      console.log("Unread");
      console.log(Unread);
      if (Unread) {
        setIsread(true);
      }
      setLoader(false);
    }
    getRewardData();
  }, []);
  const { t } = useTranslation();
  return (
    <>
      {loader ? (
        // <div className="loading-container-ranking">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <>
          <div className="bodycontainer">
            <div className="Home-top" style={{padding:"0px"}}>
              <div
                className="Home-top1"
                onClick={() => navigate("/ProfileMain")}
              >
                <img
                  src={
                    userdata?.user?.profilePicture
                      ? userdata.user.profilePicture
                      : "/Images/Home/top-prof.svg"
                  }
                  alt="prof"
                  style={{
                    width: "36px",
                    height: "36px",
                    borderRadius: "500px",
                    border: "1px solid #323232",
                  }}
                />
              </div>
              <div className="Home-top2">
                <div className="Competitions_top_inner">
                  <div className="Competitions_top_inner_1">
                    <img src="/Images/Competitions/fire.svg" alt="🔥.." />
                    <p className="Competitions_top_inner_1_text">
                      {userdata?.user?.streak}
                    </p>
                  </div>

                  <div className="Competitions_top_inner_1">
                    <img src="/Images/Competitions/m.svg" alt=".." />
                    <p className="Competitions_top_inner_1_text">
                      {userdata?.user?.xMag}
                    </p>
                  </div>
                  <img
                    src={
                      isRead
                        ? "/Images/Competitions/notify.svg"
                        : "/Images/Home/unreadnotify.svg"
                    }
                    alt=".."
                    onClick={() => navigate("/notifications")}
                  />
                </div>
              </div>
            </div>
            <div className="overall-wraper">
              <div className="metaverse">
                <div className="vectors-wrape">
                  <img
                    src="/Images/Leaderboardimages/Ranking/buildP.svg"
                    alt="build"
                  />
                  <img
                    src="/Images/Leaderboardimages/Ranking/metaverse.svg"
                    alt="metaverse"
                  />
                </div>
              </div>

              <div className="text-buttons">
                <p className="leader-text">{t('Leaderboards')}</p>
                <div className="buttons-wrape">
                  <button
                    className={
                      activeButton === "Invites"
                        ? "primary-btn"
                        : "secondary-btn"
                    }
                    onClick={() => setActiveButton("Invites")}
                  >
                    {t('Invites')}
                  </button>

                  <button
                    className={
                      activeButton === "Tasks" ? "primary-btn" : "secondary-btn"
                    }
                    onClick={() => setActiveButton("Tasks")}
                  >
                    {t('Tasks')}
                  </button>

                  <button
                    className={
                      activeButton === "xMAG" ? "primary-btn" : "secondary-btn"
                    }
                    onClick={() => setActiveButton("xMAG")}
                  >
                    xMAG
                  </button>

                  <button
                    className={
                      activeButton === "Volume"
                        ? "primary-btn"
                        : "secondary-btn"
                    }
                    onClick={() => setActiveButton("Volume")}
                  >
                    {t('Volume')}
                  </button>
                </div>
              </div>
            </div>
            {activeButton === "Invites" && (
              <>
                <Ranking />
              </>
            )}
            {activeButton === "Tasks" && (
              <>
                <Ranking2 />
              </>
            )}
            {activeButton === "xMAG" && (
              <>
                <Ranking3 />
              </>
            )}
            {activeButton === "Volume" && (
              <>
                <div className="images-uper-div">
                  <div className="image-text-wraper">
                    <img
                      src="/Images/Leaderboardimages/Ranking/amanda.svg"
                      alt="avator"
                    />
                    <div className="below-image">
                      <p className="name-id">Amanda</p>
                      <p className="number">3,457</p>
                    </div>
                  </div>

                  <div className="image-text-wraper">
                    <img
                      src="/Images/Leaderboardimages/Ranking/cisse.svg"
                      alt="avator"
                    />
                    <div className="below-image">
                      <p className="name-id">Cisse</p>
                      <p className="number">5,241</p>
                    </div>
                  </div>

                  <div className="image-text-wraper">
                    <img
                      src="/Images/Leaderboardimages/Ranking/jong.svg"
                      alt="avator"
                    />
                    <div className="below-image">
                      <p className="name-id">Jong Un</p>
                      <p className="number">2,541</p>
                    </div>
                  </div>
                </div>
                <Ranking4 />
              </>
            )}
          </div>
        </>
      )}
      <Navbar />
    </>
  );
};

export default Leaderboard;
