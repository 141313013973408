import React, { useState } from "react";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Navbar from "../../Components/Navbar/Navbar";
import { editprofile } from "../../api/editprofile";
import { getEditProfile } from "../../api/getEditProfile";
import { emailVerification } from "../../api/emailVerification";
import "./EditProfile.css";
import { useTranslation } from 'react-i18next';
import SelectProfileImage from "../../Components/Modal/Edit Profile Drawer/SelectProfileImage";
import SelectProfileImageSec from "../../Components/Modal/Edit Profile Drawer/SelectProfileImageSec";
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import AlertNot from "../../Components/Modal/Alert/AlertNot";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const EditProfile = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();   
  const [openSelectProfileDrawer, setOpenSelectProfileDrawer] = useState(false);

  const [coverPicture, setCoverPicture] = useState();
  const [profilePicture, setProfilePicture] = useState();
  const [displayName, setDisplayName] = useState();
  const [username, setUsername] = useState();
  const [email, setEmail] = useState();
  const [bio, setBio] = useState();
  const [telegram, setTelegram] = useState();
  const [XLink, setXLink] = useState();
  const [discordLink, setDiscordLink] = useState();
  const [instagramLink, setInstagramLink] = useState();
  const [linkedinLink, setLinkedinLink] = useState();
  const [youtubeLink, setYoutubeLink] = useState();
  const [facebookLink, setFacebookLink] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [profileUpdate, setProfileUpdate] = useState();

  const openBigSelectProfile = () => {
    setOpenSelectProfileDrawer(true);
  };
  const [openSecondSelectProfileDrawer, setOpenSecondSelectProfileDrawer] =
    useState(false);
  const openSmallSelectProfile = () => {
    setOpenSecondSelectProfileDrawer(true);
  };

  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [NotsaveChangesMessage, setNotSaveChangesMessage] = useState("");
  const [showNotSaveChanges, setShowNotSaveChanges] = useState(false);
  const [isVerified, setIsVerified] = useState(false);

  const handleSubmit = async () => {
    // Check for missing fields
    let missingFields = [];
    if (!displayName) missingFields.push("Display Name");
    if (!profilePicture) missingFields.push("Profile Picture");
    if (!coverPicture) missingFields.push("Cover Picture");

    // If any required field is missing, show the message and stop submission
    if (missingFields?.length > 0) {
      const message = `Please enter ${missingFields.join(", ")}.`;
      setNotSaveChangesMessage(message);
      setShowNotSaveChanges(true);
      return;
    } else {
      const token = sessionStorage.getItem("token");
      setIsloading(true);
      try {
        setSaveChangesMessage("");
        const data = await editprofile(
          displayName,
          bio,
          coverPicture,
          profilePicture,
          XLink,
          email,
          discordLink,
          instagramLink,
          linkedinLink,
          facebookLink,
          youtubeLink,
          token
        );
        console.log(token, "this is token ");
        if (data) {
          setProfileUpdate(data);
          setSaveChangesMessage("Profile Updated Successfully");
          setShowSaveChanges(true);
          console.log("Registration successful", data);
        } else {
          console.log("No data returned from registration");
          setSaveChangesMessage("");
        }
      } catch (error) {
        console.error("Error during registration:", error);
      }
      setIsloading(false);
    }
  };

  const handleVerify = async () => {
    const token = sessionStorage.getItem("token");
    setIsloading(true);
    if (!email) {
      console.error("Email is not set.");
      return;
    }
    try {
      const data = await emailVerification(token, email);
      if (data) {
        console.log("Email verification successful", data);
        navigate(`/Verification/${email}`);
      } else {
        console.log("No data returned from email verification");
      }
    } catch (error) {
      console.error("Error during email verification:", error);
    }
    setIsloading(false);
  };
  useEffect(() => {
    async function getProfile() {
      const token = sessionStorage.getItem("token");
      setIsloading(true);
      const data = await getEditProfile(token);
      console.log(data, "this is data of profile");
      // const userName = sessionStorage.getItem("userName");
      setUsername(data?.profile?.telegramUsername);
      setBio(data?.profile?.bio);
      setDisplayName(data?.profile?.displayName);
      setEmail(data?.profile?.email);
      setProfilePicture(data?.profile?.profilePicture);
      setCoverPicture(data?.profile?.coverPicture);
      setTelegram(`https://t.me/${data?.profile?.telegramUsername}`);
      if (data?.profile?.emailVerified) {
        setIsVerified(true);
      }
      setXLink(data?.profile?.XLink);
      setDiscordLink(data?.profile?.discordLink);
      setInstagramLink(data?.profile?.instagramLink);
      setLinkedinLink(data?.profile?.linkedinLink);
      setYoutubeLink(data?.profile?.youtubeLink);
      setFacebookLink(data?.profile?.facebookLink);
      setIsloading(false);
    }
    getProfile();
  }, [profileUpdate]);

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        <ScreenLoader />
      ) : (
        // </div>
        <div className="Edit-Profile-Main">
          <div className="Edit-Profile-Topbar">
            <img
              src="/Images/arrowref.svg"
              alt=""
              onClick={() => navigate("/ProfileMain")}
            />
            <p className="quiz-arrowtext">{t('Edit Profile')}</p>
            <img src="/Images/arrowref2.svg" alt="" />
          </div>
          <div
            className="Edit-Profile-Pic-Div2"
            style={{
              backgroundImage: `url('${
                coverPicture
                  ? coverPicture
                  : "/Images/Social Profile/Background-Edit.png"
              }')`,
            }}
          >
            <button
              className="edit-btn-profile2"
              onClick={openBigSelectProfile}
            >
              <img src="/Images/Social Profile/EditButton.svg" alt="" />
              <p className="edit-button-text2">{t('Edit')}</p>
            </button>
          </div>

          <div className="ProfilePicMainDiv-for-edit">
            <div className="ImagePostionDiv-for-edit">
              <div>
                <img
                  src={
                    profilePicture
                      ? profilePicture
                      : "/Images/Social Profile/Actor.png"
                  }
                  alt="ProfileActor"
                  className="Profile-Image-for-edit"
                />
              </div>

              <div
                className="InnerStarPosition-for-edit"
                onClick={openSmallSelectProfile}
              >
                <img src="/Images/Social Profile/ProfilePicEdit.svg" alt="" />
              </div>
            </div>
            <div className="name-and-atratename-div">
              <p className="name-edit-page">
                {/* {displayName ? `${displayName.slice(0, 6)}` : ""} */}
                {displayName ? displayName.split(" ")[0] : ""}
              </p>
              <p className="atratename-text">@{username}</p>
            </div>
          </div>

          <div className="edit-profile-textbox">
            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Display Name')}</p>
              <input
                className="input-style-edit-profile"
                value={displayName}
                onChange={(e) => setDisplayName(e.target.value)}
                maxLength={20}
                required
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Username')}</p>
              <input
                className="input-style-edit-profile"
                value={`@${username}`}
                onChange={(e) => setUsername(e.target.value)}
                readOnly
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Email Address')}</p>
              <div className="email-input-and-text-div1">
                <input
                  className="input-style-edit-profile-for-email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                {isVerified ? (
                  <p className="verified-in-text-box">{t('Verified')}</p>
                ) : (
                  <button
                    style={{
                      background: !email
                        ? "grey"
                        : "linear-gradient(180deg, #000000 0%, #323232 100%)",
                    }}
                    className="verify-button-editprofile"
                    onClick={handleVerify}
                  >
                    {t('Verify')}
                  </button>
                )}
              </div>
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Bio')}</p>
              <textarea
                className="textarea-style-edit-profile"
                value={bio}
                onChange={(e) => setBio(e.target.value)}
                maxLength={100}
                required
              />
            </div>

            <img
              src="/Images/Social Profile/line-gradient.svg"
              alt=""
              className="line-margin-edit-profile"
            />

            <p className="Social-links-text">{t('SOCIAL LINKS')}</p>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Telegram')}</p>
              <div className="email-input-and-text-div">
                <input
                  className="input-style-edit-profile-for-email"
                  value={telegram}
                  onChange={(e) => setTelegram(e.target.value)}
                  readOnly
                />
                <p className="verified-in-text-box">{t('Verified')}</p>
              </div>
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('X (formerly Twitter)')}</p>
              <input
                className="input-style-edit-profile"
                value={XLink ? `${XLink}` : "https://x.com/"}
                onChange={(e) => setXLink(e.target.value)}
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Discord')}</p>
              <input
                className="input-style-edit-profile"
                value={discordLink ? `${discordLink}` : "https://discord.com/"}
                onChange={(e) => setDiscordLink(e.target.value)}
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Instagram')}</p>
              <input
                className="input-style-edit-profile"
                value={
                  instagramLink
                    ? `${instagramLink}`
                    : "https://www.instagram.com/"
                }
                onChange={(e) => setInstagramLink(e.target.value)}
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('LinkedIn')}</p>
              <input
                className="input-style-edit-profile"
                value={
                  linkedinLink ? `${linkedinLink}` : "https://linkedin.com/"
                }
                onChange={(e) => setLinkedinLink(e.target.value)}
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('Facebook')}</p>
              <input
                className="input-style-edit-profile"
                value={
                  facebookLink ? `${facebookLink}` : "https://facebok.com/"
                }
                onChange={(e) => setFacebookLink(e.target.value)}
              />
            </div>

            <div className="textinput-and-nameofinput-div">
              <p className="display-name-text">{t('YouTube')}</p>
              <input
                className="input-style-edit-profile"
                value={youtubeLink ? `${youtubeLink}` : "https://youtube.com/"}
                onChange={(e) => setYoutubeLink(e.target.value)}
              />
            </div>
          </div>
          <button
            className="save-changes-button-edit-profile"
            onClick={handleSubmit}
          >
            {t('Save Changes')}
          </button>

          <SelectProfileImage
            open={openSelectProfileDrawer}
            onClose={() => setOpenSelectProfileDrawer(false)}
            setSelectedProfileImage={setCoverPicture}
          />

          <SelectProfileImageSec
            open={openSecondSelectProfileDrawer}
            onClose={() => setOpenSecondSelectProfileDrawer(false)}
            setSelectedProfileImage={setProfilePicture}
          />
          <AlertOk
            show={showSaveChanges}
            handleClose={() => setShowSaveChanges(false)}
            message={saveChangesMessage}
          />
          <AlertNot
            show={showNotSaveChanges}
            handleClose={() => setShowNotSaveChanges(false)}
            message={NotsaveChangesMessage}
          />
          <Navbar />
        </div>
      )}
    </>
  );
};

export default EditProfile;
