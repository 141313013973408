import React, { useState, useEffect } from 'react';
import './SelectLanguage.css';
import i18n from 'i18next';
import { useNavigate } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const SelectLanguage = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [selectedLanguage, setSelectedLanguage] = useState(); 
  const [showModal, setShowModal] = useState(false);
  const [tempLanguage, setTempLanguage] = useState(); 

  

 

  const openVoyagerModal = (language) => {
    setTempLanguage(language); 
    setShowModal(true);
  };

  const closeVoyagerModal = () => {
    setShowModal(false);
  };

  const handleConfirmLanguage = () => {
    setSelectedLanguage(tempLanguage);
    i18n.changeLanguage(tempLanguage);
    navigate('/home');
    setShowModal(false);
  };

  const handleLanguageSelect = (language) => {
    openVoyagerModal(language);
  };

  return (
    <div className='SelectLanguage_Container'>
      <div className='Language_Container'>
        <img src='/Images/Backicon.svg' alt='Arrow' className='ArrowIcon' onClick={()=>{navigate(-1)}}/>
        <h3 className='Select_language_Text'>{t('Select Language')}</h3>
        <p></p>
      </div>

      <div className='Dropdown'>
        <div className='Language_Option'>
          <div className='Language_Info'>
            <img src='/Images/LanguageImages/English.svg' alt='English' />
            <span>English</span>
          </div>
          <input
            type="checkbox"
            name='language'
            checked={selectedLanguage === 'en'}
            onChange={() => handleLanguageSelect('en')}
          />
        </div>
        <div className='Language_Option'>
          <div className='Language_Info'>
            <img src='/Images/LanguageImages/PyconFlag.svg' alt='Russian' />
            <span>Русский</span>
          </div>
          <input
            className='jogerre'
            type="checkbox"
            name='language'
            checked={selectedLanguage === 'ru'}
            onChange={() => handleLanguageSelect('ru')}
          />
        </div>
        <div className='Language_Option'>
          <div className='Language_Info'>
            <img src='/Images/LanguageImages/Ukrain.svg' alt='Ukrainian' />
            <span>Українська</span>
          </div>
          <input
            type="checkbox"
            name='language'
            checked={selectedLanguage === 'uk'}
            onChange={() => handleLanguageSelect('uk')}
          />
        </div>
        <div className='Language_Option'>
          <div className='Language_Info'>
            <img src='/Images/LanguageImages/Turkish.svg' alt='Turkish' />
            <span>Türkçe</span>
          </div>
          <input
            type="checkbox"
            name='language'
            checked={selectedLanguage === 'tr'}
            onChange={() => handleLanguageSelect('tr')}
          />
        </div>

        <div className='Language_Option'>
          <div className='Language_Info'>
            <img src='/Images/LanguageImages/china.svg' alt='china' />
            <span>中文</span>
          </div>
          <input
            type="checkbox"
            name='language'
            checked={selectedLanguage === 'zh'}
            onChange={() => handleLanguageSelect('zh')}
          />
        </div>
      </div>

      
      <Modal show={showModal} onHide={closeVoyagerModal} className="voyager-first-modal" centered>
        <Modal.Body className='VerifyModal'>
          <div className='Cross-Icon-Voyager'>
            <p></p>
            <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={closeVoyagerModal} />
          </div>
          <div className="text-center">
            <img src="/Images/LanguageImages/lang.svg" alt="lang" className='ImageTops' />
            <p className='surelangtext'>{t('Are you sure you want to change the language?')}</p>

            <div className='twobutton'>
            <button
                className='btnvoyoger1'
                onClick={closeVoyagerModal}
              >
               {t('Cancel')}
              </button>
              <button
                onClick={handleConfirmLanguage}
                className='btnvoyoger2'
              >
                {t('Sure')}
              </button>
             
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default SelectLanguage;
