import React, { useState, useEffect } from "react";
import "./TaskDetail.css";
import { get_quest_info } from "../../api/get_quest_info";
import { useLocation, useNavigate } from "react-router-dom";
import { API_URL } from "../../utils/client";
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { performquests } from "../../api/performquest";

function TaskDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const [questdata, setquestdata] = useState();
  const [questId, setQuestId] = useState();
  const [isLoading, setIsloading] = useState(false);
  const [selectedFile, setSelectedFile] = useState([]);
  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);

  const handleUpload = async (event) => {
    setIsloading(true);
    const files = event.target.files;
    if (!files.length) {
      setShowSaveChanges(true);
      setSaveChangesMessage("Please select a file first!");
      return;
    }

    const formData = new FormData();
    // Loop through the files and append them to the formData
    for (let i = 0; i < files.length; i++) {
      formData.append("files", files[i]);
    }

    console.log(formData, "this is formData");

    try {
      const response = await fetch(`${API_URL}/quests/upload-proof`, {
        method: "POST",
        body: formData,
      });

      const result = await response.json();
      console.log(result, "this is result");

      if (result) {
        setSelectedFile(result);
        setSaveChangesMessage("Files uploaded successfully!");
        setShowSaveChanges(true);
      } else {
        setIsloading(true);
        setSaveChangesMessage("File upload failed.");
        setShowSaveChanges(true);
      }
    } catch (error) {
      console.error("Error uploading the file:", error);
      setSaveChangesMessage("An error occurred while uploading the file.");
      setShowSaveChanges(true);
    }
    setIsloading(false);
  };
  const handleSubmit = async () => {
    setIsloading(true);
    try {
      setSaveChangesMessage("");
      const token = sessionStorage.getItem("token");
      const data = await performquests(token, questId, selectedFile);
      console.log(token, "this is token ");
      if (data) {
        setSaveChangesMessage("Task Submmited Successfully");
        setShowSaveChanges(true);
        console.log("Task Submmited", data);
        navigate(-1);
      }
    } catch (error) {
      console.error("Error during registration:", error);
    }
    setIsloading(false);
  };
  useEffect(() => {
    const getquest_detail = async () => {
      setIsloading(true);
      const { id } = location.state || {};
      console.log("quest id", questId);
      if (id) {
        const questinfo = await get_quest_info(id);
        console.log("quest detail__");
        console.log(questinfo);
        setquestdata(questinfo);
        setQuestId(id);
      }
      setIsloading(false);
    };

    getquest_detail();
  }, [location.state]);

  const handleurlClick = () => {
    let url = questdata?.Quest?.url;
    if (!url.startsWith("http://") && !url.startsWith("https://")) {
      url = "https://" + url;
    }
    window.open(url, "_blank");
  };

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <>
          <div className="task__detail_main__container">
            <div className="task__detail_main__container-1">
              <img
                src="/Images/Backicon.svg"
                alt="BackIcon"
                onClick={() => {
                  navigate(-1);
                }}
              />
              <p className="taskdetail__heading__1">Task Details</p>
              <img src="/Images/launchpad/backArrowwhite.svg" alt="BackIcon" />
            </div>
            <div className="task__detail_main__container-3">
              <div className="task__detail_main__container-4">
                <ol className="task-list">
                  <li className="task-list-1">{questdata?.Quest?.title}</li>
                  <li>
                    {/* Add a meaningful comment with the following hashtags: <br />
              <span className="hashtags__1">#Magnus #MagnusMetaverse</span> */}
                    {questdata?.Quest?.description}
                  </li>
                  <li>
                    Upload the link of your task, and a screenshot showing proof
                    of work below.
                  </li>
                </ol>
                <button className="gotask__button" onClick={handleurlClick}>
                  Go to task
                </button>

                <div className="uper-wrape">
                  <p className="text">Task Completion Proof</p>
                  <div className="input-img-wrape">
                    <input
                      className="input-text"
                      type="text"
                      placeholder="Upload Task"
                      readOnly
                    />
                    <input
                      type="file"
                      accept="image/*"
                      id="uploadTask"
                      placeholder="Upload Task"
                      onChange={handleUpload}
                      multiple // This allows multiple files to be selected
                      style={{ display: "none" }}
                    />
                    <img
                      src="/Images/link-2.svg"
                      alt="link-img"
                      onClick={() =>
                        document.getElementById("uploadTask").click()
                      }
                    />
                  </div>
                  <p>{selectedFile}</p>
                </div>
              </div>
              <button
                className="submit__task__completion_button"
                onClick={handleSubmit}
              >
                Submit task completiton
              </button>
            </div>
          </div>
          <AlertOk
            show={showSaveChanges}
            handleClose={() => setShowSaveChanges(false)}
            message={saveChangesMessage}
          />
        </>
      )}
    </>
  );
}

export default TaskDetail;
