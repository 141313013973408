import React, { useState, useEffect } from 'react';
import './Navbar.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [act, setAct] = useState('');
  const { t } = useTranslation();
  useEffect(() => {
    const pathToActive = {
      '/Home': 'A',
      '/Roadmap':'B',
      '/ProfileMain': 'E',
      '/Leaderboard': 'D',
    };

    setAct(pathToActive[location.pathname] || '');
  }, [location.pathname]);

  const handleClick = (active) => {
    setAct(active);

    if (active === 'A') {
      navigate('/Home');
    } 
    else if (active === 'B') {
      navigate('/Roadmap');
    }else if (active === 'E') {
      navigate('/ProfileMain');
    }
    else if (active === 'D') {
      navigate('/Leaderboard');
    }
  };

  return (
    <div className='navbar-container'>
      <div className='navbar-main'>
        <div className='navbar-main-inner' onClick={() => handleClick('A')}>
          <img src={act === 'A' ? '/Images/Navbar/homewhite.svg' : '/Images/Navbar/home.svg'} alt='home' />
          <p className='navbar-main-inner-text' style={{ color: act === 'A' ? '#FFFFFF' : '#BCBFCC', fontWeight: act === 'A' ? '500' : '500' }}>{t("Home")}</p>
        </div>
        <div className='navbar-main-inner' onClick={() => handleClick('B')}>
          <img src={act === 'B' ? '/Images/Navbar/Taskswhite.svg' : '/Images/Navbar/Tasks.svg'} alt='task' />
          <p className='navbar-main-inner-text' style={{ color: act === 'B' ? '#FFFFFF' : '#BCBFCC', fontWeight: act === 'B' ? '500' : '500' }}>{t('Updates')}</p>
        </div>
        <div className='navbar-main-inner' onClick={() => handleClick('C')}>
          <img src={act === 'C' ? '/Images/Navbar/invitewhite.svg' : '/Images/Navbar/invite.svg'} alt='invite' style={{width:"24px",  height:"24px"}} />
          <p className='navbar-main-inner-text' style={{ color: act === 'C' ? '#FFFFFF' : '#BCBFCC', fontWeight: act === 'C' ? '500' : '500' }}>{t('Invite')}</p>
        </div>
        <div className='navbar-main-inner' onClick={() => handleClick('D')}>
          <img src={act === 'D' ? '/Images/Navbar/rankwhite.svg' : '/Images/Navbar/rank.svg'} alt='rank' />
          <p className='navbar-main-inner-text' style={{ color: act === 'D' ? '#FFFFFF' : '#BCBFCC', fontWeight: act === 'D' ? '500' : '500' }}>{t('Rankings')}</p>
        </div>
        <div className='navbar-main-inner' onClick={() => handleClick('E')}>
          <img src={act === 'E' ? '/Images/Navbar/profilewhite.svg' : '/Images/Navbar/profile.svg'} alt='profile' />
          <p className='navbar-main-inner-text' style={{ color: act === 'E' ? '#FFFFFF' : '#BCBFCC', fontWeight: act === 'E' ? '500' : '500' }}>{t('Account')}</p>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
