import React, { useEffect } from "react";
import "./Splash.css";
import { useNavigate } from "react-router-dom";
import { getuser } from "../../api/getuser";
import i18n from 'i18next';
const Splash = () => {
  const navigate = useNavigate();

  const getData = async () => {
    try {
    } catch (error) {
      console.error("Error in getData function:", error);
    }
  };

  useEffect(() => {
    const timer = setTimeout(() => {
      getData();
    }, 2000);
    return () => clearTimeout(timer);
  }, [navigate]);

  useEffect(() => {
    async function getUserInfo() {
      const userId =
        new URLSearchParams(window.location.search).get("userId") || "";
      sessionStorage.setItem("chatId", userId);

      const userName =
        new URLSearchParams(window.location.search).get("userName") || "";
      sessionStorage.setItem("userName", userName);

      const data = await getuser(userId);
      console.log(data, "this is data splash screen");
      
      if (data) {
        sessionStorage.setItem("referrer", data?.user?.referralCode);
        sessionStorage.setItem("token", data?.accessToken);
        sessionStorage.setItem("id", data?.user?._id);

        if (data?.user?.onBoarding) {
          setTimeout(() => {
            navigate("/Home");
          }, 2000);
        } else {
          setTimeout(() => {
            navigate("/Platform");
          }, 2000);
        }
      } else {
        i18n.changeLanguage('en');
        setTimeout(() => {
          navigate("/Platform");
        }, 2000);
      }
    }

    getUserInfo();
  }, [navigate]);

  return (
    <div className="main-Splash-div">
      <img src="/Images/Magnus.svg" alt="Logo" />
    </div>
  );
};

export default Splash;
