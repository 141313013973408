
import './CourseVideo.css'
import Navbar from '../../Components/Navbar/Navbar'
import React, { useState, useRef } from "react";
import ProgressBar from 'react-bootstrap/ProgressBar';
const CourseVideo = () => {

    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
   

    
    const handlePlayPause = () => {
        if (isPlaying) {
            videoRef.current.pause();
        } else {
            videoRef.current.play();
        }
        setIsPlaying(!isPlaying);
    };

    
   
    const now = 60;

    return (
        <div className='Main-Course-Video'>

            <div className="Main-Course-arrow-div">
                <img src="/Images/Backicon.svg" alt="BackIcon" />
                <img src="/Images/CourseVideo/download.svg" alt="download" />
            </div>



            <div className="video-player-container">

                <video
                    ref={videoRef}
                    className="video-element"
                    // onTimeUpdate={handleTimeUpdate}
                    // onLoadedMetadata={handleLoadedMetadata}
                    src="/Images/CourseVideo/Video.mp4"
                    controls={false}
                />

                <div className='Control-Outer'>
                    <div className="controls">
                        <button className="play-pause-btn" onClick={handlePlayPause}>
                            {isPlaying ? (

                                <img src="/Images/CourseVideo/Play.svg" alt="Play" />
                            ) : (
                                <img src="/Images/CourseVideo/Pause.svg" alt="Pause" />
                            )}
                        </button>



                        <ProgressBar
                            className="custom-progress-bar-for-Video"
                            now={now}
                            label={`${now}%`}
                            visuallyHidden
                        />


                        <div className="time-info">
                            <span>2:20<span className='lightcolor-time'>/10:00</span></span>
                        </div>
                        <button className="Full-Screen">
                            <img src="/Images/CourseVideo/FullScreen.svg" alt="download" />
                        </button>
                    </div>
                </div>
            </div>

            <div className='Teacher-Detail-Div'>

                <p className='Cryto-Mastery-text'>Crypto Mastery</p>
                <div className='Crypto-Line'>
                    <img src="/Images/CourseVideo/teacherprofile.svg" alt="teacherprofile" />
                    <p className='Teacher-Name-Text'>Livia Donin</p>
                    <div className='dot-circle'></div>
                    <p className='crypto-Sec-text'>Crypto</p>
                </div>
            </div>

            <div>
                <p className='Read-More-Para'>Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Morbi convallis convallis diam sit amet lacinia.
                <span className='Read-More-Bold'> Read More...</span></p>
            </div>

            <div className='Videos-Div'>
                {/* First Video */}
                <div className='Video-Click'>
                    <div className='Video-Click-First'>
                        <p className='Video-Title'>
                            1. Introduction to Crypto
                        </p>
                        <div className='Video-Duration'>
                            <img src="/Images/CourseVideo/video.svg" alt="video" />
                            <p className='Video-Duration-Text'>1 video | 20 min</p>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/CourseVideo/VideoCompletion.svg" alt="VideoCompletion" />
                    </div>
                </div>
                {/* Second Video */}
                <div className='Video-Click'>
                    <div className='Video-Click-First'>
                        <p className='Video-Title'>
                            1. Introduction to Crypto
                        </p>
                        <div className='Video-Duration'>
                            <img src="/Images/CourseVideo/video.svg" alt="video" />
                            <p className='Video-Duration-Text'>1 video | 20 min</p>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
                    </div>
                </div>
                {/* Third Video */}
                <div className='Video-Click'>
                    <div className='Video-Click-First'>
                        <p className='Video-Title'>
                            1. Introduction to Crypto
                        </p>
                        <div className='Video-Duration'>
                            <img src="/Images/CourseVideo/video.svg" alt="video" />
                            <p className='Video-Duration-Text'>1 video | 20 min</p>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
                    </div>
                </div>
                {/* Fourth Video */}
                <div className='Video-Click'>
                    <div className='Video-Click-First'>
                        <p className='Video-Title'>
                            1. Introduction to Crypto
                        </p>
                        <div className='Video-Duration'>
                            <img src="/Images/CourseVideo/video.svg" alt="video" />
                            <p className='Video-Duration-Text'>1 video | 20 min</p>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
                    </div>
                </div>
                {/* Fifth Video */}
                <div className='Video-Click'>
                    <div className='Video-Click-First'>
                        <p className='Video-Title'>
                            1. Introduction to Crypto
                        </p>
                        <div className='Video-Duration'>
                            <img src="/Images/CourseVideo/video.svg" alt="video" />
                            <p className='Video-Duration-Text'>1 video | 20 min</p>
                        </div>
                    </div>
                    <div>
                        <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
                    </div>
                </div>
            </div>
            <Navbar />
        </div>
    )
}

export default CourseVideo  
