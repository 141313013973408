
import React from 'react';
import { Modal } from 'react-bootstrap';
import './VoyagerModal.css'
import { useTranslation } from 'react-i18next';
const VoyagerModal = ({ show, handleClose }) => {
    const { t } = useTranslation();
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="voyager-first-modal" centered>
                <Modal.Body className='VerifyModal'>
                    <div className='Cross-Icon-Voyager'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />
                    </div>
                    <div className="text-center">

                        {/* <img src="/Images/Vogayer.svg" alt="Voyager" className='ImageTops' /> */}
                        <img src="/Images/Modal/MagTaycon.svg" alt="MagTaycon" className='ImageTops' />
                        <h1 className='VoyagerHeadingBold'>
                        {t('MAG TYCOON')}
                        </h1>

                        <p className='VoyagerInner'>{t('Earn the Tycoon badge by buying 10,000 $MAG Tokens!')}</p>
                        <p className='VoyagerInner2'>{t('Unlocked on 23 Dec 2024')}</p>

                        <div className='twobutton'>
                            <button
                                onClick={handleClose}
                                className='btnvoyoger1'
                            >
                                {t('Share')}
                            </button>
                            <button
                                className='btnvoyoger2'
                                onClick={handleClose}
                            >
                                {t('Close')}
                            </button>

                        </div>

                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VoyagerModal;

