import React, { useEffect } from "react";
import { Drawer, styled } from "@mui/material";
import "./UsdtModel.css";
import { useTranslation } from 'react-i18next';
import { useState } from "react";

const CustomCheckbox = styled("input")(({ checked }) => ({
  appearance: "none",
  width: "20px",
  height: "20px",
  borderRadius: "5px",
  backgroundColor: checked ? "#323232" : "transparent",
  border: "1.25px solid rgba(0, 0, 0, 0.16)",
  position: "relative",
  cursor: "pointer",
  "&::before": {
    content: checked ? `url("/Images/CommisionHistory/tick.svg")` : "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: checked ? "white" : "transparent",
    fontSize: "12px",
  },
}));

const UsdtModel = ({ open, onClose }) => {
  const [checked, setChecked] = useState([false, false, false, false]);
  const { t } = useTranslation();
  const handleInputCheck = (index) => {
    setChecked((prev) => {
      const newCheckBoxes = [...prev];
      newCheckBoxes[index] = !newCheckBoxes[index];
      return newCheckBoxes;
    });
  };

  useEffect(() => {}, []);

  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div className="usdtModel_Main">
        {/*  */}
        <div className="usdtModel_Line_Top">
          <div></div>
        </div>

        {/*  */}
        <div className="usdtModel_Center_Main">
          <div className="usdtModel_Center_Main_Body">
            <div className="usdtModel_Center_Body_Heading">{t('USDT Filter')}</div>
            {/* All imputes and checks are here */}
            {["Wealth Academy", "Launchpad", "Trading Bot", "Airdrops"].map(
              (lebel, index) => (
                <div className="usdtModel_Center_Body_RadioBtn" key={index}>
                  <label className="CheckBox_Input">
                    <CustomCheckbox
                      type="checkbox"
                      checked={checked[index]}
                      onChange={() => handleInputCheck(index)} // Toggle checked state on change
                    />
                  </label>
                  {t(lebel)}
                </div>
              )
            )}
          </div>
        </div>

        {/*  */}
        <div className="USDT_Model_Button_Main">
          <button className="usdt_Model_Close_Btn">{t('Confirm')}</button>
        </div>
      </div>
    </Drawer>
  );
};

export default UsdtModel;
