import { API_URL } from "../utils/client";

export const getProfilePicture = async () => {
  try {
    const response = await fetch(`${API_URL}/mag-store/get-profile-pictures`, {
      method: "GET",
    //   headers: {
    //     Authorization: `Bearer ${token}`,
    //   },
      //   body: JSON.stringify({ id, userName }),
    });
    console.log(response, "this is console log");
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
