import React, { Act, useState } from "react";
import "./Ranking.css";
import { useTranslation } from 'react-i18next';
const Ranking4 = () => {
  const [Act, SetAct] = useState("A");
  const handleClick = (active) => {
    SetAct(active);
  };
  const { t } = useTranslation();
  const inviteData = [
    { id: 1, name: "Ella", invites: 100 },
    { id: 2, name: "Mary Jones", invites: 100 },
    { id: 3, name: "Sarah Lowe", invites: 100 },
    { id: 4, name: "Cisse", invites: 100 },
    { id: 5, name: "Hector Lowe", invites: 100 },
  ];
  return (
    <div className="Ranking__Main">
      <div className="Ranking__Main_FirstDiv">
        <button
          className="Ranking__Main_FirstDiv_Button1"
          type="submit"
          style={{
            background:
              Act === "A"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "A" ? "var(--White, #FFFFFF)" : "#1E1E24",
          }}
          onClick={() => handleClick("A")}
        >
          {t('Today')}
        </button>

        <button
          className="Ranking__Main_FirstDiv_Button2"
          type="submit"
          style={{
            background:
              Act === "B"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "B" ? "var(--White, #FFFFFF)" : "#1E1E24",
          }}
          onClick={() => handleClick("B")}
        >
          {t('Week')}
        </button>

        <button
          className="Ranking__Main_FirstDiv_Button1"
          type="submit"
          style={{
            background:
              Act === "C"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "C" ? "var(--White, #FFFFFF)" : "#1E1E24",
          }}
          onClick={() => handleClick("C")}
        >
          {t('Month')}
        </button>

        <button
          className="Ranking__Main_FirstDiv_Button1"
          type="submit"
          style={{
            background:
              Act === "D"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "D" ? "var(--White, #FFFFFF)" : "#1E1E24",
          }}
          onClick={() => handleClick("D")}
        >
          {t('All Time')}
        </button>
      </div>

      {Act === "A" && (
        <>
          <div className="invite-list">
            {inviteData.map((invitee) => (
              <div key={invitee.id} className="invite-card">
                <div className="invite-id">
                  {invitee.id < 10 ? `0${invitee.id}` : invitee.id}
                </div>
                <div className="invite-details">
                  <div className="invite-icon">
                    <img
                      src="/Images/Leaderboardimages/Ranking/firstimg.svg"
                      alt="g"
                    />
                  </div>
                  <div className="invite-name">{invitee.name}</div>
                </div>
                <div className="invite-count">
                  <span className="invites">{t('Invites')}</span>
                  <strong className="invites2">{invitee.invites}</strong>
                </div>
              </div>
            ))}
          </div>

          <div className="Ranking_btn">
            {/* <button className="Ranking_btn_styling">My Position: 43</button> */}
          </div>
        </>
      )}

      {Act === "B" && (
        <>
          <div className="invite-list">
            {inviteData.map((invitee) => (
              <div key={invitee.id} className="invite-card">
                <div className="invite-id">
                  {invitee.id < 10 ? `0${invitee.id}` : invitee.id}
                </div>
                <div className="invite-details">
                  <div className="invite-icon">
                    <img  src="/Images/Leaderboardimages/Ranking/firstimg.svg"
                      alt="g" />
                  </div>
                  <div className="invite-name">{invitee.name}</div>
                </div>
                <div className="invite-count">
                  <span className="invites">{t('Invites')}</span>
                  <strong className="invites2">{invitee.invites}</strong>
                </div>
              </div>
            ))}
          </div>
{/* 
          <div className="Ranking_btn">
            <button className="Ranking_btn_styling">My Position: 45</button>
          </div> */}
        </>
      )}

      {Act === "C" && (
        <>
          <div className="invite-list">
            {inviteData.map((invitee) => (
              <div key={invitee.id} className="invite-card">
                <div className="invite-id">
                  {invitee.id < 10 ? `0${invitee.id}` : invitee.id}
                </div>
                <div className="invite-details">
                  <div className="invite-icon">
                    <img  src="/Images/Leaderboardimages/Ranking/firstimg.svg"
                      alt="g" />
                  </div>
                  <div className="invite-name">{invitee.name}</div>
                </div>
                <div className="invite-count">
                  <span className="invites">{t('Invites')}</span>
                  <strong className="invites2">{invitee.invites}</strong>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="Ranking_btn">
            <button className="Ranking_btn_styling">My Position: 47</button>
          </div> */}
        </>
      )}

      {Act === "D" && (
        <>
          <div className="invite-list">
            {inviteData.map((invitee) => (
              <div key={invitee.id} className="invite-card">
                <div className="invite-id">
                  {invitee.id < 10 ? `0${invitee.id}` : invitee.id}
                </div>
                <div className="invite-details">
                  <div className="invite-icon">
                    <img
                      src="/Images/Leaderboardimages/Ranking/firstimg.svg"
                      alt="icon"
                    />
                  </div>
                  <div className="invite-name">{invitee.name}</div>
                </div>
                <div className="invite-count">
                  <span className="invites">{t('Invites')}</span>
                  <strong className="invites2">{invitee.invites}</strong>
                </div>
              </div>
            ))}
          </div>

          {/* <div className="Ranking_btn">
            <button className="Ranking_btn_styling">My Position: 48</button>
          </div> */}
        </>
      )}
    </div>
  );
};

export default Ranking4;
