import React, { useState } from 'react';
import './Wealth.css';
import Navbar from '../../Components/Navbar/Navbar';
import { useNavigate } from 'react-router-dom';

const Wealth = () => {

    const navigate = useNavigate();

    const [activeButton, setActiveButton] = useState('courses');

    const handleButtonClick = (buttonName) => {
        setActiveButton(buttonName);
    };
    const ButtonClick = () => {
        handleButtonClick('market')
        navigate('/WealthAcademy')
    }

    const [activeNewButton, setActiveNewButton] = useState('crypto');

    return (
        <div className='Wealth-Main'>
            <div style={{ paddingLeft: "24px", paddingRight: "24px" }}>
                <div className="Wealth-arrow-div">
                    <img src="/Images/Backicon.svg" alt="BackIcon" onClick={() => navigate(-1)} />
                    <p className='Wealth-heading'>Wealth Academy</p>
                    <img src="/Images/arrowref2.svg" alt=""/>
                </div>
                <div className='Wealth-Buttons-div'>
                    <button
                        className={`coursesbtn ${activeButton === 'courses' ? 'active' : 'transparent'}`}
                        onClick={() => handleButtonClick('courses')}
                    >
                        Courses
                    </button>
                    <button
                        className={`marketbtn ${activeButton === 'market' ? 'active' : 'transparent'}`}
                        onClick={ButtonClick}
                    >
                        Market Report
                    </button>
                </div>
            </div>

            <div className='cryptobuttons'>
                {/* First Button - Crypto */}
                <div
                    className={activeNewButton === 'crypto' ? 'first-btn-crypto-div' : 'first-btn-crypto-div-with-click'}
                    onClick={() => setActiveNewButton('crypto')}
                >
                    <div style={{ backgroundColor: activeNewButton === 'crypto' ? "#1E1E241A" : "transparent", borderRadius: "12px" }}>
                        <button className='first-btn-crypto'>
                            <p className={activeNewButton === 'crypto' ? 'btn-Gradient' : 'btn-Gradient-with-clicked'}>Crypto</p>
                        </button>
                    </div>
                </div>

                {/* Second Button - Stocks */}
                <div
                    className={activeNewButton === 'stocks' ? 'first-btn-crypto-div2' : 'first-btn-crypto-div-with-click2'}
                    onClick={() => setActiveNewButton('stocks')}
                >
                    <div style={{ backgroundColor: activeNewButton === 'stocks' ? "#1E1E241A" : "transparent", borderRadius: "12px" }}>
                        <button className='first-btn-crypto2'>
                            <p className={activeNewButton === 'stocks' ? 'btn-Gradient' : 'btn-Gradient-with-clicked2'}>Stocks</p>
                        </button>
                    </div>
                </div>

                {/* Third Button - Entrepreneurship */}
                <div
                    className={activeNewButton === 'entrepreneurship' ? 'first-btn-crypto-div3' : 'first-btn-crypto-div-with-click3'}
                    onClick={() => setActiveNewButton('entrepreneurship')}
                >
                    <div style={{ backgroundColor: activeNewButton === 'entrepreneurship' ? "#1E1E241A" : "transparent", borderRadius: "12px" }}>
                        <button className='first-btn-crypto3'>
                            <p className={activeNewButton === 'entrepreneurship' ? 'btn-Gradient' : 'btn-Gradient-with-clicked3'}>Entrepreneurship</p>
                        </button>
                    </div>
                </div>
            </div>

            <div className='Main-recommended-center'>
                <div className='recommended-div'>
                    <p className='recommended-text'>Recommended</p>


                    <div className='rec-inside-div'>
                        <div className='two-two-dives'>
                            <div className='Two-Rows-Cards'>
                                {/* first Course */}
                                <div style={{ width: "50%" }} >
                                    <div className='image-postion-handle1' onClick={() => navigate('/VideosDetail')}>


                                        <div className='HeartMain1' onClick={(e) => e.stopPropagation()}   >
                                            <img src="/Images/Wealth/hearthnew.svg" alt="card" />
                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                    <div className='card-inner-text' onClick={() => navigate('/VideosDetail')}>
                                        <div>
                                            <p className='firstsmall-text'>
                                                Crypto
                                            </p>
                                            <p className='secondlarge-text'>
                                                Crypto Mastery
                                            </p>
                                        </div>
                                        <div className='timer-lesson-div'>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/timer.svg" alt="BackIcon" />
                                                <p className='time-text'>2h 32min</p>
                                            </div>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/lessons.svg" alt="BackIcon" />
                                                <p className='time-text'>12 lessons</p>
                                            </div>
                                        </div>
                                        <div className='star-div'>
                                            <img src="/Images/Wealth/star.svg" alt="BackIcon" />
                                            <p className='star-text'>4.3</p>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <div className='image-postion-handle2'>

                                        <div className='HeartMain1'>
                                            <img src="/Images/Wealth/hearthnew.svg" alt="card" />
                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                    <div className='card-inner-text'>
                                        <div>
                                            <p className='firstsmall-text'>
                                                Crypto
                                            </p>
                                            <p className='secondlarge-text'>
                                                Crypto Mastery
                                            </p>
                                        </div>
                                        <div className='timer-lesson-div'>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/timer.svg" alt="BackIcon" />
                                                <p className='time-text'>2h 32min</p>
                                            </div>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/lessons.svg" alt="BackIcon" />
                                                <p className='time-text'>12 lessons</p>
                                            </div>
                                        </div>
                                        <div className='star-div'>
                                            <img src="/Images/Wealth/star.svg" alt="BackIcon" />
                                            <p className='star-text'>4.3</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <div className='Two-Rows-Cards'>
                                <div style={{ width: "50%" }}>
                                    <div className='image-postion-handle3'>


                                        <div className='HeartMain1'>
                                            <img src="/Images/Wealth/hearthnew.svg" alt="card" />
                                        </div>

                                        <div>

                                        </div>
                                    </div>
                                    <div className='card-inner-text'>
                                        <div>
                                            <p className='firstsmall-text'>
                                                Crypto
                                            </p>
                                            <p className='secondlarge-text'>
                                                Crypto Mastery
                                            </p>
                                        </div>
                                        <div className='timer-lesson-div'>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/timer.svg" alt="BackIcon" />
                                                <p className='time-text'>2h 32min</p>
                                            </div>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/lessons.svg" alt="BackIcon" />
                                                <p className='time-text'>12 lessons</p>
                                            </div>
                                        </div>
                                        <div className='star-div'>
                                            <img src="/Images/Wealth/star.svg" alt="BackIcon" />
                                            <p className='star-text'>4.3</p>
                                        </div>

                                    </div>
                                </div>
                                <div style={{ width: "50%" }}>
                                    <div className='image-postion-handle1'>

                                        <div className='HeartMain1'>
                                            <img src="/Images/Wealth/hearthnew.svg" alt="card" />
                                        </div>
                                        <div>

                                        </div>
                                    </div>
                                    <div className='card-inner-text'>
                                        <div>
                                            <p className='firstsmall-text'>
                                                Crypto
                                            </p>
                                            <p className='secondlarge-text'>
                                                Crypto Mastery
                                            </p>
                                        </div>
                                        <div className='timer-lesson-div'>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/timer.svg" alt="BackIcon" />
                                                <p className='time-text'>2h 32min</p>
                                            </div>
                                            <div className='timer-div'>
                                                <img src="/Images/Wealth/lessons.svg" alt="BackIcon" />
                                                <p className='time-text'>12 lessons</p>
                                            </div>
                                        </div>
                                        <div className='star-div'>
                                            <img src="/Images/Wealth/star.svg" alt="BackIcon" />
                                            <p className='star-text'>4.3</p>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
            <Navbar />
        </div>
    );
}

export default Wealth;
