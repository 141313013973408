import React from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import "./Platform.css";
const Platform = () => {

  const navigate = useNavigate();
  const { t } = useTranslation();
  return (
    <div className="main-platform">
      <div className="platform-content">
        <div className="platform-image">
          
          <img src="/Images/onboardingscreen.webp" alt=""/>
        </div>
        <div className="platform-heading">
          <h2 >{t('The leading platform for crypto airdrops')} </h2>
        </div>
        <div className="platform-text">
          <p>
         { t('Discover exclusive airdrops from trending crypto projects and earn rewards for your engagement.')}
          </p>
        </div>
        <div className="platform-bottom-button">
          <button className="platform-btn" onClick={() => navigate("/CryptoPlatform")}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default Platform;
