import { API_URL } from "../utils/client";

export const register = async (
  chatId,
  telegramUsername,
  name,
  email, 
  referrer,
  onBoarding
) => {
  try {
    const response = await fetch(`${API_URL}/user/telegram-miniapp/setup`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ telegramUsername, name, email, referrer, chatId,onBoarding }),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch user data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
