import React, { useEffect } from 'react';
import './AlertNot.css';

const AlertNot = ({ show, handleClose, message }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                handleClose();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [show, handleClose]);

    return (
        <div className={`alertnot-modal ${show ? 'show' : ''}`} onClick={handleClose}>
            <div className='alert-not-white-div'>
                <div className='alertnot-content'>
                    <div className='text-center-alertnot'>
                        <div>
                            <img src="/Images/Alert/not.svg" alt='' />
                        </div>
                        <p className='message-not'>{message}</p>
                    </div>
                </div></div>
        </div>
    );
};

export default AlertNot;