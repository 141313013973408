import React, { useState, useRef, useEffect } from "react";
import "./CourseCompleted.css";
import Navbar from "../../Components/Navbar/Navbar";
import ProgressBar from "react-bootstrap/ProgressBar";
import { useNavigate } from "react-router-dom";
const CourseCompleted = () => {
  const navigate = useNavigate();
  const videoRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [progress, setProgress] = useState(0);
  const [currentTime, setCurrentTime] = useState("0:00");
  const [duration, setDuration] = useState("0:00");
  const handlePlayPause = () => {
    if (isPlaying) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
    setIsPlaying(!isPlaying);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? `0${seconds}` : seconds}`;
  };

  const handleTimeUpdate = () => {
    const current = videoRef.current.currentTime;
    const duration = videoRef.current.duration;
    setProgress((current / duration) * 100);
    setCurrentTime(formatTime(current));
  };

  const handleLoadedMetadata = () => {
    const duration = videoRef.current.duration;
    setDuration(formatTime(duration));
  };

  const handleFullScreen = () => {
    const videoElement = videoRef.current;

    if (videoElement.requestFullscreen) {
      videoElement.requestFullscreen();
    } else if (videoElement.webkitRequestFullscreen) {
      // For Safari on iOS
      videoElement.webkitRequestFullscreen();
    } else if (videoElement.msRequestFullscreen) {
      videoElement.msRequestFullscreen();
    } else {
      // Attempt to use the iOS specific full-screen API
      if (videoElement.webkitEnterFullscreen) {
        videoElement.webkitEnterFullscreen();
      }
    }
  };
  useEffect(() => {
    const videoElement = videoRef.current;

    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);

    videoElement.addEventListener("play", handlePlay);
    videoElement.addEventListener("pause", handlePause);

    return () => {
      videoElement.removeEventListener("play", handlePlay);
      videoElement.removeEventListener("pause", handlePause);
    };
  }, []);



  const [isExpanded, setIsExpanded] = useState(false);

  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };


  return (
    <div className="Main-Course-Video-Completed">
      <div className="Main-Course-arrow-div-Completed">
        <img
          src="/Images/Backicon.svg"
          alt="BackIcon"
          onClick={() => navigate(-1)}
        />
        <img src="/Images/CourseVideo/download.svg" alt="download" />
      </div>

      <div className="video-player-container">
        <video
          ref={videoRef}
          className="video-element"
          onTimeUpdate={handleTimeUpdate}
          onLoadedMetadata={handleLoadedMetadata}
          src="/Images/CourseVideo/Video.mp4"
          controls={false} // Ensure controls are disabled
          playsInline // Prevent default fullscreen on iOS
        />

        <div className="Control-Outer">
          <div className="controls">
            <button className="play-pause-btn" onClick={handlePlayPause}>
              {isPlaying ? (
                <img src="/Images/CourseVideo/play.svg" alt="Play" />
              ) : (
                <img src="/Images/CourseVideo/Pause.svg" alt="Pause" />
              )}
            </button>

            <ProgressBar
              className="custom-progress-bar-for-Video"
              now={progress}
            />

            <div className="time-info">
              <span>
                {currentTime}
                <span className="lightcolor-time">/{duration}</span>
              </span>
            </div>
            <button className="Full-Screen" onClick={handleFullScreen}>
              <img src="/Images/CourseVideo/FullScreen.svg" alt="FullScreen" />
            </button>
          </div>
        </div>
      </div>

      <div className="Teacher-Detail-Div-Completed">
        <p className="Cryto-Mastery-text-Completed">Crypto Mastery</p>
        <div className="Crypto-Line-Completed">
          <img
            src="/Images/CourseVideo/teacherprofile.svg"
            alt="teacherprofile"
          />
          <p className="Teacher-Name-Text-Completed">Livia Donin</p>
          <div className="dot-circle-Completed"></div>
          <p className="crypto-Sec-text-Completed">Crypto</p>
        </div>
      </div>

      {/* <div>
        <p className="Read-More-Para-Completed">
          Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu
          turpis molestie, dictum est a, mattis tellus. Morbi convallis
          convallis diam sit amet lacinia.
          <span className="Read-More-Bold-Completed"> Read More...</span>
        </p>
      </div> */}
      <div>
        <p className="Read-More-Para-Completed">
          Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis
          molestie, dictum est a, mattis tellus. Morbi convallis convallis diam
          sit amet lacinia.
          {isExpanded && (
            <>
              <> </>Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis
              molestie, dictum est a, mattis tellus. Morbi convallis convallis diam
              sit amet lacinia.
            </>
          )}
          <span
            className="Read-More-Bold-Completed"
            onClick={toggleReadMore}

          >
            {isExpanded ? " Show Less" : " Read More..."}
          </span>
        </p>
      </div>


      <div className="Videos-Div-Completed">
        {/* First Video */}
        <div className="Video-Click-Completed">
          <div className="Video-Click-First-Completed">
            <p className="Video-Title-Completed">1. Introduction to Crypto</p>
            <div className="Video-Duration-Completed">
              <img src="/Images/CourseVideo/video.svg" alt="video" />
              <p className="Video-Duration-Text-Completed">1 video | 20 min</p>
            </div>
          </div>
          <div>
            <img
              src="/Images/CourseVideo/HundredPer.svg"
              alt="VideoCompletion"
            />
          </div>
        </div>

        {/* Second Video */}
        <div className="Video-Click-Completed">
          <div className="Video-Click-First-Completed">
            <p className="Video-Title-Completed">1. Introduction to Crypto</p>
            <div className="Video-Duration-Completed">
              <img src="/Images/CourseVideo/video.svg" alt="video" />
              <p className="Video-Duration-Text-Completed">1 video | 20 min</p>
            </div>
          </div>
          <div>
            <img
              src="/Images/CourseVideo/HundredPer.svg"
              alt="VideoCompletion"
            />
          </div>
        </div>

        {/* Third Video */}
        <div className="Video-Click-Completed">
          <div className="Video-Click-First-Completed">
            <p className="Video-Title-Completed">1. Introduction to Crypto</p>
            <div className="Video-Duration-Completed">
              <img src="/Images/CourseVideo/video.svg" alt="video" />
              <p className="Video-Duration-Text-Completed">1 video | 20 min</p>
            </div>
          </div>
          <div>
            <img
              src="/Images/CourseVideo/HundredPer.svg"
              alt="VideoCompletion"
            />
          </div>
        </div>

        {/* Fourth Video */}
        <div className="Video-Click-Completed">
          <div className="Video-Click-First-Completed">
            <p className="Video-Title-Completed">1. Introduction to Crypto</p>
            <div className="Video-Duration-Completed">
              <img src="/Images/CourseVideo/video.svg" alt="video" />
              <p className="Video-Duration-Text-Completed">1 video | 20 min</p>
            </div>
          </div>
          <div>
            <img
              src="/Images/CourseVideo/HundredPer.svg"
              alt="VideoCompletion"
            />
          </div>
        </div>

        {/* Fifth Video */}
        <div className="Video-Click-Completed">
          <div className="Video-Click-First-Completed">
            <p className="Video-Title-Completed">1. Introduction to Crypto</p>
            <div className="Video-Duration-Completed">
              <img src="/Images/CourseVideo/video.svg" alt="video" />
              <p className="Video-Duration-Text-Completed">1 video | 20 min</p>
            </div>
          </div>
          <div>
            <img
              src="/Images/CourseVideo/HundredPer.svg"
              alt="VideoCompletion"
            />
          </div>
        </div>
      </div>

    

      <Navbar />
    </div>
  );
};

export default CourseCompleted;
