import React from 'react';
import './ResponsiveRoadmap.css'
import { Box, Stepper, Step, StepLabel, Typography, useMediaQuery } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PersonIcon from '@mui/icons-material/Person';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import BadgeIcon from '@mui/icons-material/Badge';
import { useTheme } from '@mui/material/styles';

const steps = [
  { label: 'Basic Game', icon: <img src='/Images/magnas/basicGame.svg' alt='basic img'/>, completed: true, className: 'basic-game' },
  { label: 'Mining Upgrades', icon:<img src='/Images/magnas/basicGame.svg' alt='basic img'/>, completed: true ,className: 'basic-game'},
  { label: 'Profiles', icon: <img src='/Images/magnas/profile.svg' alt='basic img'/>, completed: false, className: 'Profiles' },
  { label: 'Referrals', icon:<img src='/Images/magnas/raffels.svg' alt='basic img'/>, completed: false,className: 'Profiles'},
  { label: 'Quests', icon:<img src='/Images/magnas/quests.svg' alt='basic img'/>, completed: false,className: 'Profiles' },
  { label: 'Badges', icon:<img src='/Images/magnas/badges.svg' alt='basic img'/>, completed: false, className: 'Profiles'  },
];



const ResponsiveRoadmap = () => {

    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  
  return (
    <Box sx={{ width: '100%', maxWidth: isSmallScreen ? '100%' : 300, mx: 'auto', mt: 4 }}>
      <Stepper
        orientation="vertical"
        sx={{
          '.MuiStepConnector-root': {
            ml: 1.5,
            '& .MuiStepConnector-line': {
              borderColor: theme.palette.grey[300],
            },
          },
        }}
      >
        {steps.map((step, index) => (
          <Step key={index} active={step.completed} completed={step.completed}>
            <StepLabel
  icon={step.icon} // Always use the custom icon from the steps array

  sx={{
    '& .MuiStepLabel-label': {
      color: step.completed ? theme.palette.text.primary : theme.palette.grey[500],
    },
  }}
            >
              <Typography variant="body1"  className={`step-label ${step.className}`}   >
                {step.label}




              </Typography>
            </StepLabel>
          </Step>
        ))}

        
      </Stepper>
    </Box>
  );
};

export default ResponsiveRoadmap;
