import React from 'react'
import { Modal } from 'react-bootstrap';
import './QuizPass.css'
const QuizPass = ({ show, handleClose }) => {
    return (
        <div>
            <Modal show={show} onHide={handleClose} className="QuizPass-first-modal" centered>
                <Modal.Body >
                    <div className='Cross-Icon-Quiz'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />
                    </div>
                    <div className="text-center-quiz">
                        <img src="/Images/Quiz/PassImage1.png" alt="" className='quiz-pic' style={{height:"auto",width:"120px"}}/>
                        <p className='pass-text'>Great! You have passed the test!</p>
                        <p className='earned-text'>Earned 15 MAG</p>
                        <button className='back-to-course-btn' onClick={handleClose}>Back to Course</button>
                    </div>
                </Modal.Body>
            </Modal>

        </div>
    )
}

export default QuizPass
