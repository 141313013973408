import { API_URL } from "../utils/client";

export const getRankingTime = async (token , timeframe , page) => {
  try {
    const response = await fetch(
      `${API_URL}/ranking/tasks-time-frame/?timeframe=${timeframe}&page=${page}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    console.log(response, "this is console log");
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to fetch  data");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
