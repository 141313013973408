import React from "react";
import "./RankProgression.css";
import { useNavigate } from "react-router-dom";
import RankCard from "../../Components/Card/RankCard";
import { useEffect, useState } from "react";
import { getRank } from "../../api/getRank";
import Navbar from "../../Components/Navbar/Navbar";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
import { useTranslation } from 'react-i18next';

const RankProgression = () => {
  const [showRank, setShowRank] = useState([]);
  const [isLoading, setIsloading] = useState(false);
  const { t } = useTranslation();
  useEffect(() => {
    async function getRankings() {
      setIsloading(true);
      const token = sessionStorage.getItem("token");
      // setIsloading(true);
      const data = await getRank(token);
      console.log(data, "this is data of Rank");
      const combinedData = []
        .concat(data?.previousRanks || []) // Concatenate previousRanks (empty array if undefined)
        .concat(data?.currentRank ? [data.currentRank] : []) // Concatenate the rank object (only if exists)
        .concat(data?.upcomingRanks || []); // Concatenate upcomingRanks (empty array if undefined)
      const groupedByRankName = combinedData.reduce((acc, item) => {
        const rankName = item?.rank.split(" ")[0]; // Extract rank name from levelName (e.g., "Bronze" from "Bronze Level 1")

        // If the rank name doesn't exist in the accumulator, initialize it as an empty array
        if (!acc[rankName]) {
          acc[rankName] = [];
        }

        // Push the item (rank level) into the corresponding rank array
        acc[rankName]?.push(item);

        return acc;
      }, {});
      console.log(groupedByRankName, "this is data of Rank");

      setShowRank(groupedByRankName);
      setIsloading(false);
    }
    getRankings();
  }, []);
  const navigate = useNavigate();

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <>
          <div className="RankningProgression_Main">
            <div className="RankningProgression-arrow-div">
              <img
                src="/Images/Backicon.svg"
                alt="BackIcon"
                onClick={() => navigate(-1)}
              />
              <p className="RankningProgression-heading">{t('Rank Progression')}</p>
              <p></p>
            </div>

            <div className="RankningProgression_Body">
              {Object?.keys(showRank)?.map((rankName, index) => (
                <RankCard
                  key={index}
                  rankName={rankName} 
                  rankLevels={showRank[rankName]}
                />
              ))}
            </div>
          </div>
          <Navbar />
        </>
      )}
    </>
  );
};

export default RankProgression;
