import React, { useState } from "react";
import "./Quiz.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";
import { ProgressBar } from 'react-bootstrap';
import QuizPass from "../../Components/Modal/QuizPass";
import QuizFail from "../../Components/Modal/QuizFail";
const Quiz = () => {
  const navigate = useNavigate();


  const [activeButton, setActiveButton] = useState("Quiz");

  // const quiz = () => {
  //   setActiveButton("Quiz");
  //   navigate("/Quiz");
  // };
  // const lesson = () => {
  //   setActiveButton("Lessons");
  //   navigate("/Home/Wealth/VideosDetail");
  // };


  const [selectedOption, setSelectedOption] = useState(null);
  const options = [
    { id: 1, text: 'Satoshi Nakamoto' },
    { id: 2, text: 'Elon Musk' },
    { id: 3, text: 'Jeff Bezos' },
    { id: 4, text: 'Nikola Tesla' },
    { id: 5, text: 'Albert Einstein' },
  ];

  const handleOptionClick = (id) => {
    setSelectedOption(id);
  };

  const [showQuizPassModal, setShowQuizPassModal] = useState(false);

  const openQuizPassModal = () => {
    setShowQuizPassModal(true);
  };

  const closeQuizPassModal = () => {
    setShowQuizPassModal(false);
  };


  const [showQuizFailModal, setShowQuizFailModal] = useState(false);

  const openQuizFailModal = () => {
    setShowQuizFailModal(true);
  };

  const closeQuizFailModal = () => {
    setShowQuizFailModal(false);
  };


  return (
    <div className="quiz-main">
      <div className="quiz-arrow">
        <img src="/Images/arrowref.svg" alt="" onClick={() => navigate(-1)} />
        <p className="quiz-arrowtext">Quiz</p>
        <img src="/Images/arrowref2.svg" alt=""/>
      </div>


      <div className="VideosDetail-ThreeButtons-Div2">
        <div className="VideosDetail-ThreeButtons-Div-inner2">
          <button
            className={`Lessons-Btn2 ${activeButton === "Lessons" ? "Active-Btn2" : ""
              }`}
            onClick={() => setActiveButton("Lessons")}
          >
            Lessons
          </button>
          <button
            className={`Overview-Btn2 ${activeButton === "Overview" ? "Active-Btn2" : ""
              }`}
            onClick={() => setActiveButton("Overview")}
          >
            Overview
          </button>
          <button
            className={`Quiz-Btn2 ${activeButton === "Quiz" ? "Active-Btn2" : ""
              }`}
            onClick={() => setActiveButton("Quiz")}
          >
            Quiz
          </button>
        </div>
      </div>

      <div className="quiz-detail-main-div">

        {/* black div */}
        <div className="question-black-div">

          <ProgressBar now={30} className="custom-progress-bar-for-quiz" />
          <div className="one-slash-ten-div">
            <p className="one-ten-number">1</p>
            <p className="slash-bar">/</p>
            <p className="ten-number-with-color">10</p>
          </div>
          <div className="question-select-div">
            <p className="question-text-quiz">Who invented the first cryptocurrency?</p>
            <p className="select-correct-answer">Select the correct answer</p>
          </div>
        </div>

        {/* <div
          className={`option-grey-div ${isClicked ? 'clicked' : ''}`}
          onClick={handleClick}
        >
          <img
            src={`/Images/Quiz/${isClicked ? 'QuizIconClicked.svg' : 'OptionIcon.svg'}`}
            alt=""
            className="option-icon-radiobutton"
          />
          <p className={`option-text ${isClicked ? 'clicked-text' : ''}`}>Elon Musk</p>
        </div> */}

        {options.map((option) => (
          <div
            key={option.id}
            className={`option-grey-div ${selectedOption === option.id ? 'clicked' : ''}`}
            onClick={() => handleOptionClick(option.id)}
          >
            <img
              src={`/Images/Quiz/${selectedOption === option.id ? 'QuizIconClicked.svg' : 'OptionIcon.svg'}`}
              alt=""
              className="option-icon-radiobutton"
            />
            <p className={`option-text ${selectedOption === option.id ? 'clicked-text' : ''}`}>
              {option.text}
            </p>
          </div>
        ))}


<button className="next-button-quiz-page" onClick={openQuizPassModal}>
  Next
</button>
{/* <button className="next-button-quiz-page" onClick={openQuizFailModal}>
  Next
</button> */}

      </div>

      {/* <div className="quiz-profile-main">
        <div className="see-all">
            <h2>Lorem Ispum</h2>
            <p>SEE ALL</p>
        </div>
        <div className="profile-bottom-main-quiz">
       <div className="aaron-profile">
        <div className="profile-image-text">
        <img src="/Images/aron.svg"/>
        <div className="ramsdala">
            <h2>Aaron Ramsdale</h2>
            <p>3 weeks ago</p>
        </div>
        </div>
        <p className="lorem-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
       </div>

       <div className="aaron-profile">
        <div className="profile-image-text">
        <img src="/Images/aron.svg"/>
        <div className="ramsdala">
            <h2>Aaron Ramsdale</h2>
            <p>3 weeks ago</p>
        </div>
        </div>
        <p className="lorem-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
       </div>

       <div className="aaron-profile">
        <div className="profile-image-text">
        <img src="/Images/aron.svg"/>
        <div className="ramsdala">
            <h2>Aaron Ramsdale</h2>
            <p>3 weeks ago</p>
        </div>
        </div>
        <p className="lorem-para">Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s.</p>
       </div>
       </div>
       <div className="collect-btn-quiz">
        <button>Collect</button>
       </div>
      </div> */}
      <Navbar />
      <QuizPass show={showQuizPassModal} handleClose={closeQuizPassModal} />
      <QuizFail show={showQuizFailModal} handleClose={closeQuizFailModal} />
    </div>
  );
};

export default Quiz;
