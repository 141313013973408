import React, { useState, useEffect } from "react";
import "./ProfileMain.css";
import Navbar from "../../Components/Navbar/Navbar";
import VoyagerModal from "../../Components/Modal/VoyagerModal";
import VoyagerSecModal from "../../Components/Modal/VoyagerSecModal";
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import { ProgressBar } from "react-bootstrap";
import { get_Profiledata } from "../../api/get_Profiledata";
import ScreenLoader from "../../Components/ScreenLoader/ScreenLoader";
const ProfileMain = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();   
  const [showModal, setShowModal] = useState(false);
  const [showSecondModal, setSecondShowModal] = useState(false);
  const [isLoading, setIsloading] = useState(true);
  const [created_Date, setDate] = useState();
  const [profileData, setprofileData] = useState();
  const [hasNullLink, setHasNullLink] = useState(false);
  useEffect(() => {
    async function getProfileData() {
      // setIsloading(true);
      const userData = await get_Profiledata();
      console.log("userData________________");
      console.log(userData);
      setprofileData(userData);
      const createdDate = formatMonthYear(userData?.profile?.createdAt);
      setDate(createdDate);
      setIsloading(false);

      const checkForNullLinks = () => {
        const {
          XLink,
          discordLink,
          facebookLink,
          instagramLink,
          tiktokLink,
          youtubeLink,
        } = userData?.profile;

        return (
          XLink === null ||
          discordLink === null ||
          facebookLink === null ||
          instagramLink === null ||
          tiktokLink === null ||
          youtubeLink === null
        );
      };
      const state = checkForNullLinks();
      setHasNullLink(state);
      console.log(hasNullLink);
    }

    getProfileData();
  }, []);

  const formatMonthYear = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString("en-US", { month: "short", year: "numeric" });
  };

  const openVoyagerModal = () => {
    setShowModal(true);
  };

  const closeVoyagerModal = () => {
    setShowModal(false);
  };
  const openVoyagerSecModal = () => {
    setSecondShowModal(true);
  };

  const closeVoyagerSecModal = () => {
    setSecondShowModal(false);
  };

  const minnigBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge2.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge3.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge5.svg",
      progress: 10,
      text: "0/1",
    },
  ];

  const taskBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge6.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge5.svg",
      progress: 10,
      text: "0/1",
    },
  ];

  const streakBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge3.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge4.svg",
      progress: 10,
      text: "0/1",
    },
  ];
  const mysteryBadgeData = [
    {
      imgsource: "/Images/Social Profile/RecentBadge1.svg",
      progress: 10,
      text: "0/1",
    },
    {
      imgsource: "/Images/Social Profile/RecentBadge6.svg",
      progress: 10,
      text: "0/1",
    },
  ];
  const handleClick = (url) => {
    window.open(url, "_blank", "noopener,noreferrer");
  };

  return (
    <>
      {isLoading ? (
        // <div className="loading-container">
        //   <div className="spinner"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <div className="AllMain">
          <div className="topImage">
            <img
              src={
                profileData?.profile?.coverPicture
                  ? profileData.profile.coverPicture
                  : "/Images/ProfileBgimg.png"
              }
              alt="ProfileActor"
              className="Profilebg"
            />
          </div>

          <div className="Main">
            <div className="maintop">
              <div className="ProfilePicMainDiv">
                <div className="ImagePostionDiv">
                  <div>
                    <img
                      src={
                        profileData?.profile?.profilePicture
                          ? profileData.profile.profilePicture
                          : "/Images/ProfileActor.png"
                      }
                      alt="ProfileActor"
                      className="Profile-Image"
                    />
                  </div>
                  <div className="InnerStarPosition">
                    <img src="/Images/Staricon.svg" alt="ProfileActor" />
                  </div>
                </div>

                <div className="ProfilePic-Div2">
                  <div className="imagealignbottom">
                    <div className="NameTotal">
                      <div className="NameDiv">
                        <p className="AccountName">
                          {profileData?.profile?.displayName
                            ? profileData?.profile?.displayName?.split(" ")[0]
                            : profileData?.profile?.name}
                        </p>
                        <div>
                          {profileData?.profile?.emailVerified ? (
                            <img src="/Images/Verified.svg" alt="Insta" />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div>
                        <p className="NameProfile">
                          @{profileData?.profile?.telegramUsername}
                        </p>
                      </div>
                    </div>

                    <div className="iconsbutton">
                      <div onClick={() => navigate("/EditProfile")}>
                        <img src="/Images/Edit.svg" alt="Edit" />
                      </div>
                      <div onClick={() => navigate("/AccountSetting")}>
                        <img src="/Images/Setting.svg" alt="Setting" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="MainInner">
              <div className="MainInner1">
                <div className="Innerdivtext">
                  <p className="CryptoText">{profileData?.profile?.bio}</p>
                  <p className="CryptoText1">{t('Member Since:')} {created_Date}</p>
                  <p className="TotalText">{t('Total team value:')} $0</p>
                </div>
                <div className="btnDiv">
                  {/* <button className='FollowBtn'>Follow</button>
                <button className='Messagebtn'>Message</button> */}
                  <div className="iconDiv">
                    <img
                      src="/Images/Social Profile/Insta.svg"
                      onClick={() =>
                        profileData?.profile?.instagramLink &&
                        handleClick(
                          `${profileData?.profile?.instagramLink}`
                        )
                      }
                      style={{
                        cursor: "pointer",
                        opacity: profileData?.profile?.instagramLink ? 1 : 0.5,
                      }}
                      alt="Insta"
                    />
                    <img
                      src="/Images/Social Profile/X.svg"
                      onClick={() =>
                        profileData?.profile?.XLink &&
                        handleClick(
                          `${profileData?.profile?.XLink}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.XLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.XLink ? 1 : 0.5,
                      }}
                      alt="Twitter"
                    />
                    <img
                      src="/Images/Social Profile/Reddit.svg"
                      onClick={() =>
                        profileData?.profile?.discordLink &&
                        handleClick(
                          `${profileData?.profile?.discordLink}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.discordLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.discordLink ? 1 : 0.5,
                      }}
                      alt="Git"
                    />
                    <img
                      src="/Images/Social Profile/Telegram.svg"
                      onClick={() =>
                        profileData?.profile?.telegramUsername &&
                        handleClick(
                          `${profileData?.profile?.telegramUsername}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.telegramUsername
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.telegramUsername
                          ? 1
                          : 0.5,
                      }}
                      alt="Telegram "
                    />

                    <img
                      src="/Images/Social Profile/Youtube.svg"
                      alt="YouTube"
                      onClick={() =>
                        profileData?.profile?.youtubeLink &&
                        handleClick(
                          `${profileData?.profile?.youtubeLink}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.youtubeLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.youtubeLink ? 1 : 0.5,
                      }}
                    />
                    <img
                      src="/Images/Social Profile/Linkedin.svg"
                      alt="LinkedIn"
                      onClick={() =>
                        profileData?.profile?.linkedinLink &&
                        handleClick(
                          `${profileData?.profile?.linkedinLink}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.linkedinLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.linkedinLink ? 1 : 0.5,
                      }}
                    />
                    <img
                      src="/Images/Social Profile/Facebook.svg"
                      onClick={() =>
                        profileData?.profile?.facebookLink &&
                        handleClick(
                          `${profileData?.profile?.facebookLink}`
                        )
                      }
                      style={{
                        cursor: profileData?.profile?.facebookLink
                          ? "pointer"
                          : "default",
                        opacity: profileData?.profile?.facebookLink ? 1 : 0.5,
                      }}
                      alt="Facebook"
                    />
                  </div>
                </div>
                <div className="AccountDiv">
                  <div className="AccountData">
                    <p className="AccountDatatxt1">xMAG</p>
                    <p className="AccountDatatxt2">{profileData?.xMag}</p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData">
                    <p className="AccountDatatxt1">{t('Referrals')}</p>
                    <p className="AccountDatatxt2">{profileData?.referrals}</p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData">
                    <p className="AccountDatatxt1">{t('Team')}</p>
                    <p className="AccountDatatxt2">{profileData?.Team}</p>
                  </div>
                  <img src="/Images/LinearBorder.svg" alt="LinearBorder" />
                  <div className="AccountData">
                    <p className="AccountDatatxt1">{t('Followers')}</p>
                    <p className="AccountDatatxt2">{profileData?.followers}</p>
                  </div>
                </div>
              </div>

              <div className="Main-Recent-Earned-Div-Bottom">
                <p className="recent-earned-text-new">{t('Recent Earned Badges')}</p>

                <div className="recent-badges-bottom-inner-div">
                  <p className="minning-text-recent-badge">{t('Mining')}</p>
                  <div className="minning-text-recent-badge-div">
                    {minnigBadgeData?.map((minningData, index) => (
                      <div className="recent-badge-img-and-progressbar">
                        <div >
                          <img
                            src={minningData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent">
                          <ProgressBar
                            now={minningData.progress}
                            className="custom-progress-bar-recent"
                          />
                          <p className="progress-bar-and-text-recent-1">
                            {minningData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div">
                  <p className="minning-text-recent-badge">{t('Tasks')}</p>
                  <div className="minning-text-recent-badge-div">
                    {taskBadgeData?.map((taskData, index) => (
                      <div className="recent-badge-img-and-progressbar">
                        <div>
                          <img
                            src={taskData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent">
                          <ProgressBar
                            now={taskData.progress}
                            className="custom-progress-bar-recent"
                          />
                          <p className="progress-bar-and-text-recent-1">
                            {taskData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div">
                  <p className="minning-text-recent-badge">{t('Streaks')}</p>
                  <div className="minning-text-recent-badge-div">
                    {streakBadgeData?.map((streakData, index) => (
                      <div className="recent-badge-img-and-progressbar">
                        <div>
                          <img
                            src={streakData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent">
                          <ProgressBar
                            now={streakData.progress}
                            className="custom-progress-bar-recent"
                          />
                          <p className="progress-bar-and-text-recent-1">
                            {streakData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>

                <div className="recent-badges-bottom-inner-div">
                  <p className="minning-text-recent-badge">{t('Mystery Packs')}</p>
                  <div className="minning-text-recent-badge-div">
                    {mysteryBadgeData?.map((mysteryData, index) => (
                      <div className="recent-badge-img-and-progressbar">
                        <div>
                          <img
                            src={mysteryData.imgsource}
                            alt=""
                            style={{
                              paddingLeft: "3.5px",
                              paddingRight: "3.5px",
                            }}
                          />
                        </div>
                        <div className="progress-bar-and-text-recent">
                          <ProgressBar
                            now={mysteryData.progress}
                            className="custom-progress-bar-recent"
                          />
                          <p className="progress-bar-and-text-recent-1">
                            {mysteryData.text}
                          </p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>

              {/* <div className='mainbottom-container' >
            <div className='recentandiconsdiv'>
              <div className='recenttextdiv'>
                <p className='recenttext'>
                  Recent Earned Badges
                </p>
                <p className='Seetext'>SEE ALL</p>
              </div>
              <div className='allicons'>
                <img src="/Images/RecentImg1.svg" alt="RecentImg1" onClick={openVoyagerModal} />
                <img src="/Images/RecentImg2.svg" alt="RecentImg2" onClick={openVoyagerSecModal} />
                <img src="/Images/RecentImg3.svg" alt="RecentImg3" />
                <img src="/Images/RecentImg4.svg" alt="RecentImg4" />
                <img src="/Images/RecentImg5.svg" alt="RecentImg5" />
                <img src="/Images/RecentImg6.svg" alt="RecentImg6" />
              </div>
            </div>

            <div className='botooomcontainer'>



              <div className='recenttextdiv'>
                  <p className='recenttext'>
                    Launchpad Participation
                  </p>
                  <p className='Seetext'>SEE ALL</p>
                </div> 


              <div className='card-container'>
                <div className='nft-viewall-div'>
                  <p className='Nft-text-style'>NFTS</p>
                  <p className='viewall-text-style'>View all</p>
                </div>

                <div className='ThreeCard-Row'>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card4Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>Doodles</p>
                    </div>
                  </div>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card5Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>Captain & Co...</p>
                    </div>
                  </div>
                  <div className='Card2'>
                    <div>
                      <img src="/Images/Card6Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1-new'>Project</p>
                      <p className='Card-Content2-new'>ChronoForge</p>
                    </div>
                  </div>
                </div>


                <div className='launchpad-partication-div'>
                  <p className='launchpad-text-style'>Launchpad Participation</p>
                  <p className='seeall-1-text-style'>SEE ALL</p>
                </div>

                <div className='ThreeCard-Row'>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card1Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>EOS</p>
                    </div>
                  </div>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card2Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>Illuvium ILV</p>
                    </div>
                  </div>
                  <div className='Card'>
                    <div>
                      <img src="/Images/Card3Pic.svg" alt="Card1Pic" />
                    </div>
                    <div className='Card-Content'>
                      <p className='Card-Content1'>Project</p>
                      <p className='Card-Content2'>NEAR Protocol</p>
                    </div>
                  </div>
                </div>

              </div>
            </div>


          </div> */}
            </div>
          </div>

          <Navbar />

          <VoyagerModal show={showModal} handleClose={closeVoyagerModal} />
          <VoyagerSecModal
            show={showSecondModal}
            handleClose={closeVoyagerSecModal}
          />
        </div>
      )}
    </>
  );
};

export default ProfileMain;
