import React, { useEffect } from 'react';
import './AlertOk.css';

const AlertOk = ({ show, handleClose, message }) => {
    useEffect(() => {
        if (show) {
            const timer = setTimeout(() => {
                handleClose();
            }, 1000);
            return () => clearTimeout(timer);
        }
    }, [show, handleClose]);

    return (
        <div className={`alertok-modal ${show ? 'show' : ''}`} onClick={handleClose}>
            <div className='alert-ok-white-div'>
                <div className='alertok-content'>
                    <div className='text-center-alertok'>
                        <div>
                            <img src="/Images/Alert/ok.svg" alt='' />
                        </div>
                        <p className='message-alert'>{message}</p>
                    </div>
                </div>
            </div>
        </div>
    );
};
export default AlertOk;