import React from "react";
import { useNavigate } from "react-router-dom";
import "./UniversityPlatform.css";
import { useTranslation } from 'react-i18next';
const UniversityPlatform = () => {
  const { t }=useTranslation();
  const navigate = useNavigate();
  return (
    <div className="main-platform">
      <div className="platform-content">
        <div className="platform-image">
          
          <img src="/Images/onboardingscreen3.webp" alt=""/>
        </div>
        <div className="platform-heading">
          <h2>{t('The leading platform for crypto university')}</h2>
        </div>
        <div className="platform-text">
          <p>
          {t('The premier platform for education and resources in cryptocurrency and blockchain technology')}
          </p>
        </div>
        <div className="platform-bottom-button">
          <button className="platform-btn" onClick={() => navigate("/TradingPlatform")}>
            {t('Next')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default UniversityPlatform;
