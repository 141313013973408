

import React from 'react';
import { Modal } from 'react-bootstrap';
import './VoyagerSecModal.css'
import ProgressBar from 'react-bootstrap/ProgressBar';
import { useTranslation } from 'react-i18next';
const VoyagerSecModal = ({ show, handleClose }) => {
    const { t } = useTranslation();
    const now = 60;

    return (
        <div>

            <Modal className="voyager-modal" show={show} onHide={handleClose} centered>
                <Modal.Body className='VerifyModal'>
                <div className='Cross-Icon-VoyagerSecond'>
                        <p></p>
                        <img src="/Images/CrossClose.svg" alt="Verify Ok" className='Cross-Close' onClick={handleClose} />
                    </div>

                    <div className="text-centersss">

                        {/* <img src="/Images/Vogayer.svg" alt="Voyager" className='ImageTops' /> */}
                        <img src="/Images/Modal/MagTaycon.svg" alt="MagTaycon" className='ImageTops' />

                        <p className='VoyagerHeading' >
                            MAG TYCOON
                        </p>

                        <p className='VoyagerInner'>{t('Earn the Tycoon badge by buying 10,000 $MAG Tokens!')}</p>
                        <div className='progress-text-div'>
                            <ProgressBar
                                className="custom-progress-bar"
                                now={now}
                                label={`${now}%`}
                                visuallyHidden
                            />
                            <p className='zeroone-text'>0/1</p>
                        </div>
                        <div>
                            <button className='close-btn' onClick={handleClose}>
                                {t('Close')}
                            </button>
                        </div>


                    </div>
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default VoyagerSecModal;

