import React from "react";
import { Drawer } from "@mui/material";
import "./commingSoonModel.css";
import { useTranslation } from 'react-i18next';
const CommingSoonModel = ({ open, onClose }) => {
  const { t } = useTranslation();
  return (
    <Drawer anchor="bottom" open={open} onClose={onClose}>
      <div className="usdtModel_Main">
        {/*  */}
        <div className="usdtModel_Line_Top">
          <div></div>
        </div>

        {/*  */}
        <div className="CH_Comming-Soon_Section Model_Comming_Soon_Section">
          <img src="/Images/CommisionHistory/Cooming_Soon_Image.png" alt="" />
          <p>{t('Coming Soon')}</p>
        </div>
      </div>
    </Drawer>
  );
};

export default CommingSoonModel;
