import React from "react";
import { Modal } from "react-bootstrap";
import "./ConversionModel.css";
import { useNavigate } from "react-router-dom";

const ConversilaModel = ({ show, handleClose }) => {
  const navigate = useNavigate();
  return (
    <div>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        dialogClassName="verify-modal-dialog"
      >
        <Modal.Body className="verify-modal-body">
          <div className="Top-Img-Cross">
            <p></p>
            <img
              src="/Images/CrossClose.svg"
              alt="Verify Ok"
              className="Cross-Close"
              onClick={handleClose}
            />
          </div>
          <div className="verify-modal-content text-centers">
            <img
              src="/Images/ModalImg.svg"
              className="ModelImage"
              alt="Verify Ok"
            />
            <p className="verify-modal-text">Conversion Successfull</p>
            <p className="conversion_final_text">
              You have successfully confirm your MAG Competition Conversion
            </p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default ConversilaModel;
