import React from "react";
import { useNavigate } from "react-router-dom";
import "./TradingPlatform.css";
import { useTranslation } from 'react-i18next';
const TradingPlatform = () => {
  const { t } =useTranslation();
  const navigate = useNavigate();
  return (
    <div className="main-platform">
      <div className="platform-content">
        <div className="platform-image">
          
          <img src="/Images/onboardingscreen2.webp" alt=""/>
        </div>
        <div className="platform-heading1">
          <h2>{t('The leading platform for crypto Trading Bot')}</h2>
        </div>
        <div className="platform-text1">
          <p>
          {t('An advanced intelligent bots platform for maximizing profits and execute trades with precision and efficiency')}
          </p>
        </div>
        <div className="platform-bottom-button">
          <button className="platform-btn" onClick={() => navigate("/Profile")}>
            {t('Register')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default TradingPlatform;
