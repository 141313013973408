import React, { useState } from "react";
import "./VideosDetail.css";
import Navbar from "../../Components/Navbar/Navbar";
import { useNavigate } from "react-router-dom";

const VideosDetail = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("Lessons");

  const quiz = () => {
    setActiveButton("Quiz");
    navigate("/Quiz");
  };
  const lesson = () => {
    setActiveButton("Lessons");
    navigate("/VideosDetail");
  };
  const [isExpanded, setIsExpanded] = useState(false);


  const toggleReadMore = () => {
    setIsExpanded(!isExpanded);
  };
  return (
    <div>
      <div className="Main-Div-With-Image">
        <div className="MainTop-IconDiv">
          <img
            src="/Images/Backicon.svg"
            alt="BackIcon"
            onClick={() => navigate(-1)}
          />
          <div className="Two-Icons-Main">
            <div className="HeartMain">
              <img
                src="/Images/CourseVideo/DownloadMain.svg"
                alt="DownloadMain"
              />
            </div>

            <div className="HeartMain">
              <img src="/Images/CourseVideo/HeartMain.svg"
                alt="HeartMain" />
            </div>
          </div>
        </div>
      </div>

      <div className="VideosDetail-Main-Course-Video">
        <div className="VideosDetail-Teacher-Detail-Div">
          <p className="VideosDetail-Cryto-Mastery-text">Crypto Mastery</p>
          <div className="VideosDetail-Crypto-Line">
            <img
              src="/Images/CourseVideo/teacherprofile.svg"
              alt="teacherprofile"
            />
            <p className="VideosDetail-Teacher-Name-Text">Livia Donin</p>
            <div className="VideosDetail-dot-circle"></div>
            <p className="VideosDetail-crypto-Sec-text">Crypto</p>
          </div>
        </div>

        <div>
          <p className="VideosDetail-Read-More-Para">
            {isExpanded
              ? `Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.`
              : `Morem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. Morbi convallis convallis diam sit amet lacinia. Aliquam in elementum tellus.`}
            <span
              className="VideosDetail-Read-More-Bold"

              onClick={toggleReadMore}
            >
              {isExpanded ? ' Read Less' : ' Read More...'}
            </span>
          </p>
        </div>

        {/* <div className="VideosDetail-ThreeButtons-Div">
          <div className="VideosDetail-ThreeButtons-Div-inner">
            <button
              className={`Lessons-Btn ${activeButton === "Lessons" ? "Active-Btn" : ""
                }`}
              onClick={lesson}
            >
              Lessons
            </button>
            <button
              className={`Overview-Btn ${activeButton === "Overview" ? "Active-Btn" : ""
                }`}
              onClick={() => setActiveButton("Overview")}
            >
              Overview
            </button>
            <button
              className={`Quiz-Btn ${activeButton === "Quiz" ? "Active-Btn" : ""
                }`}
              onClick={quiz}
            >
              Quiz
            </button>
          </div>
        </div> */}

        <div className="VideosDetail-Videos-Div">
          <p className="lessons-text">Lessons</p>
          {/* First Video */}
          <div className="VideosDetail-Video-Click">
            <div className="VideosDetail-Video-Click-First">
              <p className="VideosDetail-Video-Title">
                1. Introduction to Crypto
              </p>
              <div className="VideosDetail-Video-Duration">
                <img src="/Images/CourseVideo/video.svg" alt="video" />
                <p className="VideosDetail-Video-Duration-Text">
                  1 video | 20 min
                </p>
              </div>
            </div>
            <div>
              <img
                src="/Images/CourseVideo/StartVideo.svg"
                alt="StartVideo"
                onClick={() =>
                  navigate("/CourseCompleted")
                }
              />
            </div>
          </div>
          {/* Second Video */}
          <div className="VideosDetail-Video-Click">
            <div className="VideosDetail-Video-Click-First">
              <p className="VideosDetail-Video-Title">
                1. Introduction to Crypto
              </p>
              <div className="VideosDetail-Video-Duration">
                <img src="/Images/CourseVideo/video.svg" alt="video" />
                <p className="VideosDetail-Video-Duration-Text">
                  1 video | 20 min
                </p>
              </div>
            </div>
            <div>
              <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
            </div>
          </div>
          {/* Third Video */}
          <div className="VideosDetail-Video-Click">
            <div className="VideosDetail-Video-Click-First">
              <p className="VideosDetail-Video-Title">
                1. Introduction to Crypto
              </p>
              <div className="VideosDetail-Video-Duration">
                <img src="/Images/CourseVideo/video.svg" alt="video" />
                <p className="VideosDetail-Video-Duration-Text">
                  1 video | 20 min
                </p>
              </div>
            </div>
            <div>
              <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
            </div>
          </div>
          {/* Fourth Video */}
          <div className="VideosDetail-Video-Click">
            <div className="VideosDetail-Video-Click-First">
              <p className="VideosDetail-Video-Title">
                1. Introduction to Crypto
              </p>
              <div className="VideosDetail-Video-Duration">
                <img src="/Images/CourseVideo/video.svg" alt="video" />
                <p className="VideosDetail-Video-Duration-Text">
                  1 video | 20 min
                </p>
              </div>
            </div>
            <div>
              <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
            </div>
          </div>
          {/* Fifth Video */}
          <div className="VideosDetail-Video-Click">
            <div className="VideosDetail-Video-Click-First">
              <p className="VideosDetail-Video-Title">
                1. Introduction to Crypto
              </p>
              <div className="VideosDetail-Video-Duration">
                <img src="/Images/CourseVideo/video.svg" alt="video" />
                <p className="VideosDetail-Video-Duration-Text">
                  1 video | 20 min
                </p>
              </div>
            </div>
            <div>
              <img src="/Images/CourseVideo/StartVideo.svg" alt="StartVideo" />
            </div>
          </div>
        </div>

        <Navbar />
      </div>
    </div>
  );
};

export default VideosDetail;
