import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "./ConnectWallet.css";
import Navbar from "../../Components/Navbar/Navbar";

const ConnectWallet = () => {
  const navigate = useNavigate();

  const [openLinkWallet, setOpenLinkWallet] = useState(false);
  const openWallet = () => {
    setOpenLinkWallet(!openLinkWallet);
  };
  return (
    <div className="ConnectWallet-Main">
      <div className="ConnectWallet-arrow">
        <img src="/Images/arrowref.svg" alt="" onClick={() => navigate(-1)} />
        <p className="ConnectWallet-arrowtext">Connect Wallet</p>
        <img src="/Images/arrowref2.svg" alt="" />
      </div>
      <div className="choose-main-div-ConnectWallet" onClick={openWallet}>
        <p className="choose-main-text-ConnectWallet">
          Choose Your Main Wallet
        </p>
        <img
          src="/Images/AccountSetting/ConnectWalletArrow.svg"
          alt=""
          style={{
            transition: "transform 0.3s ease",
            transform: openLinkWallet ? "rotate(180deg)" : "rotate(0deg)",
          }}
        />
      </div>
      {openLinkWallet && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
            height: "100%",
          }}
        >
          <div>
            <div className="red-attention-div">
              <p className="red-attention-div-text">
                Attention: Once you have selected a main wallet this operation
                can't be undone. The wallet will always be associated to your
                account will be used to send you prizes and rewards.
              </p>
            </div>
            <div className="link-textand-input-div">
              <p className="link-text-style-connetwallet">Link TON Wallet</p>
              <input className="link-input-style-connetwallet" />
            </div>
          </div>

          <div>
            <button className="ConnectTON-button-style2">Connect TON</button>
          </div>
        </div>
      )}
      {/* <Navbar /> */}
    </div>
  );
};

export default ConnectWallet;
