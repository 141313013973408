import React, { useState } from 'react'
import './Competitions.css';
import { Box, Card, CardMedia, CardContent, Typography, Grid, } from '@mui/material';
import { ProgressBar } from 'react-bootstrap';
import Navbar from '../../Components/Navbar/Navbar'
const Competitions = () => {
    const [act, setAct] = useState("A");
    const handleclick = (active) => {
        setAct(active);
    }
    const cardData = [
        {
            image: '/Images/Competitions/card_2.png',
            title: 'Metaverse Avatar',
            value: '$100',
            count: '10/30'
        },
        {
            image: '/Images/Competitions/card_3.png',
            title: 'ThorBeast - Rare',
            value: '$250',
            count: '10/60'
        },
        {
            image: '/Images/Competitions/card_4.png',
            title: 'Cyber Truck - Epic',
            value: '$500',
            count: '10/100'
        },
        {
            image: '/Images/Competitions/card_5.png',
            title: 'MetaMansion - Epic',
            value: '$1000',
            count: '10/200'
        }
    ];
    const cardData1 = [// just for testing
       {
            image: '/Images/Competitions/card_3.png',
            title: 'ThorBeast - Rare',
            value: '$250',
            count: '10/60'
        },
        {
            image: '/Images/Competitions/card_4.png',
            title: 'Cyber Truck - Epic',
            value: '$500',
            count: '10/100'
        },
       
    ];

    return (
        <div className='Competitions_main'>
            <div className='Competitions_top'>
                <img src='/Images/Competitions/Prof.svg' alt='abc' />
                <div className='Competitions_top_inner'>
                    <div className='Competitions_top_inner_1'>
                        <img src='/Images/Competitions/fire.svg' alt='🔥..' />
                        <p className='Competitions_top_inner_1_text'>400</p>
                    </div>
                    <div className='Competitions_top_inner_1'>
                        <img src='/Images/Competitions/glow11.png' style={{ height: "19px", width: "19px" }} alt='...' />
                        <p className='Competitions_top_inner_1_text'>12,00</p>
                    </div>
                    <div className='Competitions_top_inner_1'>
                        <img src='/Images/Competitions/m.svg' alt='..' />
                        <p className='Competitions_top_inner_1_text'>103,000</p>
                    </div>
                    <img src='/Images/Competitions/notify.svg' alt='..' />
                </div>
            </div>



            <div className='Competitions_top___2'>
                <div className='Competitions_top___2_1'>
                    <div className='Competitions_top___2_1_inner'>
                        <p className='Competitions_top___2_1_inner_text'>Available Tickets</p>
                        <div className='Competitions_top___2_1_inner_inner'>
                            <img src='/Images/Competitions/glow22.png' style={{ height: "22px", width: "22px" }} alt='..' />
                            <h1 className='Competitions_top___2_1_inner_inner_text'>200</h1>
                        </div>
                    </div>
                    <button className='Competitions_top___2_1_button'>T&C’s</button>
                </div>

                <div className='Competitions_top___2_buttton_div'>
                    <button className='Competitions_top___2_buttton_div_btn1'>Get Tickets</button>
                    <button className='Competitions_top___2_buttton_div_btn2'>Tickets History</button>
                </div>

            </div>

            <div className='Competitions_top___3'>
                <button className='Competitions_top___3_btn1' style={{ color: act === "A" ? "#ffffff" : "#1E1E24", background: act === "A" ? "linear-gradient(180deg, #000000 0%, #323232 100%)" : "transparent" }} onClick={() => handleclick("A")}>Live</button>
                <button className='Competitions_top___3_btn1' style={{ color: act === "B" ? "#ffffff" : "#1E1E24", background: act === "B" ? "linear-gradient(180deg, #000000 0%, #323232 100%)" : "transparent" }} onClick={() => handleclick("B")}>Ended</button>
                <button className='Competitions_top___3_btn1' style={{ color: act === "C" ? "#ffffff" : "#1E1E24", background: act === "C" ? "linear-gradient(180deg, #000000 0%, #323232 100%)" : "transparent" }} onClick={() => handleclick("C")}>My Wins</button>
            </div>


            {act === "A" &&
                <Box className="card_____top">
                    {cardData.map((card, index) => (
                        <Card key={index} className='cards______card'>
                            <CardMedia
                                component="img"
                                image={card.image}
                                alt={card.title}
                                sx={{ width: "100%" }}
                            />
                            <CardContent className='card__cardcontent'>
                                <Typography className='card___text1'
                                 sx={{
                                    lineHeight: "24px",
                                    fontSize: "14px",
                                    "@media (max-width:340px)": {
                                      lineHeight: "20px",
                                      fontSize: "12px",
                                    }}}>
                                    {card.title}
                                </Typography>
                                <ProgressBar now={30} className="custom-progress-bar-for-quiz1" />
                                <Box className="content____inner">
                                    <Typography className='content____inner_text1'>Value: <span style={{ fontWeight: "600", color: "#1E1E24" }}>{card.value}</span></Typography>
                                    <div className='content____inner_1'>
                                        <img src='/Images/Competitions/glow11.png' style={{ height: "19px", width: "19px" }} alt='...' />
                                        <Typography className='content____inner_text2'>{card.count}</Typography>
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>

            }
            {act === "B" &&
                <Box className="card_____top">
                    {cardData.map((card, index) => (
                        <Card key={index} className='cards______card'>
                            <CardMedia
                                component="img"
                                image={card.image}
                                alt={card.title}
                                sx={{ width: "100%" }}
                            />
                            <CardContent className='card__cardcontent'>
                                <Typography className='card___text1'
                                 sx={{
                                    lineHeight: "24px",
                                    fontSize: "14px",
                                    "@media (max-width:340px)": {
                                      lineHeight: "20px",
                                      fontSize: "12px",
                                    }}}>
                                    {card.title}
                                </Typography>
                                <ProgressBar now={30} className="custom-progress-bar-for-quiz1" />
                                <Box className="content____inner">
                                    <Typography className='content____inner_text1'>Value: <span style={{ fontWeight: "600", color: "#1E1E24" }}>{card.value}</span></Typography>
                                    <div className='content____inner_1'>
                                        <img src='/Images/Competitions/glow11.png' style={{ height: "19px", width: "19px" }} alt='...' />
                                        <Typography className='content____inner_text2'>{card.count}</Typography>
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            }
              {act === "C" &&
                <Box className="card_____top">
                    {cardData1.map((card, index) => (
                        <Card key={index} className='cards______card'>
                            <CardMedia
                                component="img"
                                image={card.image}
                                alt={card.title}
                                sx={{ width: "100%" }}
                            />
                            <CardContent className='card__cardcontent'>
                                <Typography className='card___text1'  sx={{
                                    lineHeight: "24px",
                                    fontSize: "14px",
                                    "@media (max-width:340px)": {
                                      lineHeight: "20px",
                                      fontSize: "12px",
                                    }}}>
                                    {card.title}
                                </Typography>
                                <ProgressBar now={30} className="custom-progress-bar-for-quiz1" />
                                <Box className="content____inner">
                                    <Typography className='content____inner_text1'>Value: <span style={{ fontWeight: "600", color: "#1E1E24" }}>{card.value}</span></Typography>
                                    <div className='content____inner_1'>
                                        <img src='/Images/Competitions/glow11.png' style={{ height: "19px", width: "19px" }} alt='...' />
                                        <Typography className='content____inner_text2'>{card.count}</Typography>
                                    </div>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            }

            <Navbar/>
        </div>
    )
}

export default Competitions
