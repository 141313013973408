import React, { useState } from "react";
import { Drawer, styled } from "@mui/material";
import "./XmadModel.css";
import { useTranslation } from 'react-i18next';
const CustomCheckbox = styled("input")(({ checked }) => ({
  appearance: "none",
  width: "20px",
  height: "20px",
  borderRadius: "5px",
  backgroundColor: checked ? "#323232" : "transparent",
  border: "1.25px solid rgba(0, 0, 0, 0.16)",
  position: "relative",
  cursor: "pointer",
  "&::before": {
    content: checked ? `url("/Images/CommisionHistory/tick.svg")` : "''",
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    color: checked ? "white" : "transparent",
    fontSize: "12px",
  },
}));

const XmadModel = ({ open, onClose }) => {
  const { t } = useTranslation();
  const [checked, setChecked] = useState([
    false,
    false,
    false,
    false,
    false,
    false,
  ]);

  const handleCheckBoxes = (index) => {
    setChecked((prev) => {
      const updateCheckBoxes = [...prev];
      updateCheckBoxes[index] = !updateCheckBoxes[index];
      return updateCheckBoxes;
    });
  };

  return (
    <Drawer
      ModalProps={{
        sx: {
          backdropFilter: "blur(6px)",
        },
      }}
      PaperProps={{
        sx: {
          maxHeight: "70vh"
        },
      }}
      anchor="bottom"
      open={open} onClose={onClose}>
      <div className="usdtModel_Main">
        {/*  */}
        <div className="usdtModel_Line_Top">
          <div></div>
        </div>

        {/*  */}
        <div className="usdtModel_Center_Main">
          <div className="usdtModel_Center_Main_Body">
            <div className="usdtModel_Center_Body_Heading">{t('xMAG Filter')}</div>
            {/* All inputes are here */}
            {[
              "Quests",
              "Streaks",
              "Referrals",
              "Mining",
              "Wealth Academy",
              "Trading Bot",
            ].map((text, index) => (
              <div className="usdtModel_Center_Body_RadioBtn" key={index}>
                <label className="CheckBox_Input">
                  <CustomCheckbox
                    type="checkbox"
                    checked={checked[index]}
                    onChange={() => handleCheckBoxes(index)} // Toggle checked state on change
                  />
                </label>
                {t(text)}
              </div>
            ))}
          </div>
        </div>

        {/*  */}
        <div className="USDT_Model_Button_Main">
          <button className="usdt_Model_Close_Btn">{t('Confirm')}</button>
        </div>
      </div>
    </Drawer>
  );
};

export default XmadModel;
