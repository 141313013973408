import React, { useState } from "react";
import { LinearProgress, Box } from "@mui/material";
import { styled } from "@mui/system";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./ComptationDetail.css";

const CustomLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: "10px",
  borderRadius: "40px",
  backgroundColor: "rgba(188,191,204,0.3)",
  "& .MuiLinearProgress-bar": {
    borderRadius: "40px",
    backgroundColor: "#BCBFCC",
    width: "75px",
  },
}));

function ComptationDetail() {
  const [isWinnerAnnounced, setIsWinnerAnnounced] = useState(false); // Condition state

  // Example function to simulate condition change (you can trigger this from somewhere else)
  const announceWinner = () => {
    setIsWinnerAnnounced(true); // Set the condition to true to show the winner and hide buttons
  };
  const [showDetails, setShowDetails] = useState(false);

  // Function to handle click on "About the Prize" div
  const handleAboutClick = () => {
    setShowDetails((prev) => !prev); // Toggle the visibility
  };
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((oldProgress) => {
        if (oldProgress === 100) {
          return 0;
        }
        const diff = Math.random() * 10;
        return Math.min(oldProgress + diff, 100);
      });
    }, 500);

    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <>
      <div className="Comptation__DetailOVERAllCintainer">
        <div className="ProgresBarandBtNConTainerOverAll">
          <div className="ComptationDetai_LEftArow">
            <img src="/Images/LeftArow.svg" alt="Left Arrow" />
          </div>
          <div className="ComptationIMgTop">
            <img src="/Images/CompatationDetail.png" alt="Comptation Detail" />
          </div>

          <div className="ComptationBarBtnContainer">
            <div className="ComptationTextMAndMax_Container">
              <h2 className="ComptationH_TextM">M3 SUV 2025</h2>
              <h2 className="ComptationHMax_Text">Max per user: 30</h2>
            </div>
            <div className="Comptation__ProgresBar">
              <Box sx={{ width: "100%" }}>
                <CustomLinearProgress variant="determinate" value={60} />
              </Box>
            </div>
            <div className="ComptationContainer_PTextvalue">
              <p className="ComptationPtextValue">100</p>
              <p className="ComptationPtextValue">400 Tickets Remaining</p>
              <p className="ComptationPtextValue">500</p>
            </div>
            {/* <div className="ButonCompTionContainer">
              <button className="BUttOnComptationDetail">Enter Now</button>

              <button className="ButtOnGetTikt">Get Tickets</button>
            </div>
            <div className="Ella__WinnerTiktConatiner">
              <div className="Ella__CompetionWiner">
                <div className="EllaSvg_Alignmnt">
                  <img src="/Images/Ella.svg" />
                  <p className=" Ella__Text">Ella </p>
                </div>
                <div>
                  <h3 className="Competition__TExtWinner">
                    Competition Winner
                  </h3>
                </div>
              </div>
              <div className="DividerLiner"></div>
              <div className="Containera__TotalTIckts">
                <p className="ComptationTickets">Total Tickets: 500</p>
                <p className="SpanTicketValues ">Winning Ticket No. 3</p>
              </div>
            </div> */}
             <div>
      {/* Conditionally render buttons or the winner container */}
      {!isWinnerAnnounced ? (
        <div className="ButonCompTionContainer">
          <button className="BUttOnComptationDetail" onClick={announceWinner}>Enter Now</button>
          <button className="ButtOnGetTikt">Get Tickets</button>
        </div>
      ) : (
        <div className="Ella__WinnerTiktConatiner">
          <div className="Ella__CompetionWiner">
            <div className="EllaSvg_Alignmnt">
              <img src="/Images/Ella.svg" alt="Ella" />
              <p className="Ella__Text">Ella</p>
            </div>
            <div>
              <h3 className="Competition__TExtWinner">Competition Winner</h3>
            </div>
          </div>
          <div className="DividerLiner"></div>
          <div className="Containera__TotalTIckts">
            <p className="ComptationTickets">Total Tickets: 500</p>
            <p className="SpanTicketValues">Winning Ticket No. 3</p>
          </div>
        </div>
      )}

     
    </div>
 
          </div>
        </div>

        <div className="DRopDownANdTiktsContainerOverAll">
          <div
            className="DropDownContainer"
            onClick={handleAboutClick}
            style={{ cursor: "pointer" }}
          >
            <div className="ABoutTextANDMEnuContainer">
              <img src="/Images/MenuIcon.svg" alt="Menu Icon" />
              <h4 className="HtextAbout_The">About the Prize</h4>
            </div>
            {showDetails ? ( // Render the details only if showDetails is true
              <img src="/Images/UpliftArow.svg" alt="" />
            ) : ( 
              <img src="/Images/downArow.svg" alt=""/>
            )}
          </div>

          <div className="DividerLiner"></div>
          <div className="TiCketAndValueCOntainer">
            <p className="ComptationTickets">
              Total Tickets: <span className="SpanTicketValues">500</span>
            </p>
            <p className="ComptationTickets">
              Prize Value: <span className="SpanTicketValues">$1200</span>
            </p>
          </div>

          {showDetails && ( // Render the details only if showDetails is true
            <>
              <p className="Ptext__ChanceTo_win">
                Chance to win the The M3 SUV 2025! The M3 SUV 2025 is part of
                the MAG exclusive SUV collection. Only 500 Rare M3 SUV 2025’s
                will ever be available.
              </p>
            </>
          )}
        </div>

        <div className="MagnusCardsComptation__container">
          <h3 className="CompetitionParticipants_Text">
            Competition Participants
          </h3>
          {[
            "Cisse",
            "Hector Lowe",
            "Drake",
            "Ella",
            "Mary Jones",
            "Sarah Lowe",
          ].map((name, index) => (
            <div key={index} className="MgnusCOmptationCisseContainer">
              <p className="PTextCarsMagnus">
                {String(index + 1).padStart(2, "0")}
              </p>
              <img src="/Images/MagnusLogo.svg" alt="Magnus Logo" />
              <div className="CisseAndTicketContainer">
                <p className="CisseText">{name}</p>
                <div>
                  <p className="Tickets__P_Text">Tickets</p>
                  <p className="P__Text_ValueOfP">100</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default ComptationDetail;
