import React, { useState, useEffect } from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Avatar from "@mui/material/Avatar";
import Typographyspinner from "@mui/material/Typography";
import "./Table.css";
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { getref_tabledata } from "../../../api/getref_tabledata";
import ScreenLoader from "../../ScreenLoader/ScreenLoader";
// Sample data


const rows = [
  { id: 1, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 2, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 3, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 4, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 5, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 6, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 7, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 8, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 9, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 10, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 11, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
  { id: 12, user: "_andrea", team: 14, volume: "$2,000", commission: "$1,500" },
];

export default function CustomTable() {
  const [raffel, setRaffel] = useState();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getTableInfo() {
      setLoading(true);
      const token = sessionStorage.getItem("token");
      console.log(token);
      const tabledata = await getref_tabledata(token);
      console.log("show table data of raffel", tabledata);
      setRaffel(tabledata);
      setLoading(false);
    }
    getTableInfo();
  }, []);
  const { t } = useTranslation();
  return (
    <>
      {loading ? (
        // <div className="loading-containe-tabler">
        //   <div className="spinner-table"></div>
        // </div>
        <ScreenLoader />
      ) : (
        <TableContainer
          className="tablecont"
          component={Paper}
          sx={{ boxShadow: "none", borderRadius: "8px", marginTop: 2 }}
        >
          <Table
            //   sx={{ minWidth: 650 }}
            aria-label="custom table"
          >
            <TableHead>
              <TableRow className="tableheader">
                <TableCell className="tableheader-cell">#</TableCell>
                <TableCell className="tableheader-cell">{t('User')}</TableCell>
                <TableCell className="tableheader-cell" align="left">
                  {t('Team')}
                </TableCell>
                <TableCell className="tableheader-cell" align="left">
                  {t('Rank')}
                </TableCell>
                <TableCell className="tableheader-cell" align="left">
                  {t('Total XP')}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(raffel)
                ? raffel.map((item, index) => (
                    <TableRow
                      sx={{ borderBottom: "0.52px solid #BCBFCC33" }}
                      key={item?.id || index}
                    >
                      <TableCell
                        sx={{ width: "28px" }}
                        className="tablecell-ref"
                      >
                        {index + 1}
                      </TableCell>
                      <TableCell
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                          border: "none",
                          padding: "10px",
                        }}
                        align="left"
                        onClick={() => navigate("/Referralone")}
                      >
                        <Avatar
                          alt=""
                          src={item?.profilePicture}
                          sx={{ height: "16px", width: "16px" }}
                        />
                        <p className="tablecell-ref2">{item?.username}</p>
                      </TableCell>
                      <TableCell className="tablecell-ref3" align="left">
                        {item?.totalLevels}
                      </TableCell>
                      <TableCell className="tablecell-ref3" align="left">
                        {item?.Rank} {item?.level}
                      </TableCell>
                      <TableCell className="tablecell-ref3" align="left">
                        {item?.totalXp}
                      </TableCell>
                    </TableRow>
                  ))
                : ""}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </>
  );
}
