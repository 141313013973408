import React, { useState, useEffect } from 'react';
import './CustomAccordion.css';
import { getRank } from '../../api/getRank';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Grid,
  LinearProgress,
  Avatar
} from '@mui/material';
import { useNavigate } from "react-router-dom";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { styled } from '@mui/material/styles';


const LevelContainer = styled(Box)({
  border: '1px solid #1E1E2480',
  borderRadius: '8px',
  padding: '16px',

});

const LevelHeader = styled(Typography)({
  //   fontSize: '16px',
  //   fontWeight: 600,
  //   display: 'flex',
  //   alignItems: 'center',
});

const ProgressBox = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  borderRadius: '40px',

  //   marginTop: '10px',
});

const ProgressBar = styled(LinearProgress)({
  flexGrow: 1,
  //   marginLeft: '10px',
  //   marginRight: '10px',
  height: '10px',
  borderRadius: '40px',

});

const StatGrid = styled(Grid)({
  //   marginTop: '10px',
  //   textAlign: 'center',
});


const CustomAccordion = () => {
const navigate=useNavigate();

  const [isLoading, setIsloading] = useState(false);
  const [showRank, setShowRank] = useState([]);

  useEffect(() => {
    async function getRankings() {
      const token = sessionStorage.getItem("token");
      setIsloading(true);
      const data = await getRank(token);
      console.log(data, "this is data of Rank");
      setShowRank(data);
      setIsloading(false);
    }
    getRankings();
  }, []);





  return (
    <div style={{padding:"0px 24px 24px 24px"}}>
      <div className="quiz-arrow">
        <img src="/Images/arrowref.svg" alt="" onClick={() => navigate(-1)} />
        <p className="quiz-arrowtext">Rank Progression</p>
        <img src="/Images/arrowref2.svg" alt="" />
      </div>

      <div className='customaccordian-main-top-expanding'>
        <Accordion defaultExpanded className='accrodian-main-top'>
          <AccordionSummary
            expandIcon={<img src='/Images/CustomAccordion/exp.svg' alt="" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
            className='before_masla'
          >
            <LevelHeader className='broze-text-icon'>
              <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/accordian-icon.svg" />
              <p className=' Bronze'> Bronze</p>
            </LevelHeader>
          </AccordionSummary>
          <AccordionDetails className='expanding-for-gap-top'>
            {/* Bronze Level 1 */}
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/bronozelevel.svg" />
                  <p className=' Bronze-Level-1-text'>{showRank?.rank?.rank}</p>
                </LevelHeader>
                <div>
                  <img src='/Images/CustomAccordion/right.svg' alt=''/>
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">{showRank?.rank?.referrals}</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">{showRank?.rank?.tasksCompleted}</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">{showRank?.rank?.xpEarned}</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/bronozelevel.svg" />
                  <p className=' Bronze-Level-1-text'> Bronze Level 2</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/bronozelevel.svg" />
                  <p className=' Bronze-Level-1-text'> Bronze Level 3</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/bronozelevel.svg" />
                  <p className=' Bronze-Level-1-text'> Bronze Level 4</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>

          </AccordionDetails>
        </Accordion>
        {/* Accordion End */}

        {/* Accordion Start */}
        <Accordion defaultExpanded className='accrodian-main-top'>
          <AccordionSummary
            expandIcon={<img src='/Images/CustomAccordion/exp.svg' />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <LevelHeader className='broze-text-icon'>
              <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/silver.svg" />
              <p className=' Bronze'> Silver</p>
            </LevelHeader>
          </AccordionSummary>
          <AccordionDetails className='expanding-for-gap-top'>
            {/* Bronze Level 1 */}
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/silverlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Silver Level 1</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/silverlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Silver Level 2</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/silverlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Silver Level 3</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/silverlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Silver Level 4</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
          </AccordionDetails>
        </Accordion>
        {/* Accordion End */}
        {/* Accordion Start */}
        <Accordion defaultExpanded className='accrodian-main-top'>
          <AccordionSummary
            expandIcon={<img src='/Images/CustomAccordion/exp.svg' />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <LevelHeader className='broze-text-icon'>
              <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/gold.svg" />
              <p className=' Bronze'> Gold</p>
            </LevelHeader>
          </AccordionSummary>
          <AccordionDetails className='expanding-for-gap-top'>
            {/* Bronze Level 1 */}
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/goldlevel.svg" />
                  <p className=' Bronze-Level-1-text'>Gold Level 1</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/goldlevel.svg" />
                  <p className=' Bronze-Level-1-text'>Gold Level 2</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/goldlevel.svg" />
                  <p className=' Bronze-Level-1-text'>Gold Level 3</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/goldlevel.svg" />
                  <p className=' Bronze-Level-1-text'>Gold Level 4</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
          </AccordionDetails>
        </Accordion>
        {/* Accordion End */}
        {/* Accordion Start */}
        <Accordion defaultExpanded className='accrodian-main-top'>
          <AccordionSummary
            expandIcon={<img src='/Images/CustomAccordion/exp.svg' />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <LevelHeader className='broze-text-icon'>
              <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/platinium.svg" />
              <p className=' Bronze'> Platinum</p>
            </LevelHeader>
          </AccordionSummary>
          <AccordionDetails className='expanding-for-gap-top'>
            {/* Bronze Level 1 */}
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/platiniumlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Platinum Level 1</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/platiniumlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Platinum Level 2</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/platiniumlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Platinum Level 3</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/platiniumlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Platinum Level 4</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
          </AccordionDetails>
        </Accordion>
        {/* Accordion End */}
        {/* Accordion Start */}
        <Accordion defaultExpanded className='accrodian-main-top'>
          <AccordionSummary
            expandIcon={<img src='/Images/CustomAccordion/exp.svg' />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <LevelHeader className='broze-text-icon'>
              <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/diamond.svg" />
              <p className=' Bronze'> Diamond</p>
            </LevelHeader>
          </AccordionSummary>
          <AccordionDetails className='expanding-for-gap-top'>
            {/* Bronze Level 1 */}
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/diamondlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Diamond Level 1</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/diamondlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Diamond Level 2</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/diamondlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Diamond Level 3</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>
            <LevelContainer>
              <div className='for-broze-and-image-right-side'>
                <LevelHeader className='between-to-gap'>

                  <Avatar className='for-accordian-icon-image-width' src="/Images/CustomAccordion/diamondlevel.svg" />
                  <p className=' Bronze-Level-1-text'> Diamond Level 4</p>
                </LevelHeader>
                <div>
                  {/* <img src='/Images/CustomAccordion/right.svg'/> */}
                </div>
              </div>
              <ProgressBox className='progress-bar-main'>

                <ProgressBar sx={{
                  height: "10px",
                  borderRadius: 5,
                  // Color for the moving progress (filled part)
                  "& .MuiLinearProgress-bar": {
                    backgroundColor: "#BCBFCC", // Gradient for filled portion
                    borderRadius: 5,
                  },
                  // Color for the unfilled part of the bar
                  backgroundColor: "#BCBFCC4D",
                }} variant="determinate" value={50} /> {/* Adjust value */}

              </ProgressBox>
              <StatGrid container className='bottom-typo-main-text-head' >

                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">Referral</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">1</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo' >
                  <Typography className='tasks-completed-text-typo' variant="caption">Tasks Completed</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">5</Typography>
                </Grid>
                <div>
                  <img src='/Images/CustomAccordion/verticalline.svg' />
                </div>
                <Grid item className='text-head-main-typo'>
                  <Typography className='tasks-completed-text-typo' variant="caption">XP Earned</Typography>
                  <Typography className='tasks-completed-text-typo-numbers' variant="body2">4,999</Typography>
                </Grid>
              </StatGrid>
            </LevelContainer>

          </AccordionDetails>
        </Accordion>
      </div>
      {/* Accordion End */}
    </div>
  );
};

export default CustomAccordion;
