import { API_URL } from "../utils/client";

export const unfollow_User = async (id) => {
  try {
    const token = sessionStorage.getItem("token"); 
    console.log(token);
    const response = await fetch(`${API_URL}/profile/unfollow-user`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token}`, 
      },
      
      body: JSON.stringify({followedUserId:id}),
    });
    if (response.ok) {
      const data = await response.json();
      return data;
    } else {
      throw new Error("Failed to follow");
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
