import React, { useState } from "react";
import { useEffect } from "react";
import { Drawer } from "@mui/material";
import "./SelectProfileImage.css";
import { useTranslation } from 'react-i18next';
import { getCoverPicture } from "../../../api/getCoverPicture";

const SelectProfileImage = ({ open, onClose, setSelectedProfileImage }) => {
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [coverPictures, setCoverPictures] = useState();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  // const images = [
  //   { src: "/Images/Social Profile/Image-for-drawer-new1.svg" },
  //   { src: "/Images/Social Profile/Image-for-drawer-new2.svg" },
  //   { src: "/Images/Social Profile/Image-for-drawer-new3.svg" },
  //   { src: "/Images/Social Profile/Image-for-drawer-new4.svg" },
  // ];

  const handleImageClick = (index) => {
    setSelectedImageIndex(index);
  };

  const handleConfirm = () => {
    if (selectedImageIndex !== null) {
      setSelectedProfileImage(coverPictures[selectedImageIndex]);
      onClose();
    }
  };

  useEffect(() => {
    async function getProfile() {
      setLoading(true);
      const data = await getCoverPicture();
      setCoverPictures(data?.coverPictures);
      console.log(data, "cover Picture of profile");
      setLoading(false);
    }
    getProfile();
  }, []);

  return (
    <>
      <Drawer
        anchor="bottom"
        ModalProps={{
          sx: {
            backdropFilter: "blur(6px)",
          },
        }}
        PaperProps={{
          sx: {
            maxHeight: "70vh",
          },
        }}
        open={open}
        onClose={onClose}
      >
        {loading ? (
          <div className="loading-container-profile">
            <div className="spinner"></div>
          </div>
        ) : (
          <div className="SelectProfileImage-Main-Div">
            <p className="Select-Profile-Image-text">{t('Select Profile Banner')}</p>
            <div className="changeimage-main-div">
              {coverPictures?.map((items, index) => {
                console.log(items, "this is the items of the data");
                return (
                  <img
                    key={index}
                    src={items}
                    alt=""
                    className={`image-for-drawer-setting ${
                      selectedImageIndex === index ? "active" : ""
                    }`}
                    onClick={() => handleImageClick(index)}
                  />
                );
              })}
            </div>
            {/* <div className='change-image-div2'>
                    <img src='/Images/Social Profile/PlusButton.svg' alt='' />
                </div> */}
            {/* <p className='Visit-Mag-text'>
                    Visit the <span className='Mag-text-Bold'>$MAG Store</span> to unlock premium profile images and stand out!
                </p> */}
            <button
              className="Confirm-Image-button-select-image"
              onClick={handleConfirm}
            >
              {t('Confirm Image')}
            </button>

            {/* <p className='Visit-Mag-Store-text'>
                Visit <span className='Visit-Mag-Store-text-bold'>MAG Store</span>
                </p> */}
          </div>
        )}
      </Drawer>
    </>
  );
};

export default SelectProfileImage;
