import React, { useRef, useState } from "react";
import "./Veification.css";
import { Box, TextField } from "@mui/material";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { verificationCode } from "../../api/verificationCode";
import VerifyModal from "../../Components/Modal/VerifyModal";
import Form from "react-bootstrap/Form";
import { useTranslation } from 'react-i18next';
import AlertOk from "../../Components/Modal/Alert/AlertOk";
import AlertNot from "../../Components/Modal/Alert/AlertNot"

const Veification = () => {
  const { t } = useTranslation(); 
  const { email } = useParams();
  const navigate = useNavigate();
  const inputRefs = [useRef(null), useRef(null), useRef(null), useRef(null)];
  const [saveChangesMessage, setSaveChangesMessage] = useState("");
  const [showSaveChanges, setShowSaveChanges] = useState(false);
  const [isLoading, setIsloading] = useState(false);
  const [code, setCode] = useState(["", "", "", ""]);

  const handleInputChange = (index, value) => {
    // Allow only alphanumeric characters and limit to one character
    const newValue = value.replace(/[^a-zA-Z0-9]/g, "").charAt(0);
    // Keep only alphanumeric characters and limit to one character
    const newValues = [...code];
    newValues[index] = newValue;
    setCode(newValues);

    // Move focus to the next input if the current input is filled
    if (newValue && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const handleKeyDown = (e, index) => {
    if (e.key === "ArrowLeft" && index > 0) {
      inputRefs[index - 1].current.focus();
    } else if (e.key === "ArrowRight" && index < inputRefs.length - 1) {
      inputRefs[index + 1].current.focus();
    }
  };

  const [showModal, setShowModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleVerifyClick = async () => {
    const token = sessionStorage.getItem("token");
    try {
      setIsloading(true);
      const verificationCodeString = code.join("");
      const data = await verificationCode(token, email, verificationCodeString);
      console.log("value of code as an variable", verificationCodeString)
      if (data) {
        setIsloading(false);
        console.log("Email verification successful", data);
        setShowModal(true);
      } else {
        setIsloading(false);
        setShowSaveChanges(true);
        setSaveChangesMessage("Otp is incorrect!");
        console.log("Please enter corect verification code");
      }
    } catch (error) {
      setIsloading(false);
      console.error("Error during email verification:", error);
    }
  };



  return (
    <>
      {isLoading ? (
        <div className="loading-container">
          <div className="spinner"></div>
        </div>
      ) : (
        <div className="MainVerifyy">
          <div className="Top-ArrowDivv">
            <img
              src="/Images/Backicon.svg"
              alt="BackIcon"
              onClick={() => navigate(-1)}
            />
          </div>
          <div className="verify-Identityy">
            <div>
              <img src="/Images/verifyok.svg" alt="Verify Ok" />
            </div>
            <p className="verify-headingg">{t('Verify your identity')}</p>
            <p className="verify-secheadingg">
              {t('4-Digit verification code has been sent on your email')}
            </p>
            
          </div>
          <div className="otpdiv">
            <Box display="flex" gap="13px" mx="auto" my={4}>
              {/* {[...Array(4)].map((_, index) => (
                <TextField
                  key={index}
                  variant="outlined"
                  inputRef={(el) => (inputRefs.current[index] = el)}
                  inputProps={{
                    maxLength: 1,
                    style: {
                      textAlign: "center",
                      fontSize: "24px",
                      padding: "0px",
                      width: "72px",
                      height: "72px",
                      borderRadius: "8px",
                      fontFamily: "Montserrat",
                      em: "28px",
                      fontWeight: "400",
                    },
                  }}
                  sx={{
                    "& .MuiOutlinedInput-root": {
                      "& fieldset": {
                        borderRadius: "8px",
                        border: "1px solid #BCBFCC",
                      },

                      "&.Mui-focused fieldset": {
                        borderRadius: "8px",
                        border: "1px solid #BCBFCC",
                      },
                    },
                  }}
                  value={code}
                  onKeyUp={(e) => handleKeyUp(e, index)}
                  onKeyDown={(e) => handleKeyDown(e, index)}
                  onChange={(e) => handleChange(e, index)}
                  onChange={(e) => setCode(e.target.value)}
                />
              ))} */}
              {code.map((value, index) => (
                <Form.Group className="email-div" key={index}>
                  <Form.Control
                    type="text"
                    inputmode="text"
                    className="otp-input"
                    value={value}
                    maxLength={1}
                    ref={inputRefs[index]}
                    onChange={(e) => handleInputChange(index, e.target.value)}
                    onKeyDown={(e) => handleKeyDown(e, index)}
                  />
                </Form.Group>
              ))}
              {/* {console.log("value of code", verificationCodeString)} */}
            </Box>
            <p className="request-text">
              {t('Didn’t receive the code?')}{" "}
              <span className="again-text">{t('Request again')}</span>
            </p>
          </div>

          <div className="button-divv">
            <button className="verify-buttonn" onClick={handleVerifyClick}>
              {t('Verify')}
            </button>
          </div>
          <AlertNot
            show={showSaveChanges}
            handleClose={() => setShowSaveChanges(false)}
            message={saveChangesMessage}
          />
          <VerifyModal show={showModal} handleClose={handleCloseModal} />
        </div>
      )}
    </>
  );
};

export default Veification;
