import React, { useState } from "react";
import "./RoadMap____Updates.css";
import ResponsiveRoadmap from "../../Components/ResponsiveRoad__map/ResponsiveRoadmap";
import { useTranslation } from 'react-i18next';
const RoadMap____Updates = () => {
  const { t } = useTranslation();
  // State to control visibility of the content
  const [isContentVisible, setIsContentVisible] = useState(true);
  const [icon, setIcon] = useState("/Images/magnas/arrowtop.svg"); // Initial icon

 


   const toggleContentVisibility = () => {
    setIsContentVisible((prev) => !prev);
    
   
    setIcon((prevIcon) =>
      prevIcon === "/Images/magnas/arrowtop.svg"
        ? "/Images/magnas/arrowright.svg" 
        : "/Images/magnas/arrowtop.svg"  
    );
  };

  const [Act, SetAct] = useState("B");

  const handleClick = (active) => {
    SetAct(active);

 
  };
  return (
    <div className="RoadMap____Updates_Main">
      <div className="RoadMap____Updates_Main_First1">
        <img src="/Images/magnas/backIcon.svg" alt="..." />
        <p className="Term_Main_Div_first_One_p1">{t('Updates')}</p>
        <img src="/Images/magnas/backIcon2.svg" alt="..." />
      </div>

      <div className="RoadMap____Updates_Main__second">
        <button
          className="RoadMap____Updates_Main__secondBtn1"
          style={{
            background:
              Act === "A"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "A" ? "var(--White, #FFFFFF)" : "",
          }}
          onClick={() => handleClick("A")}
        >
          {t('Roadmap')}
        </button>
        <button
          className="RoadMap____Updates_Main__secondBtn2"
          style={{
            background:
              Act === "B"
                ? "linear-gradient(180deg, #000000 0%, #323232 100%)"
                : "transparent",
            color: Act === "B" ? "var(--White, #FFFFFF)" : "",
          }}
          onClick={() => handleClick("B")}
        >
          {t('News')} 
        </button>
      </div>




      {/* <ResponsiveRoadmap/>

      <div className="RoadMap____Updates_Main__Third">
        <div className="RoadMap____Updates_Main__Third__1">
          <div className="RoadMap____Updates_Main__Third__1">
            <p className="RoadMap____Updates_Main__Third__1_P1">October 2024</p>
            <img src="/Images/magnas/dot..svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__1_P2">12:09:35</p>
          </div>

          <img
           src={icon} 
          alt="Toggle"
          onClick={toggleContentVisibility} 
          style={{ cursor: 'pointer' }}
        />
        </div>


   
   {isContentVisible && (
        <>
        <img src="/Images/magnas/line.svg" alt=".." />

        <div className="RoadMap____Updates_Main__Third__2">
          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
              Basic Game
            </p>
          </div>
          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
              Mining Updates
            </p>
          </div>

          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg2.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">Profiles</p>
          </div>

          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg2.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
              Referrals
            </p>
          </div>

          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg2.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">Quests</p>
          </div>

          <div className="RoadMap____Updates_Main__Third__2_1">
            <img src="/Images/magnas/chkboximg2.svg" alt=".." />
            <p className="RoadMap____Updates_Main__Third__2_1_Ptag">Badges</p>
          </div>
        </div>

        <div className="RoadMap____Updates_Main__Third__3">
          <img src="/Images/magnas/dot..svg" alt="" />
          <p className="RoadMap____Updates_Main__Third__3_P">12:09:35</p>
        </div>
        </>
      )}
      </div> */}



      { Act==="A" &&(

        <>

<ResponsiveRoadmap/>

<div className="RoadMap____Updates_Main__Third">
  <div className="RoadMap____Updates_Main__Third__1">
    <div className="RoadMap____Updates_Main__Third__1">
      <p className="RoadMap____Updates_Main__Third__1_P1">October 2024</p>
      <img src="/Images/magnas/dot..svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__1_P2">12:09:35</p>
    </div>

    <img
     src={icon} 
    alt="Toggle"
    onClick={toggleContentVisibility} 
    style={{ cursor: 'pointer' }}
  />
  </div>



{isContentVisible && (
  <>
  <img src="/Images/magnas/line.svg" alt=".." />

  <div className="RoadMap____Updates_Main__Third__2">
    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
        {t('Basic Game')}
      </p>
    </div>
    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
        {t('Mining Updates')}
      </p>
    </div>

    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg2.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">{t('Profiles')}</p>
    </div>

    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg2.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">
        {t('Referrals')}
      </p>
    </div>

    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg2.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">{t('Quests')}</p>
    </div>

    <div className="RoadMap____Updates_Main__Third__2_1">
      <img src="/Images/magnas/chkboximg2.svg" alt=".." />
      <p className="RoadMap____Updates_Main__Third__2_1_Ptag">{t('Badges')}</p>
    </div>
  </div>

  <div className="RoadMap____Updates_Main__Third__3">
    <img src="/Images/magnas/dot..svg" alt="" />
    <p className="RoadMap____Updates_Main__Third__3_P">12:09:35</p>
  </div>
  </>
)}
</div>


</>

      )}



{ Act==="B" &&(

<>



<div className='Div_News_MAin'>

    <h2 className='News_Text_H_set'>{t('News')}</h2>


 
   <div className='News_Card_Container'>
   <div className='News_Lorem_Ispum_Text_Container'>
    <h2 className='News_Lorem_ispum_Text_H'>Lorem Ispum</h2>
    <img src='/Images/magnas/lorem.svg'/>
   </div>
   <div>
    <img className='Divider_Line' src='/Images/magnas/line.svg'/>
   </div>
   <div>
    <p className='News_Porem_Ispum_Text'>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. </p>
    <div className='News_Time_Containter'>
      <p className='NewsTime_Text'>Oct 23, 2024</p>
      <img src='/Images/magnas/dot..svg'/>
      <p  className='NewsTime_Text'>12:09:35</p>
    </div>
   </div>
   </div>
  
   <div className='News_Card_Container'>
   <div className='News_Lorem_Ispum_Text_Container'>
    <h2 className='News_Lorem_ispum_Text_H'>Lorem Ispum</h2>
    <img src='/Images/magnas/lorem.svg'/>
   </div>
   <div>
    <img className='Divider_Line' src='/Images/magnas/line.svg'/>
   </div>
   <div>
    <p className='News_Porem_Ispum_Text'>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. </p>
    <div className='News_Time_Containter'>
      <p className='NewsTime_Text'>Oct 23, 2024</p>
      <img src='/Images/magnas/dot..svg' />
      <p  className='NewsTime_Text'>12:09:35</p>
    </div>
   </div>
   </div>
  
   <div className='News_Card_Container'>
   <div className='News_Lorem_Ispum_Text_Container'>
    <h2 className='News_Lorem_ispum_Text_H'>Lorem Ispum</h2>
    <img src='/Images/magnas/lorem.svg' alt=""/>
   </div>
   <div>
    <img className='Divider_Line' src='/Images/magnas/line.svg' alt=""/>
   </div>
   <div>
    <p className='News_Porem_Ispum_Text'>Porem ipsum dolor sit amet, consectetur adipiscing elit. Etiam eu turpis molestie, dictum est a, mattis tellus. </p>
    <div className='News_Time_Containter'>
      <p className='NewsTime_Text'>Oct 23, 2024</p>
      <img src='/Images/magnas/dot..svg' alt=""/>
      <p  className='NewsTime_Text'>12:09:35</p>
    </div>
   </div>
   </div>
  
  
   </div>

</>

)}



    </div>
  );
};

export default RoadMap____Updates;
